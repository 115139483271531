/*:root {*/
/*    --swiper-theme-color: #000;*/
/*}*/
/*.swiper-button-next, .swiper-button-prev {*/
/*    transition: opacity .5s;*/
/*}*/

/*.swiper-button-disabled {*/
/*     opacity: 0 !important;*/
/*}*/

.swiper-button-next, .swiper-button-prev {
    display: none !important;
}