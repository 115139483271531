@charset "UTF-8";.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.displayNone {
  display: none !important;
}

.displayBlock {
  display: block !important;
}

.link-primary {
    color: #0d6efd
}

.link-primary:hover,.link-primary:focus {
    color: #0a58ca
}

.link-secondary {
    color: #6c757d
}

.link-secondary:hover,.link-secondary:focus {
    color: #565e64
}

.link-success {
    color: #198754
}

.link-success:hover,.link-success:focus {
    color: #146c43
}

.link-info {
    color: #0dcaf0
}

.link-info:hover,.link-info:focus {
    color: #3dd5f3
}

.link-warning {
    color: #ffc107
}

.link-warning:hover,.link-warning:focus {
    color: #ffcd39
}

.link-danger {
    color: #dc3545
}

.link-danger:hover,.link-danger:focus {
    color: #b02a37
}

.link-light {
    color: #f8f9fa
}

.link-light:hover,.link-light:focus {
    color: #f9fafb
}

.link-dark {
    color: #212529
}

.link-dark:hover,.link-dark:focus {
    color: #1a1e21
}

.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: calc(3 / 4 * 100%)
}

.ratio-16x9 {
    --bs-aspect-ratio: calc(9 / 16 * 100%)
}

.ratio-21x9 {
    --bs-aspect-ratio: calc(9 / 21 * 100%)
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
}

@media(min-width: 576px) {
    .sticky-sm-top {
        position:-webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 768px) {
    .sticky-md-top {
        position:-webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 992px) {
    .sticky-lg-top {
        position:-webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 1200px) {
    .sticky-xl-top {
        position:-webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media(min-width: 1400px) {
    .sticky-xxl-top {
        position:-webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.visually-hidden,.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.align-baseline {
    vertical-align: baseline!important
}

.align-top {
    vertical-align: top!important
}

.align-middle {
    vertical-align: middle!important
}

.align-bottom {
    vertical-align: bottom!important
}

.align-text-bottom {
    vertical-align: text-bottom!important
}

.align-text-top {
    vertical-align: text-top!important
}

.float-start {
    float: left!important
}

.float-end {
    float: right!important
}

.float-none {
    float: none!important
}

.overflow-auto {
    overflow: auto!important
}

.overflow-hidden {
    overflow: hidden!important
}

.overflow-visible {
    overflow: visible!important
}

.overflow-scroll {
    overflow: scroll!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-grid {
    display: grid!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: -ms-flexbox!important;
    display: flex!important
}

.d-inline-flex {
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
}

.d-none {
    display: none!important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important
}

.shadow-none {
    box-shadow: none!important
}

.position-static {
    position: static!important
}

.position-relative {
    position: relative!important
}

.position-absolute {
    position: absolute!important
}

.position-fixed {
    position: fixed!important
}

.position-sticky {
    position: -webkit-sticky!important;
    position: sticky!important
}

.top-0 {
    top: 0!important
}

.top-50 {
    top: 50%!important
}

.top-100 {
    top: 100%!important
}

.bottom-0 {
    bottom: 0!important
}

.bottom-50 {
    bottom: 50%!important
}

.bottom-100 {
    bottom: 100%!important
}

.start-0 {
    left: 0!important
}

.start-50 {
    left: 50%!important
}

.start-100 {
    left: 100%!important
}

.end-0 {
    right: 0!important
}

.end-50 {
    right: 50%!important
}

.end-100 {
    right: 100%!important
}

.translate-middle {
    -webkit-transform: translate(-50%,-50%)!important;
    transform: translate(-50%,-50%)!important
}

.translate-middle-x {
    -webkit-transform: translateX(-50%)!important;
    transform: translateX(-50%)!important
}

.translate-middle-y {
    -webkit-transform: translateY(-50%)!important;
    transform: translateY(-50%)!important
}

.border {
    border: 1px solid #dee2e6!important
}

.border-0 {
    border: 0!important
}

.border-top {
    border-top: 1px solid #dee2e6!important
}

.border-top-0 {
    border-top: 0!important
}

.border-end {
    border-right: 1px solid #dee2e6!important
}

.border-end-0 {
    border-right: 0!important
}

.border-bottom {
    border-bottom: 1px solid #dee2e6!important
}

.border-bottom-0 {
    border-bottom: 0!important
}

.border-start {
    border-left: 1px solid #dee2e6!important
}

.border-start-0 {
    border-left: 0!important
}

.border-primary {
    border-color: #0d6efd!important
}

.border-secondary {
    border-color: #6c757d!important
}

.border-success {
    border-color: #198754!important
}

.border-info {
    border-color: #0dcaf0!important
}

.border-warning {
    border-color: #ffc107!important
}

.border-danger {
    border-color: #dc3545!important
}

.border-light {
    border-color: #f8f9fa!important
}

.border-dark {
    border-color: #212529!important
}

.border-white {
    border-color: #fff!important
}

.border-0 {
    border-width: 0!important
}

.border-1 {
    border-width: 1px!important
}

.border-2 {
    border-width: 2px!important
}

.border-3 {
    border-width: 3px!important
}

.border-4 {
    border-width: 4px!important
}

.border-5 {
    border-width: 5px!important
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.w-auto {
    width: auto!important
}

.mw-100 {
    max-width: 100%!important
}

.vw-100 {
    width: 100vw!important
}

.min-vw-100 {
    min-width: 100vw!important
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.h-auto {
    height: auto!important
}

.mh-100 {
    max-height: 100%!important
}

.vh-100 {
    height: 100vh!important
}

.min-vh-100 {
    min-height: 100vh!important
}

.flex-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto!important
}

.flex-row {
    -ms-flex-direction: row!important;
    flex-direction: row!important
}

.flex-column {
    -ms-flex-direction: column!important;
    flex-direction: column!important
}

.flex-row-reverse {
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important
}

.flex-grow-0 {
    -ms-flex-positive: 0!important;
    flex-grow: 0!important
}

.flex-grow-1 {
    -ms-flex-positive: 1!important;
    flex-grow: 1!important
}

.flex-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0!important
}

.flex-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1!important
}

.flex-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important
}

.flex-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important
}

.gap-0 {
    gap: 0!important
}

.gap-1 {
    gap: .25rem!important
}

.gap-2 {
    gap: .5rem!important
}

.gap-3 {
    gap: 1rem!important
}

.gap-4 {
    gap: 1.5rem!important
}

.gap-5 {
    gap: 3rem!important
}

.justify-content-start {
    -ms-flex-pack: start!important;
    justify-content: flex-start!important
}

.justify-content-end {
    -ms-flex-pack: end!important;
    justify-content: flex-end!important
}

.justify-content-center {
    -ms-flex-pack: center!important;
    justify-content: center!important
}

.justify-content-between {
    -ms-flex-pack: justify!important;
    justify-content: space-between!important
}

.justify-content-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important
}

.justify-content-evenly {
    -ms-flex-pack: space-evenly!important;
    justify-content: space-evenly!important
}

.align-items-start {
    -ms-flex-align: start!important;
    align-items: flex-start!important
}

.align-items-end {
    -ms-flex-align: end!important;
    align-items: flex-end!important
}

.align-items-center {
    -ms-flex-align: center!important;
    align-items: center!important
}

.align-items-baseline {
    -ms-flex-align: baseline!important;
    align-items: baseline!important
}

.align-items-stretch {
    -ms-flex-align: stretch!important;
    align-items: stretch!important
}

.align-content-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important
}

.align-content-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important
}

.align-content-center {
    -ms-flex-line-pack: center!important;
    align-content: center!important
}

.align-content-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important
}

.align-content-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important
}

.align-content-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important
}

.align-self-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto!important
}

.align-self-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start!important
}

.align-self-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end!important
}

.align-self-center {
    -ms-flex-item-align: center!important;
    align-self: center!important
}

.align-self-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important
}

.align-self-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important
}

.order-first {
    -ms-flex-order: -1!important;
    order: -1!important
}

.order-0 {
    -ms-flex-order: 0!important;
    order: 0!important
}

.order-1 {
    -ms-flex-order: 1!important;
    order: 1!important
}

.order-2 {
    -ms-flex-order: 2!important;
    order: 2!important
}

.order-3 {
    -ms-flex-order: 3!important;
    order: 3!important
}

.order-4 {
    -ms-flex-order: 4!important;
    order: 4!important
}

.order-5 {
    -ms-flex-order: 5!important;
    order: 5!important
}

.order-last {
    -ms-flex-order: 6!important;
    order: 6!important
}

.m-0 {
    margin: 0!important
}

.m-1 {
    margin: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.m-auto {
    margin: auto!important
}

.mx-0 {
    margin-right: 0!important;
    margin-left: 0!important
}

.mx-1 {
    margin-right: .25rem!important;
    margin-left: .25rem!important
}

.mx-2 {
    margin-right: .5rem!important;
    margin-left: .5rem!important
}

.mx-3 {
    margin-right: 1rem!important;
    margin-left: 1rem!important
}

.mx-4 {
    margin-right: 1.5rem!important;
    margin-left: 1.5rem!important
}

.mx-5 {
    margin-right: 3rem!important;
    margin-left: 3rem!important
}

.mx-auto {
    margin-right: auto!important;
    margin-left: auto!important
}

.my-0 {
    margin-top: 0!important;
    margin-bottom: 0!important
}

.my-1 {
    margin-top: .25rem!important;
    margin-bottom: .25rem!important
}

.my-2 {
    margin-top: .5rem!important;
    margin-bottom: .5rem!important
}

.my-3 {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important
}

.my-4 {
    margin-top: 1.5rem!important;
    margin-bottom: 1.5rem!important
}

.my-5 {
    margin-top: 3rem!important;
    margin-bottom: 3rem!important
}

.my-auto {
    margin-top: auto!important;
    margin-bottom: auto!important
}

.mt-0 {
    margin-top: 0!important
}

.mt-1 {
    margin-top: .25rem!important
}

.mt-2 {
    margin-top: .5rem!important
}

.mt-3 {
    margin-top: 1rem!important
}

.mt-4 {
    margin-top: 1.5rem!important
}

.mt-5 {
    margin-top: 3rem!important
}

.mt-auto {
    margin-top: auto!important
}

.me-0 {
    margin-right: 0!important
}

.me-1 {
    margin-right: .25rem!important
}

.me-2 {
    margin-right: .5rem!important
}

.me-3 {
    margin-right: 1rem!important
}

.me-4 {
    margin-right: 1.5rem!important
}

.me-5 {
    margin-right: 3rem!important
}

.me-auto {
    margin-right: auto!important
}

.mb-0 {
    margin-bottom: 0!important
}

.mb-1 {
    margin-bottom: .25rem!important
}

.mb-2 {
    margin-bottom: .5rem!important
}

.mb-3 {
    margin-bottom: 1rem!important
}

.mb-4 {
    margin-bottom: 1.5rem!important
}

.mb-5 {
    margin-bottom: 3rem!important
}

.mb-auto {
    margin-bottom: auto!important
}

.ms-0 {
    margin-left: 0!important
}

.ms-1 {
    margin-left: .25rem!important
}

.ms-2 {
    margin-left: .5rem!important
}

.ms-3 {
    margin-left: 1rem!important
}

.ms-4 {
    margin-left: 1.5rem!important
}

.ms-5 {
    margin-left: 3rem!important
}

.ms-auto {
    margin-left: auto!important
}

.p-0 {
    padding: 0!important
}

.p-1 {
    padding: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.p-3 {
    padding: 1rem!important
}

.p-4 {
    padding: 1.5rem!important
}

.p-5 {
    padding: 3rem!important
}

.px-0 {
    padding-right: 0!important;
    padding-left: 0!important
}

.px-1 {
    padding-right: .25rem!important;
    padding-left: .25rem!important
}

.px-2 {
    padding-right: .5rem!important;
    padding-left: .5rem!important
}

.px-3 {
    padding-right: 1rem!important;
    padding-left: 1rem!important
}

.px-4 {
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important
}

.px-5 {
    padding-right: 3rem!important;
    padding-left: 3rem!important
}

.py-0 {
    padding-top: 0!important;
    padding-bottom: 0!important
}

.py-1 {
    padding-top: .25rem!important;
    padding-bottom: .25rem!important
}

.py-2 {
    padding-top: .5rem!important;
    padding-bottom: .5rem!important
}

.py-3 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important
}

.py-4 {
    padding-top: 1.5rem!important;
    padding-bottom: 1.5rem!important
}

.py-5 {
    padding-top: 3rem!important;
    padding-bottom: 3rem!important
}

.pt-0 {
    padding-top: 0!important
}

.pt-1 {
    padding-top: .25rem!important
}

.pt-2 {
    padding-top: .5rem!important
}

.pt-3 {
    padding-top: 1rem!important
}

.pt-4 {
    padding-top: 1.5rem!important
}

.pt-5 {
    padding-top: 3rem!important
}

.pe-0 {
    padding-right: 0!important
}

.pe-1 {
    padding-right: .25rem!important
}

.pe-2 {
    padding-right: .5rem!important
}

.pe-3 {
    padding-right: 1rem!important
}

.pe-4 {
    padding-right: 1.5rem!important
}

.pe-5 {
    padding-right: 3rem!important
}

.pb-0 {
    padding-bottom: 0!important
}

.pb-1 {
    padding-bottom: .25rem!important
}

.pb-2 {
    padding-bottom: .5rem!important
}

.pb-3 {
    padding-bottom: 1rem!important
}

.pb-4 {
    padding-bottom: 1.5rem!important
}

.pb-5 {
    padding-bottom: 3rem!important
}

.ps-0 {
    padding-left: 0!important
}

.ps-1 {
    padding-left: .25rem!important
}

.ps-2 {
    padding-left: .5rem!important
}

.ps-3 {
    padding-left: 1rem!important
}

.ps-4 {
    padding-left: 1.5rem!important
}

.ps-5 {
    padding-left: 3rem!important
}

.fs-1 {
    font-size: calc(1.375rem + 1.5vw)!important
}

.fs-2 {
    font-size: calc(1.325rem + 0.9vw)!important
}

.fs-3 {
    font-size: calc(1.3rem + 0.6vw)!important
}

.fs-4 {
    font-size: calc(1.275rem + 0.3vw)!important
}

.fs-5 {
    font-size: 1.25rem!important
}

.fs-6 {
    font-size: 1rem!important
}

.fst-italic {
    font-style: italic!important
}

.fst-normal {
    font-style: normal!important
}

.fw-light {
    font-weight: 300!important
}

.fw-lighter {
    font-weight: lighter!important
}

.fw-normal {
    font-weight: 400!important
}

.fw-bold {
    font-weight: 700!important
}

.fw-bolder {
    font-weight: bolder!important
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.text-start {
    text-align: left!important
}

.text-end {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

.text-primary {
    color: #0d6efd!important
}

.text-secondary {
    color: #6c757d!important
}

.text-success {
    color: #198754!important
}

.text-info {
    color: #0dcaf0!important
}

.text-warning {
    color: #ffc107!important
}

.text-danger {
    color: #dc3545!important
}

.text-light {
    color: #f8f9fa!important
}

.text-dark {
    color: #212529!important
}

.text-white {
    color: #fff!important
}

.text-body {
    color: #212529!important
}

.text-muted {
    color: #6c757d!important
}

.text-black-50 {
    color: rgba(0,0,0,.5)!important
}

.text-white-50 {
    color: rgba(255,255,255,.5)!important
}

.text-reset {
    color: inherit!important
}

.lh-1 {
    line-height: 1!important
}

.lh-sm {
    line-height: 1.25!important
}

.lh-base {
    line-height: 1.5!important
}

.lh-lg {
    line-height: 2!important
}

.bg-primary {
    background-color: #0d6efd!important
}

.bg-secondary {
    background-color: #6c757d!important
}

.bg-success {
    background-color: #198754!important
}

.bg-info {
    background-color: #0dcaf0!important
}

.bg-warning {
    background-color: #ffc107!important
}

.bg-danger {
    background-color: #dc3545!important
}

.bg-light {
    background-color: #f8f9fa!important
}

.bg-dark {
    background-color: #212529!important
}

.bg-body {
    background-color: #fff!important
}

.bg-white {
    background-color: #fff!important
}

.bg-transparent {
    background-color: transparent!important
}

.bg-gradient {
    background-image: var(--bs-gradient)!important
}

.text-wrap {
    white-space: normal!important
}

.text-nowrap {
    white-space: nowrap!important
}

.text-decoration-none {
    text-decoration: none!important
}

.text-decoration-underline {
    text-decoration: underline!important
}

.text-decoration-line-through {
    text-decoration: line-through!important
}

.text-break {
    word-wrap: break-word!important;
    word-break: break-word!important
}

.font-monospace {
    font-family: var(--bs-font-monospace)!important
}

.user-select-all {
    -webkit-user-select: all!important;
    -moz-user-select: all!important;
    user-select: all!important
}

.user-select-auto {
    -webkit-user-select: auto!important;
    -moz-user-select: auto!important;
    -ms-user-select: auto!important;
    user-select: auto!important
}

.user-select-none {
    -webkit-user-select: none!important;
    -moz-user-select: none!important;
    -ms-user-select: none!important;
    user-select: none!important
}

.pe-none {
    pointer-events: none!important
}

.pe-auto {
    pointer-events: auto!important
}

.rounded {
    border-radius: .25rem!important
}

.rounded-0 {
    border-radius: 0!important
}

.rounded-1 {
    border-radius: .2rem!important
}

.rounded-2 {
    border-radius: .25rem!important
}

.rounded-3 {
    border-radius: .3rem!important
}

.rounded-circle {
    border-radius: 50%!important
}

.rounded-pill {
    border-radius: 50rem!important
}

.rounded-top {
    border-top-left-radius: .25rem!important;
    border-top-right-radius: .25rem!important
}

.rounded-end {
    border-top-right-radius: .25rem!important;
    border-bottom-right-radius: .25rem!important
}

.rounded-bottom {
    border-bottom-right-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important
}

.rounded-start {
    border-bottom-left-radius: .25rem!important;
    border-top-left-radius: .25rem!important
}

.visible {
    visibility: visible!important
}

.invisible {
    visibility: hidden!important
}

@media(min-width: 576px) {
    .float-sm-start {
        float:left!important
    }

    .float-sm-end {
        float: right!important
    }

    .float-sm-none {
        float: none!important
    }

    .d-sm-inline {
        display: inline!important
    }

    .d-sm-inline-block {
        display: inline-block!important
    }

    .d-sm-block {
        display: block!important
    }

    .d-sm-grid {
        display: grid!important
    }

    .d-sm-table {
        display: table!important
    }

    .d-sm-table-row {
        display: table-row!important
    }

    .d-sm-table-cell {
        display: table-cell!important
    }

    .d-sm-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-sm-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-sm-none {
        display: none!important
    }

    .flex-sm-fill {
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-sm-row {
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-sm-column {
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-sm-row-reverse {
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-sm-column-reverse {
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-sm-grow-0 {
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-sm-grow-1 {
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .gap-sm-0 {
        gap: 0!important
    }

    .gap-sm-1 {
        gap: .25rem!important
    }

    .gap-sm-2 {
        gap: .5rem!important
    }

    .gap-sm-3 {
        gap: 1rem!important
    }

    .gap-sm-4 {
        gap: 1.5rem!important
    }

    .gap-sm-5 {
        gap: 3rem!important
    }

    .justify-content-sm-start {
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-sm-end {
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-sm-center {
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-sm-between {
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-sm-evenly {
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-sm-start {
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-sm-end {
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-sm-center {
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-sm-baseline {
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-sm-stretch {
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-sm-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-sm-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-sm-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-sm-first {
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-sm-0 {
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-sm-1 {
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-sm-2 {
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-sm-3 {
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-sm-4 {
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-sm-5 {
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-sm-last {
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-sm-0 {
        margin: 0!important
    }

    .m-sm-1 {
        margin: .25rem!important
    }

    .m-sm-2 {
        margin: .5rem!important
    }

    .m-sm-3 {
        margin: 1rem!important
    }

    .m-sm-4 {
        margin: 1.5rem!important
    }

    .m-sm-5 {
        margin: 3rem!important
    }

    .m-sm-auto {
        margin: auto!important
    }

    .mx-sm-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-sm-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-sm-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-sm-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-sm-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-sm-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-sm-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-sm-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-sm-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-sm-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-sm-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-sm-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-sm-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-sm-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-sm-0 {
        margin-top: 0!important
    }

    .mt-sm-1 {
        margin-top: .25rem!important
    }

    .mt-sm-2 {
        margin-top: .5rem!important
    }

    .mt-sm-3 {
        margin-top: 1rem!important
    }

    .mt-sm-4 {
        margin-top: 1.5rem!important
    }

    .mt-sm-5 {
        margin-top: 3rem!important
    }

    .mt-sm-auto {
        margin-top: auto!important
    }

    .me-sm-0 {
        margin-right: 0!important
    }

    .me-sm-1 {
        margin-right: .25rem!important
    }

    .me-sm-2 {
        margin-right: .5rem!important
    }

    .me-sm-3 {
        margin-right: 1rem!important
    }

    .me-sm-4 {
        margin-right: 1.5rem!important
    }

    .me-sm-5 {
        margin-right: 3rem!important
    }

    .me-sm-auto {
        margin-right: auto!important
    }

    .mb-sm-0 {
        margin-bottom: 0!important
    }

    .mb-sm-1 {
        margin-bottom: .25rem!important
    }

    .mb-sm-2 {
        margin-bottom: .5rem!important
    }

    .mb-sm-3 {
        margin-bottom: 1rem!important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-sm-5 {
        margin-bottom: 3rem!important
    }

    .mb-sm-auto {
        margin-bottom: auto!important
    }

    .ms-sm-0 {
        margin-left: 0!important
    }

    .ms-sm-1 {
        margin-left: .25rem!important
    }

    .ms-sm-2 {
        margin-left: .5rem!important
    }

    .ms-sm-3 {
        margin-left: 1rem!important
    }

    .ms-sm-4 {
        margin-left: 1.5rem!important
    }

    .ms-sm-5 {
        margin-left: 3rem!important
    }

    .ms-sm-auto {
        margin-left: auto!important
    }

    .p-sm-0 {
        padding: 0!important
    }

    .p-sm-1 {
        padding: .25rem!important
    }

    .p-sm-2 {
        padding: .5rem!important
    }

    .p-sm-3 {
        padding: 1rem!important
    }

    .p-sm-4 {
        padding: 1.5rem!important
    }

    .p-sm-5 {
        padding: 3rem!important
    }

    .px-sm-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-sm-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-sm-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-sm-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-sm-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-sm-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-sm-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-sm-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-sm-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-sm-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-sm-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-sm-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-sm-0 {
        padding-top: 0!important
    }

    .pt-sm-1 {
        padding-top: .25rem!important
    }

    .pt-sm-2 {
        padding-top: .5rem!important
    }

    .pt-sm-3 {
        padding-top: 1rem!important
    }

    .pt-sm-4 {
        padding-top: 1.5rem!important
    }

    .pt-sm-5 {
        padding-top: 3rem!important
    }

    .pe-sm-0 {
        padding-right: 0!important
    }

    .pe-sm-1 {
        padding-right: .25rem!important
    }

    .pe-sm-2 {
        padding-right: .5rem!important
    }

    .pe-sm-3 {
        padding-right: 1rem!important
    }

    .pe-sm-4 {
        padding-right: 1.5rem!important
    }

    .pe-sm-5 {
        padding-right: 3rem!important
    }

    .pb-sm-0 {
        padding-bottom: 0!important
    }

    .pb-sm-1 {
        padding-bottom: .25rem!important
    }

    .pb-sm-2 {
        padding-bottom: .5rem!important
    }

    .pb-sm-3 {
        padding-bottom: 1rem!important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-sm-5 {
        padding-bottom: 3rem!important
    }

    .ps-sm-0 {
        padding-left: 0!important
    }

    .ps-sm-1 {
        padding-left: .25rem!important
    }

    .ps-sm-2 {
        padding-left: .5rem!important
    }

    .ps-sm-3 {
        padding-left: 1rem!important
    }

    .ps-sm-4 {
        padding-left: 1.5rem!important
    }

    .ps-sm-5 {
        padding-left: 3rem!important
    }

    .text-sm-start {
        text-align: left!important
    }

    .text-sm-end {
        text-align: right!important
    }

    .text-sm-center {
        text-align: center!important
    }
}

@media(min-width: 768px) {
    .float-md-start {
        float:left!important
    }

    .float-md-end {
        float: right!important
    }

    .float-md-none {
        float: none!important
    }

    .d-md-inline {
        display: inline!important
    }

    .d-md-inline-block {
        display: inline-block!important
    }

    .d-md-block {
        display: block!important
    }

    .d-md-grid {
        display: grid!important
    }

    .d-md-table {
        display: table!important
    }

    .d-md-table-row {
        display: table-row!important
    }

    .d-md-table-cell {
        display: table-cell!important
    }

    .d-md-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-md-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-md-none {
        display: none!important
    }

    .flex-md-fill {
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-md-row {
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-md-column {
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-md-row-reverse {
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-md-column-reverse {
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-md-grow-0 {
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-md-grow-1 {
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-md-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-md-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .gap-md-0 {
        gap: 0!important
    }

    .gap-md-1 {
        gap: .25rem!important
    }

    .gap-md-2 {
        gap: .5rem!important
    }

    .gap-md-3 {
        gap: 1rem!important
    }

    .gap-md-4 {
        gap: 1.5rem!important
    }

    .gap-md-5 {
        gap: 3rem!important
    }

    .justify-content-md-start {
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-md-end {
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-md-center {
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-md-between {
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-md-evenly {
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-md-start {
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-md-end {
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-md-center {
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-md-baseline {
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-md-stretch {
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-md-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-md-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-md-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-md-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-md-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-md-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-md-first {
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-md-0 {
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-md-1 {
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-md-2 {
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-md-3 {
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-md-4 {
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-md-5 {
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-md-last {
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-md-0 {
        margin: 0!important
    }

    .m-md-1 {
        margin: .25rem!important
    }

    .m-md-2 {
        margin: .5rem!important
    }

    .m-md-3 {
        margin: 1rem!important
    }

    .m-md-4 {
        margin: 1.5rem!important
    }

    .m-md-5 {
        margin: 3rem!important
    }

    .m-md-auto {
        margin: auto!important
    }

    .mx-md-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-md-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-md-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-md-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-md-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-md-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-md-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-md-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-md-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-md-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-md-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-md-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-md-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-md-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-md-0 {
        margin-top: 0!important
    }

    .mt-md-1 {
        margin-top: .25rem!important
    }

    .mt-md-2 {
        margin-top: .5rem!important
    }

    .mt-md-3 {
        margin-top: 1rem!important
    }

    .mt-md-4 {
        margin-top: 1.5rem!important
    }

    .mt-md-5 {
        margin-top: 3rem!important
    }

    .mt-md-auto {
        margin-top: auto!important
    }

    .me-md-0 {
        margin-right: 0!important
    }

    .me-md-1 {
        margin-right: .25rem!important
    }

    .me-md-2 {
        margin-right: .5rem!important
    }

    .me-md-3 {
        margin-right: 1rem!important
    }

    .me-md-4 {
        margin-right: 1.5rem!important
    }

    .me-md-5 {
        margin-right: 3rem!important
    }

    .me-md-auto {
        margin-right: auto!important
    }

    .mb-md-0 {
        margin-bottom: 0!important
    }

    .mb-md-1 {
        margin-bottom: .25rem!important
    }

    .mb-md-2 {
        margin-bottom: .5rem!important
    }

    .mb-md-3 {
        margin-bottom: 1rem!important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-md-5 {
        margin-bottom: 3rem!important
    }

    .mb-md-auto {
        margin-bottom: auto!important
    }

    .ms-md-0 {
        margin-left: 0!important
    }

    .ms-md-1 {
        margin-left: .25rem!important
    }

    .ms-md-2 {
        margin-left: .5rem!important
    }

    .ms-md-3 {
        margin-left: 1rem!important
    }

    .ms-md-4 {
        margin-left: 1.5rem!important
    }

    .ms-md-5 {
        margin-left: 3rem!important
    }

    .ms-md-auto {
        margin-left: auto!important
    }

    .p-md-0 {
        padding: 0!important
    }

    .p-md-1 {
        padding: .25rem!important
    }

    .p-md-2 {
        padding: .5rem!important
    }

    .p-md-3 {
        padding: 1rem!important
    }

    .p-md-4 {
        padding: 1.5rem!important
    }

    .p-md-5 {
        padding: 3rem!important
    }

    .px-md-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-md-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-md-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-md-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-md-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-md-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-md-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-md-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-md-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-md-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-md-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-md-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-md-0 {
        padding-top: 0!important
    }

    .pt-md-1 {
        padding-top: .25rem!important
    }

    .pt-md-2 {
        padding-top: .5rem!important
    }

    .pt-md-3 {
        padding-top: 1rem!important
    }

    .pt-md-4 {
        padding-top: 1.5rem!important
    }

    .pt-md-5 {
        padding-top: 3rem!important
    }

    .pe-md-0 {
        padding-right: 0!important
    }

    .pe-md-1 {
        padding-right: .25rem!important
    }

    .pe-md-2 {
        padding-right: .5rem!important
    }

    .pe-md-3 {
        padding-right: 1rem!important
    }

    .pe-md-4 {
        padding-right: 1.5rem!important
    }

    .pe-md-5 {
        padding-right: 3rem!important
    }

    .pb-md-0 {
        padding-bottom: 0!important
    }

    .pb-md-1 {
        padding-bottom: .25rem!important
    }

    .pb-md-2 {
        padding-bottom: .5rem!important
    }

    .pb-md-3 {
        padding-bottom: 1rem!important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-md-5 {
        padding-bottom: 3rem!important
    }

    .ps-md-0 {
        padding-left: 0!important
    }

    .ps-md-1 {
        padding-left: .25rem!important
    }

    .ps-md-2 {
        padding-left: .5rem!important
    }

    .ps-md-3 {
        padding-left: 1rem!important
    }

    .ps-md-4 {
        padding-left: 1.5rem!important
    }

    .ps-md-5 {
        padding-left: 3rem!important
    }

    .text-md-start {
        text-align: left!important
    }

    .text-md-end {
        text-align: right!important
    }

    .text-md-center {
        text-align: center!important
    }
}

@media(min-width: 992px) {
    .float-lg-start {
        float:left!important
    }

    .float-lg-end {
        float: right!important
    }

    .float-lg-none {
        float: none!important
    }

    .d-lg-inline {
        display: inline!important
    }

    .d-lg-inline-block {
        display: inline-block!important
    }

    .d-lg-block {
        display: block!important
    }

    .d-lg-grid {
        display: grid!important
    }

    .d-lg-table {
        display: table!important
    }

    .d-lg-table-row {
        display: table-row!important
    }

    .d-lg-table-cell {
        display: table-cell!important
    }

    .d-lg-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-lg-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-lg-none {
        display: none!important
    }

    .flex-lg-fill {
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-lg-row {
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-lg-column {
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-lg-row-reverse {
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-lg-column-reverse {
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-lg-grow-0 {
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-lg-grow-1 {
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-lg-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-lg-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .gap-lg-0 {
        gap: 0!important
    }

    .gap-lg-1 {
        gap: .25rem!important
    }

    .gap-lg-2 {
        gap: .5rem!important
    }

    .gap-lg-3 {
        gap: 1rem!important
    }

    .gap-lg-4 {
        gap: 1.5rem!important
    }

    .gap-lg-5 {
        gap: 3rem!important
    }

    .justify-content-lg-start {
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-lg-end {
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-lg-center {
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-lg-between {
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-lg-evenly {
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-lg-start {
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-lg-end {
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-lg-center {
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-lg-baseline {
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-lg-stretch {
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-lg-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-lg-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-lg-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-lg-first {
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-lg-0 {
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-lg-1 {
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-lg-2 {
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-lg-3 {
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-lg-4 {
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-lg-5 {
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-lg-last {
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-lg-0 {
        margin: 0!important
    }

    .m-lg-1 {
        margin: .25rem!important
    }

    .m-lg-2 {
        margin: .5rem!important
    }

    .m-lg-3 {
        margin: 1rem!important
    }

    .m-lg-4 {
        margin: 1.5rem!important
    }

    .m-lg-5 {
        margin: 3rem!important
    }

    .m-lg-auto {
        margin: auto!important
    }

    .mx-lg-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-lg-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-lg-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-lg-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-lg-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-lg-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-lg-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-lg-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-lg-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-lg-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-lg-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-lg-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-lg-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-lg-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-lg-0 {
        margin-top: 0!important
    }

    .mt-lg-1 {
        margin-top: .25rem!important
    }

    .mt-lg-2 {
        margin-top: .5rem!important
    }

    .mt-lg-3 {
        margin-top: 1rem!important
    }

    .mt-lg-4 {
        margin-top: 1.5rem!important
    }

    .mt-lg-5 {
        margin-top: 3rem!important
    }

    .mt-lg-auto {
        margin-top: auto!important
    }

    .me-lg-0 {
        margin-right: 0!important
    }

    .me-lg-1 {
        margin-right: .25rem!important
    }

    .me-lg-2 {
        margin-right: .5rem!important
    }

    .me-lg-3 {
        margin-right: 1rem!important
    }

    .me-lg-4 {
        margin-right: 1.5rem!important
    }

    .me-lg-5 {
        margin-right: 3rem!important
    }

    .me-lg-auto {
        margin-right: auto!important
    }

    .mb-lg-0 {
        margin-bottom: 0!important
    }

    .mb-lg-1 {
        margin-bottom: .25rem!important
    }

    .mb-lg-2 {
        margin-bottom: .5rem!important
    }

    .mb-lg-3 {
        margin-bottom: 1rem!important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-lg-5 {
        margin-bottom: 3rem!important
    }

    .mb-lg-auto {
        margin-bottom: auto!important
    }

    .ms-lg-0 {
        margin-left: 0!important
    }

    .ms-lg-1 {
        margin-left: .25rem!important
    }

    .ms-lg-2 {
        margin-left: .5rem!important
    }

    .ms-lg-3 {
        margin-left: 1rem!important
    }

    .ms-lg-4 {
        margin-left: 1.5rem!important
    }

    .ms-lg-5 {
        margin-left: 3rem!important
    }

    .ms-lg-auto {
        margin-left: auto!important
    }

    .p-lg-0 {
        padding: 0!important
    }

    .p-lg-1 {
        padding: .25rem!important
    }

    .p-lg-2 {
        padding: .5rem!important
    }

    .p-lg-3 {
        padding: 1rem!important
    }

    .p-lg-4 {
        padding: 1.5rem!important
    }

    .p-lg-5 {
        padding: 3rem!important
    }

    .px-lg-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-lg-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-lg-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-lg-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-lg-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-lg-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-lg-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-lg-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-lg-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-lg-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-lg-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-lg-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-lg-0 {
        padding-top: 0!important
    }

    .pt-lg-1 {
        padding-top: .25rem!important
    }

    .pt-lg-2 {
        padding-top: .5rem!important
    }

    .pt-lg-3 {
        padding-top: 1rem!important
    }

    .pt-lg-4 {
        padding-top: 1.5rem!important
    }

    .pt-lg-5 {
        padding-top: 3rem!important
    }

    .pe-lg-0 {
        padding-right: 0!important
    }

    .pe-lg-1 {
        padding-right: .25rem!important
    }

    .pe-lg-2 {
        padding-right: .5rem!important
    }

    .pe-lg-3 {
        padding-right: 1rem!important
    }

    .pe-lg-4 {
        padding-right: 1.5rem!important
    }

    .pe-lg-5 {
        padding-right: 3rem!important
    }

    .pb-lg-0 {
        padding-bottom: 0!important
    }

    .pb-lg-1 {
        padding-bottom: .25rem!important
    }

    .pb-lg-2 {
        padding-bottom: .5rem!important
    }

    .pb-lg-3 {
        padding-bottom: 1rem!important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-lg-5 {
        padding-bottom: 3rem!important
    }

    .ps-lg-0 {
        padding-left: 0!important
    }

    .ps-lg-1 {
        padding-left: .25rem!important
    }

    .ps-lg-2 {
        padding-left: .5rem!important
    }

    .ps-lg-3 {
        padding-left: 1rem!important
    }

    .ps-lg-4 {
        padding-left: 1.5rem!important
    }

    .ps-lg-5 {
        padding-left: 3rem!important
    }

    .text-lg-start {
        text-align: left!important
    }

    .text-lg-end {
        text-align: right!important
    }

    .text-lg-center {
        text-align: center!important
    }
}

@media(min-width: 1200px) {
    .float-xl-start {
        float:left!important
    }

    .float-xl-end {
        float: right!important
    }

    .float-xl-none {
        float: none!important
    }

    .d-xl-inline {
        display: inline!important
    }

    .d-xl-inline-block {
        display: inline-block!important
    }

    .d-xl-block {
        display: block!important
    }

    .d-xl-grid {
        display: grid!important
    }

    .d-xl-table {
        display: table!important
    }

    .d-xl-table-row {
        display: table-row!important
    }

    .d-xl-table-cell {
        display: table-cell!important
    }

    .d-xl-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-xl-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-xl-none {
        display: none!important
    }

    .flex-xl-fill {
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-xl-row {
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-xl-column {
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-xl-row-reverse {
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-xl-column-reverse {
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-xl-grow-0 {
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-xl-grow-1 {
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .gap-xl-0 {
        gap: 0!important
    }

    .gap-xl-1 {
        gap: .25rem!important
    }

    .gap-xl-2 {
        gap: .5rem!important
    }

    .gap-xl-3 {
        gap: 1rem!important
    }

    .gap-xl-4 {
        gap: 1.5rem!important
    }

    .gap-xl-5 {
        gap: 3rem!important
    }

    .justify-content-xl-start {
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-xl-end {
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-xl-center {
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-xl-between {
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-xl-evenly {
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-xl-start {
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-xl-end {
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-xl-center {
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-xl-baseline {
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-xl-stretch {
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-xl-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-xl-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-xl-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-xl-first {
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-xl-0 {
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-xl-1 {
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-xl-2 {
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-xl-3 {
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-xl-4 {
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-xl-5 {
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-xl-last {
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-xl-0 {
        margin: 0!important
    }

    .m-xl-1 {
        margin: .25rem!important
    }

    .m-xl-2 {
        margin: .5rem!important
    }

    .m-xl-3 {
        margin: 1rem!important
    }

    .m-xl-4 {
        margin: 1.5rem!important
    }

    .m-xl-5 {
        margin: 3rem!important
    }

    .m-xl-auto {
        margin: auto!important
    }

    .mx-xl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xl-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-xl-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-xl-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xl-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xl-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xl-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-xl-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-xl-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xl-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xl-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xl-0 {
        margin-top: 0!important
    }

    .mt-xl-1 {
        margin-top: .25rem!important
    }

    .mt-xl-2 {
        margin-top: .5rem!important
    }

    .mt-xl-3 {
        margin-top: 1rem!important
    }

    .mt-xl-4 {
        margin-top: 1.5rem!important
    }

    .mt-xl-5 {
        margin-top: 3rem!important
    }

    .mt-xl-auto {
        margin-top: auto!important
    }

    .me-xl-0 {
        margin-right: 0!important
    }

    .me-xl-1 {
        margin-right: .25rem!important
    }

    .me-xl-2 {
        margin-right: .5rem!important
    }

    .me-xl-3 {
        margin-right: 1rem!important
    }

    .me-xl-4 {
        margin-right: 1.5rem!important
    }

    .me-xl-5 {
        margin-right: 3rem!important
    }

    .me-xl-auto {
        margin-right: auto!important
    }

    .mb-xl-0 {
        margin-bottom: 0!important
    }

    .mb-xl-1 {
        margin-bottom: .25rem!important
    }

    .mb-xl-2 {
        margin-bottom: .5rem!important
    }

    .mb-xl-3 {
        margin-bottom: 1rem!important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-xl-5 {
        margin-bottom: 3rem!important
    }

    .mb-xl-auto {
        margin-bottom: auto!important
    }

    .ms-xl-0 {
        margin-left: 0!important
    }

    .ms-xl-1 {
        margin-left: .25rem!important
    }

    .ms-xl-2 {
        margin-left: .5rem!important
    }

    .ms-xl-3 {
        margin-left: 1rem!important
    }

    .ms-xl-4 {
        margin-left: 1.5rem!important
    }

    .ms-xl-5 {
        margin-left: 3rem!important
    }

    .ms-xl-auto {
        margin-left: auto!important
    }

    .p-xl-0 {
        padding: 0!important
    }

    .p-xl-1 {
        padding: .25rem!important
    }

    .p-xl-2 {
        padding: .5rem!important
    }

    .p-xl-3 {
        padding: 1rem!important
    }

    .p-xl-4 {
        padding: 1.5rem!important
    }

    .p-xl-5 {
        padding: 3rem!important
    }

    .px-xl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xl-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-xl-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-xl-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xl-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xl-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-xl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xl-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-xl-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-xl-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xl-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xl-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-xl-0 {
        padding-top: 0!important
    }

    .pt-xl-1 {
        padding-top: .25rem!important
    }

    .pt-xl-2 {
        padding-top: .5rem!important
    }

    .pt-xl-3 {
        padding-top: 1rem!important
    }

    .pt-xl-4 {
        padding-top: 1.5rem!important
    }

    .pt-xl-5 {
        padding-top: 3rem!important
    }

    .pe-xl-0 {
        padding-right: 0!important
    }

    .pe-xl-1 {
        padding-right: .25rem!important
    }

    .pe-xl-2 {
        padding-right: .5rem!important
    }

    .pe-xl-3 {
        padding-right: 1rem!important
    }

    .pe-xl-4 {
        padding-right: 1.5rem!important
    }

    .pe-xl-5 {
        padding-right: 3rem!important
    }

    .pb-xl-0 {
        padding-bottom: 0!important
    }

    .pb-xl-1 {
        padding-bottom: .25rem!important
    }

    .pb-xl-2 {
        padding-bottom: .5rem!important
    }

    .pb-xl-3 {
        padding-bottom: 1rem!important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-xl-5 {
        padding-bottom: 3rem!important
    }

    .ps-xl-0 {
        padding-left: 0!important
    }

    .ps-xl-1 {
        padding-left: .25rem!important
    }

    .ps-xl-2 {
        padding-left: .5rem!important
    }

    .ps-xl-3 {
        padding-left: 1rem!important
    }

    .ps-xl-4 {
        padding-left: 1.5rem!important
    }

    .ps-xl-5 {
        padding-left: 3rem!important
    }

    .text-xl-start {
        text-align: left!important
    }

    .text-xl-end {
        text-align: right!important
    }

    .text-xl-center {
        text-align: center!important
    }
}

@media(min-width: 1400px) {
    .float-xxl-start {
        float:left!important
    }

    .float-xxl-end {
        float: right!important
    }

    .float-xxl-none {
        float: none!important
    }

    .d-xxl-inline {
        display: inline!important
    }

    .d-xxl-inline-block {
        display: inline-block!important
    }

    .d-xxl-block {
        display: block!important
    }

    .d-xxl-grid {
        display: grid!important
    }

    .d-xxl-table {
        display: table!important
    }

    .d-xxl-table-row {
        display: table-row!important
    }

    .d-xxl-table-cell {
        display: table-cell!important
    }

    .d-xxl-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-xxl-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-xxl-none {
        display: none!important
    }

    .flex-xxl-fill {
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-xxl-row {
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-xxl-column {
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-xxl-row-reverse {
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-xxl-column-reverse {
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-xxl-grow-0 {
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-xxl-grow-1 {
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-xxl-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-xxl-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-xxl-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-xxl-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-xxl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .gap-xxl-0 {
        gap: 0!important
    }

    .gap-xxl-1 {
        gap: .25rem!important
    }

    .gap-xxl-2 {
        gap: .5rem!important
    }

    .gap-xxl-3 {
        gap: 1rem!important
    }

    .gap-xxl-4 {
        gap: 1.5rem!important
    }

    .gap-xxl-5 {
        gap: 3rem!important
    }

    .justify-content-xxl-start {
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-xxl-end {
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-xxl-center {
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-xxl-between {
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-xxl-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-xxl-evenly {
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-xxl-start {
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-xxl-end {
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-xxl-center {
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-xxl-baseline {
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-xxl-stretch {
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-xxl-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-xxl-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-xxl-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-xxl-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-xxl-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-xxl-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-xxl-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-xxl-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-xxl-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-xxl-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-xxl-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-xxl-first {
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-xxl-0 {
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-xxl-1 {
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-xxl-2 {
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-xxl-3 {
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-xxl-4 {
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-xxl-5 {
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-xxl-last {
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-xxl-0 {
        margin: 0!important
    }

    .m-xxl-1 {
        margin: .25rem!important
    }

    .m-xxl-2 {
        margin: .5rem!important
    }

    .m-xxl-3 {
        margin: 1rem!important
    }

    .m-xxl-4 {
        margin: 1.5rem!important
    }

    .m-xxl-5 {
        margin: 3rem!important
    }

    .m-xxl-auto {
        margin: auto!important
    }

    .mx-xxl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xxl-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-xxl-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-xxl-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xxl-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xxl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xxl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xxl-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-xxl-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-xxl-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xxl-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xxl-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xxl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xxl-0 {
        margin-top: 0!important
    }

    .mt-xxl-1 {
        margin-top: .25rem!important
    }

    .mt-xxl-2 {
        margin-top: .5rem!important
    }

    .mt-xxl-3 {
        margin-top: 1rem!important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem!important
    }

    .mt-xxl-5 {
        margin-top: 3rem!important
    }

    .mt-xxl-auto {
        margin-top: auto!important
    }

    .me-xxl-0 {
        margin-right: 0!important
    }

    .me-xxl-1 {
        margin-right: .25rem!important
    }

    .me-xxl-2 {
        margin-right: .5rem!important
    }

    .me-xxl-3 {
        margin-right: 1rem!important
    }

    .me-xxl-4 {
        margin-right: 1.5rem!important
    }

    .me-xxl-5 {
        margin-right: 3rem!important
    }

    .me-xxl-auto {
        margin-right: auto!important
    }

    .mb-xxl-0 {
        margin-bottom: 0!important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem!important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem!important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem!important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-xxl-5 {
        margin-bottom: 3rem!important
    }

    .mb-xxl-auto {
        margin-bottom: auto!important
    }

    .ms-xxl-0 {
        margin-left: 0!important
    }

    .ms-xxl-1 {
        margin-left: .25rem!important
    }

    .ms-xxl-2 {
        margin-left: .5rem!important
    }

    .ms-xxl-3 {
        margin-left: 1rem!important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem!important
    }

    .ms-xxl-5 {
        margin-left: 3rem!important
    }

    .ms-xxl-auto {
        margin-left: auto!important
    }

    .p-xxl-0 {
        padding: 0!important
    }

    .p-xxl-1 {
        padding: .25rem!important
    }

    .p-xxl-2 {
        padding: .5rem!important
    }

    .p-xxl-3 {
        padding: 1rem!important
    }

    .p-xxl-4 {
        padding: 1.5rem!important
    }

    .p-xxl-5 {
        padding: 3rem!important
    }

    .px-xxl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xxl-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-xxl-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-xxl-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xxl-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xxl-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-xxl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xxl-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-xxl-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-xxl-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xxl-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xxl-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-xxl-0 {
        padding-top: 0!important
    }

    .pt-xxl-1 {
        padding-top: .25rem!important
    }

    .pt-xxl-2 {
        padding-top: .5rem!important
    }

    .pt-xxl-3 {
        padding-top: 1rem!important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem!important
    }

    .pt-xxl-5 {
        padding-top: 3rem!important
    }

    .pe-xxl-0 {
        padding-right: 0!important
    }

    .pe-xxl-1 {
        padding-right: .25rem!important
    }

    .pe-xxl-2 {
        padding-right: .5rem!important
    }

    .pe-xxl-3 {
        padding-right: 1rem!important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem!important
    }

    .pe-xxl-5 {
        padding-right: 3rem!important
    }

    .pb-xxl-0 {
        padding-bottom: 0!important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem!important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem!important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem!important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-xxl-5 {
        padding-bottom: 3rem!important
    }

    .ps-xxl-0 {
        padding-left: 0!important
    }

    .ps-xxl-1 {
        padding-left: .25rem!important
    }

    .ps-xxl-2 {
        padding-left: .5rem!important
    }

    .ps-xxl-3 {
        padding-left: 1rem!important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem!important
    }

    .ps-xxl-5 {
        padding-left: 3rem!important
    }

    .text-xxl-start {
        text-align: left!important
    }

    .text-xxl-end {
        text-align: right!important
    }

    .text-xxl-center {
        text-align: center!important
    }
}

@media(min-width: 1200px) {
    .fs-1 {
        font-size:2.5rem!important
    }

    .fs-2 {
        font-size: 2rem!important
    }

    .fs-3 {
        font-size: 1.75rem!important
    }

    .fs-4 {
        font-size: 1.5rem!important
    }
}

@media print {
    .d-print-inline {
        display: inline!important
    }

    .d-print-inline-block {
        display: inline-block!important
    }

    .d-print-block {
        display: block!important
    }

    .d-print-grid {
        display: grid!important
    }

    .d-print-table {
        display: table!important
    }

    .d-print-table-row {
        display: table-row!important
    }

    .d-print-table-cell {
        display: table-cell!important
    }

    .d-print-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-print-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-print-none {
        display: none!important
    }
}

body {
    color: #000!important
}

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-default: #ffffff;
    --bs-black: #000000;
    --bs-gray: #dcdcdc;
    --bs-light-gray: #f8f8f8;
    --bs-light-blue: #c7eafb;
    --bs-font-sans-serif: Roboto, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: Roboto, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))
}

*,*::before,*::after {
    box-sizing: border-box
}

@media(prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0!important
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25
}

hr:not([size]) {
    height: 1px
}

h6,.h6,h5,.h5,h4,.h4,h3,.h3,h2,.h2,h1,.h1 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

h1,.h1 {
    font-size: calc(1.375rem + 1.5vw)
}

@media(min-width: 1200px) {
    h1,.h1 {
        font-size:2.5rem
    }
}

h2,.h2 {
    font-size: calc(1.325rem + 0.9vw)
}

@media(min-width: 1200px) {
    h2,.h2 {
        font-size:2rem
    }
}

h3,.h3 {
    font-size: calc(1.3rem + 0.6vw)
}

@media(min-width: 1200px) {
    h3,.h3 {
        font-size:1.75rem
    }
}

h4,.h4 {
    font-size: calc(1.275rem + 0.3vw)
}

@media(min-width: 1200px) {
    h4,.h4 {
        font-size:1.5rem
    }
}

h5,.h5 {
    font-size: 1.25rem
}

h6,.h6 {
    font-size: 1rem
}

p {
    margin: 0
}

abbr[title],abbr[data-bs-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,ul {
    padding-left: 2rem
}

ol,ul,dl {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,ul ul,ol ul,ul ol {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,strong {
    font-weight: bolder
}

small,.small {
    font-size: .875em
}

mark,.mark {
    padding: .2em;
    background-color: #fcf8e3
}

sub,sup {
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #0d6efd;
    text-decoration: underline
}

a:hover {
    color: #0a58ca
}

a:not([href]):not([class]),a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

pre,code,kbd,samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: .875em;
    color: #d63384;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: .875em;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700
}

figure {
    margin: 0 0 1rem
}

img,svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: #6c757d;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

thead,tbody,tfoot,tr,td,th {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

input,button,select,optgroup,textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

[list]::-webkit-calendar-picker-indicator {
    display: none
}

button,[type=button],[type=reset],[type=submit] {
    -webkit-appearance: button
}

button:not(:disabled),[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit
}

@media(min-width: 1200px) {
    legend {
        font-size:1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-fields-wrapper,::-webkit-datetime-edit-text,::-webkit-datetime-edit-minute,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::file-selector-button {
    font: inherit
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none!important
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-1 {
        font-size:5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-2 {
        font-size:4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-3 {
        font-size:4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-4 {
        font-size:3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-5 {
        font-size:3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media(min-width: 1200px) {
    .display-6 {
        font-size:2.5rem
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: .875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #6c757d
}

.blockquote-footer::before {
    content: "— "
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: .875em;
    color: #6c757d
}

.container,.container-fluid,.container-xxl,.container-xl,.container-lg,.container-md,.container-sm {
    width: 100%;
    padding-right: var(--bs-gutter-x,0.75rem);
    padding-left: var(--bs-gutter-x,0.75rem);
    margin-right: auto;
    margin-left: auto
}

@media(min-width: 576px) {
    .container-sm,.container {
        max-width:540px
    }
}

@media(min-width: 768px) {
    .container-md,.container-sm,.container {
        max-width:720px
    }
}

@media(min-width: 992px) {
    .container-lg,.container-md,.container-sm,.container {
        max-width:960px
    }
}

@media(min-width: 1200px) {
    .container-xl,.container-lg,.container-md,.container-sm,.container {
        max-width:1140px
    }
}

@media(min-width: 1400px) {
    .container-xxl,.container-xl,.container-lg,.container-md,.container-sm,.container {
        max-width:1232px
    }
}

.row {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x)/-2);
    margin-left: calc(var(--bs-gutter-x)/-2)
}

.row>* {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)/2);
    padding-left: calc(var(--bs-gutter-x)/2);
    margin-top: var(--bs-gutter-y)
}

.col {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%
}

.row-cols-auto>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.3333333333%
}

.col-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%
}

.col-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.6666666667%
}

.col-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.3333333333%
}

.col-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.6666666667%
}

.col-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.3333333333%
}

.col-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.6666666667%
}

.col-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.3333333333%
}

.offset-2 {
    margin-left: 16.6666666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.3333333333%
}

.offset-5 {
    margin-left: 41.6666666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.3333333333%
}

.offset-8 {
    margin-left: 66.6666666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.3333333333%
}

.offset-11 {
    margin-left: 91.6666666667%
}

.g-0,.gx-0 {
    --bs-gutter-x: 0
}

.g-0,.gy-0 {
    --bs-gutter-y: 0
}

.g-1,.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,.gx-3 {
    --bs-gutter-x: 1rem
}

.g-3,.gy-3 {
    --bs-gutter-y: 1rem
}

.g-4,.gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4,.gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5,.gx-5 {
    --bs-gutter-x: 3rem
}

.g-5,.gy-5 {
    --bs-gutter-y: 3rem
}

@media(min-width: 576px) {
    .col-sm {
        -ms-flex:1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-sm-auto>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.3333333333%
    }

    .col-sm-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .col-sm-5 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.6666666667%
    }

    .col-sm-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.3333333333%
    }

    .col-sm-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.6666666667%
    }

    .col-sm-9 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.3333333333%
    }

    .col-sm-11 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.6666666667%
    }

    .col-sm-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.3333333333%
    }

    .offset-sm-2 {
        margin-left: 16.6666666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.3333333333%
    }

    .offset-sm-5 {
        margin-left: 41.6666666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.3333333333%
    }

    .offset-sm-8 {
        margin-left: 66.6666666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.3333333333%
    }

    .offset-sm-11 {
        margin-left: 91.6666666667%
    }

    .g-sm-0,.gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0,.gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1,.gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1,.gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2,.gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2,.gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3,.gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3,.gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4,.gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4,.gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5,.gx-sm-5 {
        --bs-gutter-x: 3rem
    }

    .g-sm-5,.gy-sm-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 768px) {
    .col-md {
        -ms-flex:1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.3333333333%
    }

    .col-md-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .col-md-5 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.6666666667%
    }

    .col-md-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.3333333333%
    }

    .col-md-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.6666666667%
    }

    .col-md-9 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.3333333333%
    }

    .col-md-11 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.6666666667%
    }

    .col-md-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.3333333333%
    }

    .offset-md-2 {
        margin-left: 16.6666666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.3333333333%
    }

    .offset-md-5 {
        margin-left: 41.6666666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.3333333333%
    }

    .offset-md-8 {
        margin-left: 66.6666666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.3333333333%
    }

    .offset-md-11 {
        margin-left: 91.6666666667%
    }

    .g-md-0,.gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,.gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,.gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,.gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,.gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,.gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,.gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3,.gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4,.gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4,.gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5,.gx-md-5 {
        --bs-gutter-x: 3rem
    }

    .g-md-5,.gy-md-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 992px) {
    .col-lg {
        -ms-flex:1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.3333333333%
    }

    .col-lg-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .col-lg-5 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.6666666667%
    }

    .col-lg-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.3333333333%
    }

    .col-lg-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.6666666667%
    }

    .col-lg-9 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.3333333333%
    }

    .col-lg-11 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.6666666667%
    }

    .col-lg-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.3333333333%
    }

    .offset-lg-2 {
        margin-left: 16.6666666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.3333333333%
    }

    .offset-lg-5 {
        margin-left: 41.6666666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.3333333333%
    }

    .offset-lg-8 {
        margin-left: 66.6666666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.3333333333%
    }

    .offset-lg-11 {
        margin-left: 91.6666666667%
    }

    .g-lg-0,.gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,.gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,.gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,.gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,.gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,.gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,.gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,.gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,.gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,.gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,.gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5,.gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 1200px) {
    .col-xl {
        -ms-flex:1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.3333333333%
    }

    .col-xl-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .col-xl-5 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.6666666667%
    }

    .col-xl-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.3333333333%
    }

    .col-xl-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.6666666667%
    }

    .col-xl-9 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.3333333333%
    }

    .col-xl-11 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.6666666667%
    }

    .col-xl-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.3333333333%
    }

    .offset-xl-2 {
        margin-left: 16.6666666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.3333333333%
    }

    .offset-xl-5 {
        margin-left: 41.6666666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.3333333333%
    }

    .offset-xl-8 {
        margin-left: 66.6666666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.3333333333%
    }

    .offset-xl-11 {
        margin-left: 91.6666666667%
    }

    .g-xl-0,.gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,.gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,.gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,.gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,.gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,.gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,.gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3,.gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4,.gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4,.gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5,.gx-xl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xl-5,.gy-xl-5 {
        --bs-gutter-y: 3rem
    }
}

@media(min-width: 1400px) {
    .col-xxl {
        -ms-flex:1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.3333333333%
    }

    .col-xxl-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .col-xxl-5 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.6666666667%
    }

    .col-xxl-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.3333333333%
    }

    .col-xxl-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.6666666667%
    }

    .col-xxl-9 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.3333333333%
    }

    .col-xxl-11 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.6666666667%
    }

    .col-xxl-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.3333333333%
    }

    .offset-xxl-2 {
        margin-left: 16.6666666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.3333333333%
    }

    .offset-xxl-5 {
        margin-left: 41.6666666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.3333333333%
    }

    .offset-xxl-8 {
        margin-left: 66.6666666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.3333333333%
    }

    .offset-xxl-11 {
        margin-left: 91.6666666667%
    }

    .g-xxl-0,.gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0,.gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1,.gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1,.gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2,.gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2,.gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3,.gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3,.gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4,.gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4,.gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5,.gx-xxl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-5,.gy-xxl-5 {
        --bs-gutter-y: 3rem
    }
}

.table {
    --bs-table-bg: transparent;
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6
}

.table>:not(caption)>*>* {
    padding: .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: currentColor
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .25rem
}

.table-bordered>:not(caption)>* {
    border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-bg: #cfe2ff;
    --bs-table-striped-bg: #c5d7f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bacbe6
}

.table-secondary {
    --bs-table-bg: #e2e3e5;
    --bs-table-striped-bg: #d7d8da;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cbccce;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d1d2d4;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #cbccce
}

.table-success {
    --bs-table-bg: #d1e7dd;
    --bs-table-striped-bg: #c7dbd2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bcd0c7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c1d6cc;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bcd0c7
}

.table-info {
    --bs-table-bg: #cff4fc;
    --bs-table-striped-bg: #c5e8ef;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #badce3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfe2e9;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #badce3
}

.table-warning {
    --bs-table-bg: #fff3cd;
    --bs-table-striped-bg: #f2e7c3;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6dbb9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece1be;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6dbb9
}

.table-danger {
    --bs-table-bg: #f8d7da;
    --bs-table-striped-bg: #eccccf;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfc2c4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5c7ca;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfc2c4
}

.table-light {
    --bs-table-bg: #f8f9fa;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfe0e1
}

.table-dark {
    --bs-table-bg: #212529;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #373b3e
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media(max-width: 575.98px) {
    .table-responsive-sm {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 767.98px) {
    .table-responsive-md {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 1199.98px) {
    .table-responsive-xl {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media(max-width: 1399.98px) {
    .table-responsive-xxl {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: 6px;
    font-size: .8125rem;
    font-weight: 700;
    line-height: 1
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: .875rem
}

.form-text {
    margin-top: .25rem;
    font-size: .875em;
    color: #6c757d
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    color: #0e566c;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dcdcdc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:valid {
    border-color: #dcdcdc;
    color: #000
}

.form-control:hover {
    border-color: #9bb1be
}

.form-control:focus {
    color: #0e566c;
    background-color: #fff;
    border-color: #bedfe6;
    outline: 0;
    box-shadow: none
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::-webkit-input-placeholder {
    color: #dcdcdc;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #dcdcdc;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #dcdcdc;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #dcdcdc;
    opacity: 1
}

.form-control::placeholder {
    color: #dcdcdc;
    opacity: 1
}

.form-control:disabled,.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

.form-control::file-selector-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    -moz-margin-end: .75rem;
    margin-inline-end:.75rem;color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width:1px;border-radius: 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3
}

.form-control::-webkit-file-upload-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end:.75rem;color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width:1px;border-radius: 0;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-sm,.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.form-control-sm::file-selector-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    -moz-margin-end: .5rem;
    margin-inline-end:.5rem}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end:.5rem}

.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.form-control-lg::file-selector-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    -moz-margin-end: 1rem;
    margin-inline-end:1rem}

.form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end:1rem}

textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px)
}

textarea.form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px)
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px)
}

.form-control-color {
    max-width: 3rem;
    height: auto;
    padding: .375rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: .25rem
}

.form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: .25rem
}

.invalid label {
    color: #973937
}

.invalid .form-control {
    color: #973937;
    background-color: #fff6f6;
    border-color: #ebcccc
}

.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMzQzYTQwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMicgZD0nTTIgNWw2IDYgNi02Jy8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25)
}

.form-select[multiple],.form-select[size]:not([size="1"]) {
    padding-right: .75rem;
    background-image: none
}

.form-select:disabled {
    color: #6c757d;
    background-color: #e9ecef
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .875rem
}

.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.25rem
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.625em;
    margin-bottom: .125rem;
    font-size: 14px
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.625em
}

.form-check-input {
    width: 18px;
    height: 18px;
    margin-top: .1875em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact
}

.form-check-input[type=checkbox] {
    background-image: url(../svg/form-checkbox.svg);
    border: none;
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%;
    border-color: #063d5d;
    border-width: 2px;
    position: relative
}

.form-check-input:active {
    -webkit-filter: brightness(90%);
    filter: brightness(90%)
}

.form-check-input:focus {
    outline: 0
}

.form-check-input:checked {
    background-color: rgba(6,61,93,.3)
}

.form-check-input:checked[type=checkbox] {
    background-image: url(../svg/form-checkbox-checked.svg);
    border: none
}

.form-check-input:checked[type=radio] {
    background-color: #fff
}

.form-check-input:checked[type=radio]::before {
    content: "";
    display: block;
    width: 9px;
    height: 9px;
    background: #063d5d;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjZmZmJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nTTYgMTBoOCcvPjwvc3ZnPg==)
}

.form-check-input:disabled {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: .5
}

.form-check-input[disabled]~.form-check-label,.form-check-input:disabled~.form-check-label {
    opacity: .5
}

.form-switch {
    padding-left: 3.5625em
}

.form-switch .form-check-input {
    width: 3.0625em;
    height: 22px;
    margin-left: -3.5625em;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSc0JyBmaWxsPScjZmZmJy8+PC9zdmc+);
    background-color: #dcdcdc;
    background-position: left center;
    border-radius: 3.0625em;
    transition: background-position .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-color: #063d5d;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSc0JyBmaWxsPScjZmZmJy8+PC9zdmc+)
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none
}

.btn-check[disabled]+.btn,.btn-check:disabled+.btn {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: .65
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff,none
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff,none
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #b6d4fe
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #b6d4fe
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
}

.form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
}

.form-floating {
    position: relative
}

.form-floating>.form-control,.form-floating>.form-select {
    height: calc(3.5rem + 2px);
    padding: 1rem .75rem
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: 1px solid transparent;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out,-webkit-transform .1s ease-in-out;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out,-webkit-transform .1s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control::-webkit-input-placeholder {
    color: transparent
}

.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control:-ms-input-placeholder {
    color: transparent
}

.form-floating>.form-control::-ms-input-placeholder {
    color: transparent
}

.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:not(:-ms-input-placeholder) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:focus,.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    opacity: .65;
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control:not(:-ms-input-placeholder)~label {
    opacity: .65;
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control:focus~label,.form-floating>.form-control:not(:placeholder-shown)~label,.form-floating>.form-select~label {
    opacity: .65;
    -webkit-transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    -webkit-transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem)
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,.input-group>.form-select {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus,.input-group>.form-select:focus {
    z-index: 3
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 3
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.input-group-lg>.form-control,.input-group-lg>.form-select,.input-group-lg>.input-group-text,.input-group-lg>.btn {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.input-group-sm>.form-control,.input-group-sm>.form-select,.input-group-sm>.input-group-text,.input-group-sm>.btn {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.input-group-lg>.form-select,.input-group-sm>.form-select {
    padding-right: 3rem
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #198754
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(25,135,84,.9);
    border-radius: .25rem
}

.was-validated :valid~.valid-feedback,.was-validated :valid~.valid-tooltip,.is-valid~.valid-feedback,.is-valid~.valid-tooltip {
    display: block
}

.was-validated .form-control:valid,.form-control.is-valid {
    border-color: #198754;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMxOTg3NTQnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-control:valid:focus,.form-control.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25,135,84,.25)
}

.was-validated textarea.form-control:valid,textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}

.was-validated .form-select:valid,.form-select.is-valid {
    border-color: #198754;
    padding-right: 4.125rem;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMzQzYTQwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMicgZD0nTTIgNWw2IDYgNi02Jy8+PC9zdmc+),url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMxOTg3NTQnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
    background-position: right .75rem center,center right 2.25rem;
    background-size: 16px 12px,calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-select:valid:focus,.form-select.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 .25rem rgba(25,135,84,.25)
}

.was-validated .form-check-input:valid,.form-check-input.is-valid {
    border-color: #198754
}

.was-validated .form-check-input:valid:checked,.form-check-input.is-valid:checked {
    background-color: #198754
}

.was-validated .form-check-input:valid:focus,.form-check-input.is-valid:focus {
    box-shadow: 0 0 0 .25rem rgba(25,135,84,.25)
}

.was-validated .form-check-input:valid~.form-check-label,.form-check-input.is-valid~.form-check-label {
    color: #198754
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(220,53,69,.9);
    border-radius: .25rem
}

.was-validated :invalid~.invalid-feedback,.was-validated :invalid~.invalid-tooltip,.is-invalid~.invalid-feedback,.is-invalid~.invalid-tooltip {
    display: block
}

.was-validated .form-control:invalid,.form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMiAxMicgd2lkdGg9JzEyJyBoZWlnaHQ9JzEyJyBmaWxsPSdub25lJyBzdHJva2U9JyNkYzM1NDUnPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2RjMzU0NScgc3Ryb2tlPSdub25lJy8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-control:invalid:focus,.form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220,53,69,.25)
}

.was-validated textarea.form-control:invalid,textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem)
}

.was-validated .form-select:invalid,.form-select.is-invalid {
    border-color: #dc3545;
    padding-right: 4.125rem;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMzQzYTQwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMicgZD0nTTIgNWw2IDYgNi02Jy8+PC9zdmc+),url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMiAxMicgd2lkdGg9JzEyJyBoZWlnaHQ9JzEyJyBmaWxsPSdub25lJyBzdHJva2U9JyNkYzM1NDUnPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2RjMzU0NScgc3Ryb2tlPSdub25lJy8+PC9zdmc+);
    background-position: right .75rem center,center right 2.25rem;
    background-size: 16px 12px,calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.was-validated .form-select:invalid:focus,.form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220,53,69,.25)
}

.was-validated .form-check-input:invalid,.form-check-input.is-invalid {
    border-color: #dc3545
}

.was-validated .form-check-input:invalid:checked,.form-check-input.is-invalid:checked {
    background-color: #dc3545
}

.was-validated .form-check-input:invalid:focus,.form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 .25rem rgba(220,53,69,.25)
}

.was-validated .form-check-input:invalid~.form-check-label,.form-check-input.is-invalid~.form-check-label {
    color: #dc3545
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.btn {
    display: inline-block;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border-width: 1px;
    padding: 10px 26px;
    font-size: .875rem;
    border-radius: 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #000
}

.btn-check:focus+.btn,.btn:focus {
    outline: 0;
    box-shadow: none!important
}

.btn:disabled,.btn.disabled,fieldset:disabled .btn {
    pointer-events: none;
    opacity: .65
}

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca
}

.btn-check:checked+.btn-primary,.btn-check:active+.btn-primary,.btn-primary:focus,.btn-primary:active,.btn-primary.active,.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be
}

.btn-check:checked+.btn-primary:focus,.btn-check:active+.btn-primary:focus,.btn-primary:focus:focus,.btn-primary:active:focus,.btn-primary.active:focus,.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(49,132,253,.5)
}

.btn-primary:disabled,.btn-primary.disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64
}

.btn-check:checked+.btn-secondary,.btn-check:active+.btn-secondary,.btn-secondary:focus,.btn-secondary:active,.btn-secondary.active,.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e
}

.btn-check:checked+.btn-secondary:focus,.btn-check:active+.btn-secondary:focus,.btn-secondary:focus:focus,.btn-secondary:active:focus,.btn-secondary.active:focus,.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(130,138,145,.5)
}

.btn-secondary:disabled,.btn-secondary.disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43
}

.btn-check:checked+.btn-success,.btn-check:active+.btn-success,.btn-success:focus,.btn-success:active,.btn-success.active,.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f
}

.btn-check:checked+.btn-success:focus,.btn-check:active+.btn-success:focus,.btn-success:focus:focus,.btn-success:active:focus,.btn-success.active:focus,.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(60,153,110,.5)
}

.btn-success:disabled,.btn-success.disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-info {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2
}

.btn-check:checked+.btn-info,.btn-check:active+.btn-info,.btn-info:focus,.btn-info:active,.btn-info.active,.show>.btn-info.dropdown-toggle {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2
}

.btn-check:checked+.btn-info:focus,.btn-check:active+.btn-info:focus,.btn-info:focus:focus,.btn-info:active:focus,.btn-info.active:focus,.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(11,172,204,.5)
}

.btn-info:disabled,.btn-info.disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-warning {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720
}

.btn-check:checked+.btn-warning,.btn-check:active+.btn-warning,.btn-warning:focus,.btn-warning:active,.btn-warning.active,.show>.btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720
}

.btn-check:checked+.btn-warning:focus,.btn-check:active+.btn-warning:focus,.btn-warning:focus:focus,.btn-warning:active:focus,.btn-warning.active:focus,.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(217,164,6,.5)
}

.btn-warning:disabled,.btn-warning.disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37
}

.btn-check:checked+.btn-danger,.btn-check:active+.btn-danger,.btn-danger:focus,.btn-danger:active,.btn-danger.active,.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834
}

.btn-check:checked+.btn-danger:focus,.btn-check:active+.btn-danger:focus,.btn-danger:focus:focus,.btn-danger:active:focus,.btn-danger.active:focus,.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(225,83,97,.5)
}

.btn-danger:disabled,.btn-danger.disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-light {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:hover {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb
}

.btn-check:checked+.btn-light,.btn-check:active+.btn-light,.btn-light:focus,.btn-light:active,.btn-light.active,.show>.btn-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb
}

.btn-check:checked+.btn-light:focus,.btn-check:active+.btn-light:focus,.btn-light:focus:focus,.btn-light:active:focus,.btn-light.active:focus,.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(211,212,213,.5)
}

.btn-light:disabled,.btn-light.disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-dark {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-dark:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21
}

.btn-check:checked+.btn-dark,.btn-check:active+.btn-dark,.btn-dark:focus,.btn-dark:active,.btn-dark.active,.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f
}

.btn-check:checked+.btn-dark:focus,.btn-check:active+.btn-dark:focus,.btn-dark:focus:focus,.btn-dark:active:focus,.btn-dark.active:focus,.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(66,70,73,.5)
}

.btn-dark:disabled,.btn-dark.disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-default {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-default:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:checked+.btn-default,.btn-check:active+.btn-default,.btn-default:focus,.btn-default:active,.btn-default.active,.show>.btn-default.dropdown-toggle {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:checked+.btn-default:focus,.btn-check:active+.btn-default:focus,.btn-default:focus:focus,.btn-default:active:focus,.btn-default.active:focus,.show>.btn-default.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(217,217,217,.5)
}

.btn-default:disabled,.btn-default.disabled {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-black {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-check:checked+.btn-black,.btn-check:active+.btn-black,.btn-black:focus,.btn-black:active,.btn-black.active,.show>.btn-black.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-check:checked+.btn-black:focus,.btn-check:active+.btn-black:focus,.btn-black:focus:focus,.btn-black:active:focus,.btn-black.active:focus,.show>.btn-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(38,38,38,.5)
}

.btn-black:disabled,.btn-black.disabled {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-gray {
    color: #000;
    background-color: #dcdcdc;
    border-color: #dcdcdc
}

.btn-gray:hover {
    color: #000;
    background-color: #e1e1e1;
    border-color: #e0e0e0
}

.btn-check:checked+.btn-gray,.btn-check:active+.btn-gray,.btn-gray:focus,.btn-gray:active,.btn-gray.active,.show>.btn-gray.dropdown-toggle {
    color: #000;
    background-color: #e3e3e3;
    border-color: #e0e0e0
}

.btn-check:checked+.btn-gray:focus,.btn-check:active+.btn-gray:focus,.btn-gray:focus:focus,.btn-gray:active:focus,.btn-gray.active:focus,.show>.btn-gray.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(187,187,187,.5)
}

.btn-gray:disabled,.btn-gray.disabled {
    color: #000;
    background-color: #dcdcdc;
    border-color: #dcdcdc
}

.btn-light-gray {
    color: #000;
    background-color: #f8f8f8;
    border-color: #f8f8f8
}

.btn-light-gray:hover {
    color: #000;
    background-color: #f9f9f9;
    border-color: #f9f9f9
}

.btn-check:checked+.btn-light-gray,.btn-check:active+.btn-light-gray,.btn-light-gray:focus,.btn-light-gray:active,.btn-light-gray.active,.show>.btn-light-gray.dropdown-toggle {
    color: #000;
    background-color: #f9f9f9;
    border-color: #f9f9f9
}

.btn-check:checked+.btn-light-gray:focus,.btn-check:active+.btn-light-gray:focus,.btn-light-gray:focus:focus,.btn-light-gray:active:focus,.btn-light-gray.active:focus,.show>.btn-light-gray.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(211,211,211,.5)
}

.btn-light-gray:disabled,.btn-light-gray.disabled {
    color: #000;
    background-color: #f8f8f8;
    border-color: #f8f8f8
}

.btn-light-blue {
    color: #000;
    background-color: #c7eafb;
    border-color: #c7eafb
}

.btn-light-blue:hover {
    color: #000;
    background-color: #cfedfc;
    border-color: #cdecfb
}

.btn-check:checked+.btn-light-blue,.btn-check:active+.btn-light-blue,.btn-light-blue:focus,.btn-light-blue:active,.btn-light-blue.active,.show>.btn-light-blue.dropdown-toggle {
    color: #000;
    background-color: #d2eefc;
    border-color: #cdecfb
}

.btn-check:checked+.btn-light-blue:focus,.btn-check:active+.btn-light-blue:focus,.btn-light-blue:focus:focus,.btn-light-blue:active:focus,.btn-light-blue.active:focus,.show>.btn-light-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(169,199,213,.5)
}

.btn-light-blue:disabled,.btn-light-blue.disabled {
    color: #000;
    background-color: #c7eafb;
    border-color: #c7eafb
}

.btn-outline-primary {
    color: #0d6efd;
    border-color: #0d6efd
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-check:focus+.btn-outline-primary,.btn-outline-primary:focus {
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.5)
}

.btn-check:checked+.btn-outline-primary,.btn-check:active+.btn-outline-primary,.btn-outline-primary:active,.btn-outline-primary.active,.btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-check:checked+.btn-outline-primary:focus,.btn-check:active+.btn-outline-primary:focus,.btn-outline-primary:active:focus,.btn-outline-primary.active:focus,.btn-outline-primary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.5)
}

.btn-outline-primary:disabled,.btn-outline-primary.disabled {
    color: #0d6efd;
    background-color: transparent
}

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-check:focus+.btn-outline-secondary,.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .25rem rgba(108,117,125,.5)
}

.btn-check:checked+.btn-outline-secondary,.btn-check:active+.btn-outline-secondary,.btn-outline-secondary:active,.btn-outline-secondary.active,.btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-check:checked+.btn-outline-secondary:focus,.btn-check:active+.btn-outline-secondary:focus,.btn-outline-secondary:active:focus,.btn-outline-secondary.active:focus,.btn-outline-secondary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(108,117,125,.5)
}

.btn-outline-secondary:disabled,.btn-outline-secondary.disabled {
    color: #6c757d;
    background-color: transparent
}

.btn-outline-success {
    color: #198754;
    border-color: #198754
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-check:focus+.btn-outline-success,.btn-outline-success:focus {
    box-shadow: 0 0 0 .25rem rgba(25,135,84,.5)
}

.btn-check:checked+.btn-outline-success,.btn-check:active+.btn-outline-success,.btn-outline-success:active,.btn-outline-success.active,.btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #198754;
    border-color: #198754
}

.btn-check:checked+.btn-outline-success:focus,.btn-check:active+.btn-outline-success:focus,.btn-outline-success:active:focus,.btn-outline-success.active:focus,.btn-outline-success.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(25,135,84,.5)
}

.btn-outline-success:disabled,.btn-outline-success.disabled {
    color: #198754;
    background-color: transparent
}

.btn-outline-info {
    color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-check:focus+.btn-outline-info,.btn-outline-info:focus {
    box-shadow: 0 0 0 .25rem rgba(13,202,240,.5)
}

.btn-check:checked+.btn-outline-info,.btn-check:active+.btn-outline-info,.btn-outline-info:active,.btn-outline-info.active,.btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0
}

.btn-check:checked+.btn-outline-info:focus,.btn-check:active+.btn-outline-info:focus,.btn-outline-info:active:focus,.btn-outline-info.active:focus,.btn-outline-info.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(13,202,240,.5)
}

.btn-outline-info:disabled,.btn-outline-info.disabled {
    color: #0dcaf0;
    background-color: transparent
}

.btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:focus+.btn-outline-warning,.btn-outline-warning:focus {
    box-shadow: 0 0 0 .25rem rgba(255,193,7,.5)
}

.btn-check:checked+.btn-outline-warning,.btn-check:active+.btn-outline-warning,.btn-outline-warning:active,.btn-outline-warning.active,.btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:checked+.btn-outline-warning:focus,.btn-check:active+.btn-outline-warning:focus,.btn-outline-warning:active:focus,.btn-outline-warning.active:focus,.btn-outline-warning.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(255,193,7,.5)
}

.btn-outline-warning:disabled,.btn-outline-warning.disabled {
    color: #ffc107;
    background-color: transparent
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-check:focus+.btn-outline-danger,.btn-outline-danger:focus {
    box-shadow: 0 0 0 .25rem rgba(220,53,69,.5)
}

.btn-check:checked+.btn-outline-danger,.btn-check:active+.btn-outline-danger,.btn-outline-danger:active,.btn-outline-danger.active,.btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-check:checked+.btn-outline-danger:focus,.btn-check:active+.btn-outline-danger:focus,.btn-outline-danger:active:focus,.btn-outline-danger.active:focus,.btn-outline-danger.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(220,53,69,.5)
}

.btn-outline-danger:disabled,.btn-outline-danger.disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-check:focus+.btn-outline-light,.btn-outline-light:focus {
    box-shadow: 0 0 0 .25rem rgba(248,249,250,.5)
}

.btn-check:checked+.btn-outline-light,.btn-check:active+.btn-outline-light,.btn-outline-light:active,.btn-outline-light.active,.btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-check:checked+.btn-outline-light:focus,.btn-check:active+.btn-outline-light:focus,.btn-outline-light:active:focus,.btn-outline-light.active:focus,.btn-outline-light.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(248,249,250,.5)
}

.btn-outline-light:disabled,.btn-outline-light.disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-dark {
    color: #212529;
    border-color: #212529
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-check:focus+.btn-outline-dark,.btn-outline-dark:focus {
    box-shadow: 0 0 0 .25rem rgba(33,37,41,.5)
}

.btn-check:checked+.btn-outline-dark,.btn-check:active+.btn-outline-dark,.btn-outline-dark:active,.btn-outline-dark.active,.btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #212529;
    border-color: #212529
}

.btn-check:checked+.btn-outline-dark:focus,.btn-check:active+.btn-outline-dark:focus,.btn-outline-dark:active:focus,.btn-outline-dark.active:focus,.btn-outline-dark.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(33,37,41,.5)
}

.btn-outline-dark:disabled,.btn-outline-dark.disabled {
    color: #212529;
    background-color: transparent
}

.btn-outline-default {
    color: #fff;
    border-color: #fff
}

.btn-outline-default:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:focus+.btn-outline-default,.btn-outline-default:focus {
    box-shadow: 0 0 0 .25rem rgba(255,255,255,.5)
}

.btn-check:checked+.btn-outline-default,.btn-check:active+.btn-outline-default,.btn-outline-default:active,.btn-outline-default.active,.btn-outline-default.dropdown-toggle.show {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:checked+.btn-outline-default:focus,.btn-check:active+.btn-outline-default:focus,.btn-outline-default:active:focus,.btn-outline-default.active:focus,.btn-outline-default.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(255,255,255,.5)
}

.btn-outline-default:disabled,.btn-outline-default.disabled {
    color: #fff;
    background-color: transparent
}

.btn-outline-black {
    color: #000;
    border-color: #000
}

.btn-outline-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-check:focus+.btn-outline-black,.btn-outline-black:focus {
    box-shadow: 0 0 0 .25rem rgba(0,0,0,.5)
}

.btn-check:checked+.btn-outline-black,.btn-check:active+.btn-outline-black,.btn-outline-black:active,.btn-outline-black.active,.btn-outline-black.dropdown-toggle.show {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-check:checked+.btn-outline-black:focus,.btn-check:active+.btn-outline-black:focus,.btn-outline-black:active:focus,.btn-outline-black.active:focus,.btn-outline-black.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(0,0,0,.5)
}

.btn-outline-black:disabled,.btn-outline-black.disabled {
    color: #000;
    background-color: transparent
}

.btn-outline-gray {
    color: #dcdcdc;
    border-color: #dcdcdc
}

.btn-outline-gray:hover {
    color: #000;
    background-color: #dcdcdc;
    border-color: #dcdcdc
}

.btn-check:focus+.btn-outline-gray,.btn-outline-gray:focus {
    box-shadow: 0 0 0 .25rem rgba(220,220,220,.5)
}

.btn-check:checked+.btn-outline-gray,.btn-check:active+.btn-outline-gray,.btn-outline-gray:active,.btn-outline-gray.active,.btn-outline-gray.dropdown-toggle.show {
    color: #000;
    background-color: #dcdcdc;
    border-color: #dcdcdc
}

.btn-check:checked+.btn-outline-gray:focus,.btn-check:active+.btn-outline-gray:focus,.btn-outline-gray:active:focus,.btn-outline-gray.active:focus,.btn-outline-gray.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(220,220,220,.5)
}

.btn-outline-gray:disabled,.btn-outline-gray.disabled {
    color: #dcdcdc;
    background-color: transparent
}

.btn-outline-light-gray {
    color: #f8f8f8;
    border-color: #f8f8f8
}

.btn-outline-light-gray:hover {
    color: #000;
    background-color: #f8f8f8;
    border-color: #f8f8f8
}

.btn-check:focus+.btn-outline-light-gray,.btn-outline-light-gray:focus {
    box-shadow: 0 0 0 .25rem rgba(248,248,248,.5)
}

.btn-check:checked+.btn-outline-light-gray,.btn-check:active+.btn-outline-light-gray,.btn-outline-light-gray:active,.btn-outline-light-gray.active,.btn-outline-light-gray.dropdown-toggle.show {
    color: #000;
    background-color: #f8f8f8;
    border-color: #f8f8f8
}

.btn-check:checked+.btn-outline-light-gray:focus,.btn-check:active+.btn-outline-light-gray:focus,.btn-outline-light-gray:active:focus,.btn-outline-light-gray.active:focus,.btn-outline-light-gray.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(248,248,248,.5)
}

.btn-outline-light-gray:disabled,.btn-outline-light-gray.disabled {
    color: #f8f8f8;
    background-color: transparent
}

.btn-outline-light-blue {
    color: #c7eafb;
    border-color: #c7eafb
}

.btn-outline-light-blue:hover {
    color: #000;
    background-color: #c7eafb;
    border-color: #c7eafb
}

.btn-check:focus+.btn-outline-light-blue,.btn-outline-light-blue:focus {
    box-shadow: 0 0 0 .25rem rgba(199,234,251,.5)
}

.btn-check:checked+.btn-outline-light-blue,.btn-check:active+.btn-outline-light-blue,.btn-outline-light-blue:active,.btn-outline-light-blue.active,.btn-outline-light-blue.dropdown-toggle.show {
    color: #000;
    background-color: #c7eafb;
    border-color: #c7eafb
}

.btn-check:checked+.btn-outline-light-blue:focus,.btn-check:active+.btn-outline-light-blue:focus,.btn-outline-light-blue:active:focus,.btn-outline-light-blue.active:focus,.btn-outline-light-blue.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 .25rem rgba(199,234,251,.5)
}

.btn-outline-light-blue:disabled,.btn-outline-light-blue.disabled {
    color: #c7eafb;
    background-color: transparent
}

.btn-link {
    font-weight: 400;
    color: #0d6efd;
    text-decoration: underline
}

.btn-link:hover {
    color: #0a58ca
}

.btn-link:disabled,.btn-link.disabled {
    color: #6c757d
}

.btn-lg,.btn-group-lg>.btn {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.btn-sm,.btn-group-sm>.btn {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem
}

.fade {
    transition: opacity .15s linear
}

@media(prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media(prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.dropup,.dropend,.dropdown,.dropstart {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem
}

.dropdown-menu[data-bs-popper] {
    left: 0;
    margin-top: .125rem
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media(min-width: 576px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media(min-width: 1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%
}

.dropup .dropdown-menu[data-bs-popper] {
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%
}

.dropend .dropdown-menu[data-bs-popper] {
    margin-top: 0;
    margin-left: .125rem
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto
}

.dropstart .dropdown-menu[data-bs-popper] {
    margin-top: 0;
    margin-right: .125rem
}

.dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropstart .dropdown-toggle::after {
    display: none
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0,0,0,.15)
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:hover,.dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef
}

.dropdown-item.active,.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd
}

.dropdown-item.disabled,.dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #6c757d;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .25rem 1rem;
    color: #212529
}

.dropdown-menu-dark {
    color: #dee2e6;
    background-color: #343a40;
    border-color: rgba(0,0,0,.15)
}

.dropdown-menu-dark .dropdown-item {
    color: #dee2e6
}

.dropdown-menu-dark .dropdown-item:hover,.dropdown-menu-dark .dropdown-item:focus {
    color: #fff;
    background-color: rgba(255,255,255,.15)
}

.dropdown-menu-dark .dropdown-item.active,.dropdown-menu-dark .dropdown-item:active {
    color: #fff;
    background-color: #0d6efd
}

.dropdown-menu-dark .dropdown-item.disabled,.dropdown-menu-dark .dropdown-item:disabled {
    color: #adb5bd
}

.dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0,0,0,.15)
}

.dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6
}

.dropdown-menu-dark .dropdown-header {
    color: #adb5bd
}

.btn-group,.btn-group-vertical {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle
}

.btn-group>.btn,.btn-group-vertical>.btn {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.btn-group>.btn-check:checked+.btn,.btn-group>.btn-check:focus+.btn,.btn-group>.btn:hover,.btn-group>.btn:focus,.btn-group>.btn:active,.btn-group>.btn.active,.btn-group-vertical>.btn-check:checked+.btn,.btn-group-vertical>.btn-check:focus+.btn,.btn-group-vertical>.btn:hover,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn.active {
    z-index: 1
}

.btn-toolbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn:not(:first-child),.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn:nth-child(n+3),.btn-group>:not(.btn-check)+.btn,.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: 19.5px;
    padding-left: 19.5px
}

.dropdown-toggle-split::after,.dropup .dropdown-toggle-split::after,.dropend .dropdown-toggle-split::after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-sm+.dropdown-toggle-split,.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-lg+.dropdown-toggle-split,.btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center
}

.btn-group-vertical>.btn,.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn:not(:first-child),.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),.btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn~.btn,.btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.nav-tabs .nav-link:hover,.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-link.active,.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: .25rem
}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0d6efd
}

.nav-fill>.nav-link,.nav-fill .nav-item {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center
}

.nav-justified>.nav-link,.nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar>.container,.navbar>.container-fluid,.navbar>.container-sm,.navbar>.container-md,.navbar>.container-lg,.navbar>.container-xl,.navbar>.container-xxl {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.navbar-brand {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 .25rem
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height,75vh);
    overflow-y: auto
}

@media(min-width: 576px) {
    .navbar-expand-sm {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media(min-width: 768px) {
    .navbar-expand-md {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media(min-width: 992px) {
    .navbar-expand-lg {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media(min-width: 1200px) {
    .navbar-expand-xl {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

@media(min-width: 1400px) {
    .navbar-expand-xxl {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand {
    color: rgba(0,0,0,.9)
}

.navbar-light .navbar-brand:hover,.navbar-light .navbar-brand:focus {
    color: rgba(0,0,0,.9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,.55)
}

.navbar-light .navbar-nav .nav-link:hover,.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0,0,0,.7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0,0,0,.3)
}

.navbar-light .navbar-nav .show>.nav-link,.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0,0,0,.9)
}

.navbar-light .navbar-toggler {
    color: rgba(0,0,0,.55);
    border-color: rgba(0,0,0,.1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzMCAzMCc+PHBhdGggc3Ryb2tlPSdyZ2JhKDAsIDAsIDAsIDAuNTUpJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1taXRlcmxpbWl0PScxMCcgc3Ryb2tlLXdpZHRoPScyJyBkPSdNNCA3aDIyTTQgMTVoMjJNNCAyM2gyMicvPjwvc3ZnPg==)
}

.navbar-light .navbar-text {
    color: rgba(0,0,0,.55)
}

.navbar-light .navbar-text a,.navbar-light .navbar-text a:hover,.navbar-light .navbar-text a:focus {
    color: rgba(0,0,0,.9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:hover,.navbar-dark .navbar-brand:focus {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,.55)
}

.navbar-dark .navbar-nav .nav-link:hover,.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255,255,255,.75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255,255,255,.25)
}

.navbar-dark .navbar-nav .show>.nav-link,.navbar-dark .navbar-nav .nav-link.active {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255,255,255,.55);
    border-color: rgba(255,255,255,.1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzMCAzMCc+PHBhdGggc3Ryb2tlPSdyZ2JhKDI1NSwgMjU1LCAyNTUsIDAuNTUpJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1taXRlcmxpbWl0PScxMCcgc3Ryb2tlLXdpZHRoPScyJyBkPSdNNCA3aDIyTTQgMTVoMjJNNCAyM2gyMicvPjwvc3ZnPg==)
}

.navbar-dark .navbar-text {
    color: rgba(255,255,255,.55)
}

.navbar-dark .navbar-text a,.navbar-dark .navbar-text a:hover,.navbar-dark .navbar-text a:focus {
    color: #fff
}

.navbar-nav.nav-menu {
    -ms-flex-pack: distribute;
    justify-content: space-around;
    width: 100%
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px)
}

.card>.card-header+.list-group,.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem
}

.card-title {
    margin-bottom: .5rem
}

.card-subtitle {
    margin-top: -.25rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1rem
}

.card-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125)
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0
}

.card-footer {
    padding: .5rem 1rem;
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,0,0,.125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)
}

.card-header-tabs {
    margin-right: -.5rem;
    margin-bottom: -.5rem;
    margin-left: -.5rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.5rem;
    margin-left: -.5rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(0.25rem - 1px)
}

.card-img,.card-img-top,.card-img-bottom {
    width: 100%
}

.card-img,.card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px)
}

.card-img,.card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px)
}

.card-group>.card {
    margin-bottom: .75rem
}

@media(min-width: 576px) {
    .card-group {
        display:-ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .card-group>.card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-top,.card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-bottom,.card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-top,.card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-bottom,.card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0
    }
}

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 1rem;
    list-style: none
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider,"/")
}

.breadcrumb-item.active {
    color: #6c757d
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: #0a58ca;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.page-link:focus {
    z-index: 3;
    color: #0a58ca;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: none
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6
}

.page-link {
    padding: .375rem .75rem
}

.page-item:first-child .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .35em .65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    position: relative;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe
}

.alert-primary .alert-link {
    color: #06357a
}

.alert-secondary {
    color: #41464b;
    background-color: #e2e3e5;
    border-color: #d3d6d8
}

.alert-secondary .alert-link {
    color: #34383c
}

.alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc
}

.alert-success .alert-link {
    color: #0c4128
}

.alert-info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb
}

.alert-info .alert-link {
    color: #04414d
}

.alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5
}

.alert-warning .alert-link {
    color: #523e02
}

.alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7
}

.alert-danger .alert-link {
    color: #6a1a21
}

.alert-light {
    color: #636464;
    background-color: #fefefe;
    border-color: #fdfdfe
}

.alert-light .alert-link {
    color: #4f5050
}

.alert-dark {
    color: #141619;
    background-color: #d3d3d4;
    border-color: #bcbebf
}

.alert-dark .alert-link {
    color: #101214
}

.alert-default {
    color: #666;
    background-color: #fff;
    border-color: #fff
}

.alert-default .alert-link {
    color: #525252
}

.alert-black {
    color: #000;
    background-color: #ccc;
    border-color: #b3b3b3
}

.alert-black .alert-link {
    color: #000
}

.alert-gray {
    color: #585858;
    background-color: #f8f8f8;
    border-color: #f5f5f5
}

.alert-gray .alert-link {
    color: #464646
}

.alert-light-gray {
    color: #636363;
    background-color: #fefefe;
    border-color: #fdfdfd
}

.alert-light-gray .alert-link {
    color: #4f4f4f
}

.alert-light-blue {
    color: #505e64;
    background-color: #f4fbfe;
    border-color: #eef9fe
}

.alert-light-blue .alert-link {
    color: #404b50
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem
}

.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0d6efd;
    transition: width .6s ease
}

@media(prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg,rgba(255,255,255,0.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,0.15) 50%,rgba(255,255,255,0.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes
}

@media(prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}

.list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: .25rem
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
}

.list-group-item-action:hover,.list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef
}

.list-group-item {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    -ms-flex-direction: row;
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media(min-width: 576px) {
    .list-group-horizontal-sm {
        -ms-flex-direction:row;
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 768px) {
    .list-group-horizontal-md {
        -ms-flex-direction:row;
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 992px) {
    .list-group-horizontal-lg {
        -ms-flex-direction:row;
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 1200px) {
    .list-group-horizontal-xl {
        -ms-flex-direction:row;
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width: 1400px) {
    .list-group-horizontal-xxl {
        -ms-flex-direction:row;
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #084298;
    background-color: #cfe2ff
}

.list-group-item-primary.list-group-item-action:hover,.list-group-item-primary.list-group-item-action:focus {
    color: #084298;
    background-color: #bacbe6
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #084298;
    border-color: #084298
}

.list-group-item-secondary {
    color: #41464b;
    background-color: #e2e3e5
}

.list-group-item-secondary.list-group-item-action:hover,.list-group-item-secondary.list-group-item-action:focus {
    color: #41464b;
    background-color: #cbccce
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b
}

.list-group-item-success {
    color: #0f5132;
    background-color: #d1e7dd
}

.list-group-item-success.list-group-item-action:hover,.list-group-item-success.list-group-item-action:focus {
    color: #0f5132;
    background-color: #bcd0c7
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132
}

.list-group-item-info {
    color: #055160;
    background-color: #cff4fc
}

.list-group-item-info.list-group-item-action:hover,.list-group-item-info.list-group-item-action:focus {
    color: #055160;
    background-color: #badce3
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160
}

.list-group-item-warning {
    color: #664d03;
    background-color: #fff3cd
}

.list-group-item-warning.list-group-item-action:hover,.list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #e6dbb9
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03
}

.list-group-item-danger {
    color: #842029;
    background-color: #f8d7da
}

.list-group-item-danger.list-group-item-action:hover,.list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029
}

.list-group-item-light {
    color: #636464;
    background-color: #fefefe
}

.list-group-item-light.list-group-item-action:hover,.list-group-item-light.list-group-item-action:focus {
    color: #636464;
    background-color: #e5e5e5
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464
}

.list-group-item-dark {
    color: #141619;
    background-color: #d3d3d4
}

.list-group-item-dark.list-group-item-action:hover,.list-group-item-dark.list-group-item-action:focus {
    color: #141619;
    background-color: #bebebf
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619
}

.list-group-item-default {
    color: #666;
    background-color: #fff
}

.list-group-item-default.list-group-item-action:hover,.list-group-item-default.list-group-item-action:focus {
    color: #666;
    background-color: #e6e6e6
}

.list-group-item-default.list-group-item-action.active {
    color: #fff;
    background-color: #666;
    border-color: #666
}

.list-group-item-black {
    color: #000;
    background-color: #ccc
}

.list-group-item-black.list-group-item-action:hover,.list-group-item-black.list-group-item-action:focus {
    color: #000;
    background-color: #b8b8b8
}

.list-group-item-black.list-group-item-action.active {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.list-group-item-gray {
    color: #585858;
    background-color: #f8f8f8
}

.list-group-item-gray.list-group-item-action:hover,.list-group-item-gray.list-group-item-action:focus {
    color: #585858;
    background-color: #dfdfdf
}

.list-group-item-gray.list-group-item-action.active {
    color: #fff;
    background-color: #585858;
    border-color: #585858
}

.list-group-item-light-gray {
    color: #636363;
    background-color: #fefefe
}

.list-group-item-light-gray.list-group-item-action:hover,.list-group-item-light-gray.list-group-item-action:focus {
    color: #636363;
    background-color: #e5e5e5
}

.list-group-item-light-gray.list-group-item-action.active {
    color: #fff;
    background-color: #636363;
    border-color: #636363
}

.list-group-item-light-blue {
    color: #505e64;
    background-color: #f4fbfe
}

.list-group-item-light-blue.list-group-item-action:hover,.list-group-item-light-blue.list-group-item-action:focus {
    color: #505e64;
    background-color: #dce2e5
}

.list-group-item-light-blue.list-group-item-action.active {
    color: #fff;
    background-color: #505e64;
    border-color: #505e64
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 0;
    opacity: .5
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.btn-close:focus {
    outline: 0;
    box-shadow: none;
    opacity: 1
}

.btn-close:disabled,.btn-close.disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: .25
}

.btn-close-white {
    -webkit-filter: invert(1) grayscale(100%) brightness(200%);
    filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    border-radius: .25rem
}

.toast:not(.showing):not(.show) {
    opacity: 0
}

.toast.hide {
    display: none
}

.toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: .75rem
}

.toast-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .5rem .75rem;
    color: #6c757d;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px)
}

.toast-header .btn-close {
    margin-right: -.375rem;
    margin-left: .75rem
}

.toast-body {
    padding: .75rem;
    word-wrap: break-word
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,-50px);
    transform: translate(0,-50px)
}

@media(prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px)
}

.modal-header .btn-close {
    padding: .5rem;
    margin: -.5rem -.5rem -.5rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px)
}

.modal-footer>* {
    margin: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media(min-width: 576px) {
    .modal-dialog {
        max-width:500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media(min-width: 992px) {
    .modal-lg,.modal-xl {
        max-width:800px
    }
}

@media(min-width: 1200px) {
    .modal-xl {
        max-width:1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

.modal-fullscreen .modal-footer {
    border-radius: 0
}

@media(max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0
    }
}

@media(max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-top,.bs-tooltip-auto[data-popper-placement^=top] {
    padding: .4rem 0
}

.bs-tooltip-top .tooltip-arrow,.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-top .tooltip-arrow::before,.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    top: -1px;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-end,.bs-tooltip-auto[data-popper-placement^=right] {
    padding: 0 .4rem
}

.bs-tooltip-end .tooltip-arrow,.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-end .tooltip-arrow::before,.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    right: -1px;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-bottom,.bs-tooltip-auto[data-popper-placement^=bottom] {
    padding: .4rem 0
}

.bs-tooltip-bottom .tooltip-arrow,.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
    top: 0
}

.bs-tooltip-bottom .tooltip-arrow::before,.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-start,.bs-tooltip-auto[data-popper-placement^=left] {
    padding: 0 .4rem
}

.bs-tooltip-start .tooltip-arrow,.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-start .tooltip-arrow::before,.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    left: -1px;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem
}

.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem
}

.popover .popover-arrow::before,.popover .popover-arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-top>.popover-arrow,.bs-popover-auto[data-popper-placement^=top]>.popover-arrow {
    bottom: calc(-0.5rem - 1px)
}

.bs-popover-top>.popover-arrow::before,.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0,0,0,.25)
}

.bs-popover-top>.popover-arrow::after,.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-end>.popover-arrow,.bs-popover-auto[data-popper-placement^=right]>.popover-arrow {
    left: calc(-0.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-end>.popover-arrow::before,.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0,0,0,.25)
}

.bs-popover-end>.popover-arrow::after,.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-bottom>.popover-arrow,.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow {
    top: calc(-0.5rem - 1px)
}

.bs-popover-bottom>.popover-arrow::before,.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem;
    border-bottom-color: rgba(0,0,0,.25)
}

.bs-popover-bottom>.popover-arrow::after,.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    top: 1px;
    border-width: 0 .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-bottom .popover-header::before,.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f0f0f0
}

.bs-popover-start>.popover-arrow,.bs-popover-auto[data-popper-placement^=left]>.popover-arrow {
    right: calc(-0.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-start>.popover-arrow::before,.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0,0,0,.25)
}

.bs-popover-start>.popover-arrow::after,.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid #d8d8d8;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: 1rem;
    color: #212529
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item.active,.carousel-item-next,.carousel-item-prev {
    display: block
}

.carousel-item-next:not(.carousel-item-start),.active.carousel-item-end {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.carousel-item-prev:not(.carousel-item-end),.active.carousel-item-start {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none
}

.carousel-fade .carousel-item.active,.carousel-fade .carousel-item-next.carousel-item-start,.carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-start,.carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media(prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-start,.carousel-fade .active.carousel-item-end {
        transition: none
    }
}

.carousel-control-prev,.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media(prefers-reduced-motion:reduce) {
    .carousel-control-prev,.carousel-control-next {
        transition: none
    }
}

.carousel-control-prev:hover,.carousel-control-prev:focus,.carousel-control-next:hover,.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-prev-icon,.carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nI2ZmZic+PHBhdGggZD0nTTExLjM1NCAxLjY0NmEuNS41IDAgMCAxIDAgLjcwOEw1LjcwNyA4bDUuNjQ3IDUuNjQ2YS41LjUgMCAwIDEtLjcwOC43MDhsLTYtNmEuNS41IDAgMCAxIDAtLjcwOGw2LTZhLjUuNSAwIDAgMSAuNzA4IDB6Jy8+PC9zdmc+)
}

.carousel-control-next-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nI2ZmZic+PHBhdGggZD0nTTQuNjQ2IDEuNjQ2YS41LjUgMCAwIDEgLjcwOCAwbDYgNmEuNS41IDAgMCAxIDAgLjcwOGwtNiA2YS41LjUgMCAwIDEtLjcwOC0uNzA4TDEwLjI5MyA4IDQuNjQ2IDIuMzU0YS41LjUgMCAwIDEgMC0uNzA4eicvPjwvc3ZnPg==)
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media(prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-prev-icon,.carousel-dark .carousel-control-next-icon {
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-border;
    animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: .75s linear infinite spinner-grow;
    animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

@media(prefers-reduced-motion:reduce) {
    .spinner-border,.spinner-grow {
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s
    }
}

.btn-primary {
    color: #fff;
    background-color: #063d5d;
    border-color: #063d5d
}

.btn-primary:hover {
    color: #063d5d;
    background-color: #fff;
    border-color: #063d5d
}

.btn-check:checked+.btn-primary,.btn-check:active+.btn-primary,.btn-primary:focus,.btn-primary:active,.btn-primary.active,.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #9bb1be;
    border-color: #9bb1be
}

.btn-check:checked+.btn-primary:focus,.btn-check:active+.btn-primary:focus,.btn-primary:focus:focus,.btn-primary:active:focus,.btn-primary.active:focus,.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(43,90,117,.5)
}

.btn-primary:disabled,.btn-primary.disabled {
    color: #fff;
    background-color: #063d5d;
    border-color: #063d5d
}

.btn-secondary {
    color: #fff;
    background-color: red;
    border-color: red
}

.btn-secondary:hover {
    color: red;
    background-color: #fff;
    border-color: red
}

.btn-check:checked+.btn-secondary,.btn-check:active+.btn-secondary,.btn-secondary:focus,.btn-secondary:active,.btn-secondary.active,.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #fd9999;
    border-color: #fd9999
}

.btn-check:checked+.btn-secondary:focus,.btn-check:active+.btn-secondary:focus,.btn-secondary:focus:focus,.btn-secondary:active:focus,.btn-secondary.active:focus,.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(255,38,38,.5)
}

.btn-secondary:disabled,.btn-secondary.disabled {
    color: #000;
    background-color: red;
    border-color: red
}

.btn-default {
    color: #063d5d;
    background-color: #fff;
    border-color: #dcdcdc
}

.btn-default:hover {
    color: #063d5d;
    background-color: #f8f8f8;
    border-color: #9bb1be
}

.btn-check:checked+.btn-default,.btn-check:active+.btn-default,.btn-default:focus,.btn-default:active,.btn-default.active,.show>.btn-default.dropdown-toggle {
    color: #063d5d;
    background-color: #f8f8f8;
    border-color: #dcdcdc
}

.btn-check:checked+.btn-default:focus,.btn-check:active+.btn-default:focus,.btn-default:focus:focus,.btn-default:active:focus,.btn-default.active:focus,.show>.btn-default.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(188,196,201,.5)
}

.btn-default:disabled,.btn-default.disabled {
    color: #000;
    background-color: #fff;
    border-color: #dcdcdc
}

.add-btn {
    background-image: url(../svg/cancel-24px.svg);
    background-repeat: no-repeat;
    color: #063d5d;
    background-position-x: 14px;
    background-position-y: 11px;
    padding: 16px 50px
}

.btn-transparent {
    background-color: transparent;
    border: 1px solid #063d5d
}
/*todo make them miport locally*/
@font-face {
    font-family: gotham-bold;
    src: url(../fonts/gotham/Gotham-Bold-TR.woff2);
    src: url(../fonts/gotham/Gotham-Bold-TR.woff2) format("woff2"),url(../fonts/gotham/Gotham-Bold-TR.woff) format("woff")
}

@font-face {
    font-family: gotham-medium;
    src: url(../fonts/gotham/Gotham-Medium-TR.woff2);
    src: url(../fonts/gotham/Gotham-Medium-TR.woff2) format("woff2"),url(../fonts/gotham/Gotham-Medium-TR.woff2) format("woff")
}

@font-face {
    font-family: gotham-thin;
    src: url(../fonts/gotham/Gotham-Thin-TR.woff);
    src: url(../fonts/gotham/Gotham-Thin-TR.woff) format("woff2"),url(../fonts/gotham/Gotham-Thin-TR.woff) format("woff")
}

@font-face {
    font-family: gotham-light;
    src: url(../fonts/gotham/Gotham-Light-TR.woff2);
    src: url(../fonts/gotham/Gotham-Light-TR.woff2) format("woff2"),url(../fonts/gotham/Gotham-Light-TR.woff) format("woff")
}

@font-face {
    font-family: gotham-book;
    src: url(../fonts/gotham/Gotham-Book.woff);
    src: url(../fonts/gotham/Gotham-Book.woff) format("woff"),url(../fonts/gotham/Gotham-Book.woff2) format("woff2"),url(../fonts/gotham/Gotham-Book.ttf) format("truetype")
}

h1,.h1 {
    font-family: Gotham-Bold;
    color: #000
}

h2,.h2 {
    font-family: Gotham-Bold;
    color: #063d5d
}

h3,.h3 {
    font-family: Gotham-Bold;
    color: #063d5d
}

h4,.h4 {
    font-family: Gotham-Bold;
    color: #000
}

h5,.h5 {
    font-family: Gotham-Bold;
    color: #063d5d
}

h6,.h6 {
    font-family: Gotham-Bold;
    color: #063d5d
}

p {
    font-family: Gotham-Book
}

.primary-text {
    font-family: Gotham-Light;
    color: rgba(0,0,0,.8);
    line-height: 1.75
}

.red-header {
    color: red;
    font-family: Gotham-Bold;
    line-height: 1.54
}

.red-header.sub {
    font-size: 14px
}

.red-header.paragraph {
    font-size: 13px
}

.sub-header {
    line-height: 1.75;
    font-size: 16px;
    color: #000
}

.date {
    font-family: Gotham-Bold;
    font-size: 14px;
    line-height: 1.57;
    color: #dcdcdc;
    margin: 0
}

.effect:hover {
    color: red
}

.title-effect {
    cursor: pointer
}

.title-effect:hover {
    color: #063d5d
}

a,a:hover,a:focus,a:active {
    color: inherit;
    text-decoration: inherit
}

.owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
    z-index: 0
}

.owl-carousel .owl-animated-out {
    z-index: 1
}

.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.owl-height {
    transition: height 500ms ease-in-out
}

.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px,0px,0px);
    transform: translate3d(0px,0px,0px)
}

.owl-carousel .owl-wrapper,.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0)
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%
}

.owl-carousel .owl-nav.disabled,.owl-carousel .owl-dots.disabled {
    display: none
}

.owl-carousel .owl-nav .owl-prev,.owl-carousel .owl-nav .owl-next,.owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel .owl-nav button.owl-prev,.owl-carousel .owl-nav button.owl-next,.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0!important;
    font: inherit
}

.owl-carousel.owl-loaded {
    display: block
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block
}

.owl-carousel.owl-hidden {
    opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab
}

.owl-carousel.owl-rtl {
    direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
    float: right
}

.no-js .owl-carousel {
    display: block
}

.owl-carousel .owl-item {
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease
}

.owl-carousel .owl-item .owl-lazy[src^=""],.owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0
}

.owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-nav [class*=owl-] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #d6d6d6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #869791;
    color: #fff;
    text-decoration: none
}

.owl-theme .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom:1;*display: inline
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease
}

.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791
}

.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-nav [class*=owl-] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #d6d6d6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #869791;
    color: #fff;
    text-decoration: none
}

.owl-theme .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom:1;*display: inline
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease
}

.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791
}

.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-nav [class*=owl-] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #d6d6d6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #869791;
    color: #fff;
    text-decoration: none
}

.owl-theme .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom:1;*display: inline
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease
}

.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791
}

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000
}

/*.owl-carousel .owl-video-play-icon {*/
/*    position: absolute;*/
/*    height: 80px;*/
/*    width: 80px;*/
/*    left: 50%;*/
/*    top: 50%;*/
/*    margin-left: -40px;*/
/*    margin-top: -40px;*/
/*    background: url(owl.video.play.png) no-repeat;*/
/*    cursor: pointer;*/
/*    z-index: 1;*/
/*    -webkit-backface-visibility: hidden;*/
/*    transition: -webkit-transform 100ms ease;*/
/*    transition: transform 100ms ease;*/
/*    transition: transform 100ms ease,-webkit-transform 100ms ease*/
/*}*/

.owl-carousel .owl-video-play-icon:hover {
    -webkit-transform: scale(1.3,1.3);
    transform: scale(1.3,1.3)
}

.owl-carousel .owl-video-playing .owl-video-tn,.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%
}

.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px,0px,0px);
    transform: translate3d(0px,0px,0px)
}

.owl-carousel .owl-wrapper,.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0)
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%
}

.owl-carousel .owl-nav.disabled,.owl-carousel .owl-dots.disabled {
    display: none
}

.owl-carousel .owl-nav .owl-prev,.owl-carousel .owl-nav .owl-next,.owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel .owl-nav button.owl-prev,.owl-carousel .owl-nav button.owl-next,.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0!important;
    font: inherit
}

.owl-carousel.owl-loaded {
    display: block
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block
}

.owl-carousel.owl-hidden {
    opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab
}

.owl-carousel.owl-rtl {
    direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
    float: right
}

.no-js .owl-carousel {
    display: block
}

.owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
    z-index: 0
}

.owl-carousel .owl-animated-out {
    z-index: 1
}

.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.owl-height {
    transition: height 500ms ease-in-out
}

.owl-carousel .owl-item {
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease
}

.owl-carousel .owl-item .owl-lazy[src^=""],.owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0
}

.owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000
}

/*.owl-carousel .owl-video-play-icon {*/
/*    position: absolute;*/
/*    height: 80px;*/
/*    width: 80px;*/
/*    left: 50%;*/
/*    top: 50%;*/
/*    margin-left: -40px;*/
/*    margin-top: -40px;*/
/*    background: url(owl.video.play.png) no-repeat;*/
/*    cursor: pointer;*/
/*    z-index: 1;*/
/*    -webkit-backface-visibility: hidden;*/
/*    transition: -webkit-transform 100ms ease;*/
/*    transition: transform 100ms ease;*/
/*    transition: transform 100ms ease,-webkit-transform 100ms ease*/
/*}*/

.owl-carousel .owl-video-play-icon:hover {
    -webkit-transform: scale(1.3,1.3);
    transform: scale(1.3,1.3)
}

.owl-carousel .owl-video-playing .owl-video-tn,.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%
}

.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-nav [class*=owl-] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #d6d6d6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #869791;
    color: #fff;
    text-decoration: none
}

.owl-theme .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom:1;*display: inline
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease
}

.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791
}

.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-nav [class*=owl-] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #d6d6d6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #869791;
    color: #fff;
    text-decoration: none
}

.owl-theme .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom:1;*display: inline
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease
}

.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791
}

body {
    background: url(../img/body_bg.webp) top center no-repeat;
    background-size: 100%
}

html,body {
    overflow-x: hidden
}

img.header-logo {
    width: 88px!important;
    height: 104px!important;
    border: 2px solid #ffffff; /* Correct syntax for border */

}

.banner-cage {
    background-color: #063d5d;
    width: 100%;
    height: 30px
}

.banner-cage .banner-container {
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    z-index: 6
}

.banner-cage .banner-container-subsites-desktop {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.banner-cage .banner-container-subsites-desktop a {
    color: #fff;
    text-decoration: none;
    font-family: Gotham-Bold;
    margin: 0 10px;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px
}

.banner-cage .banner-container-subsites-desktop a:hover {
    cursor: pointer
}

.banner-cage .banner-container-subsites-desktop a:first-child {
    margin-left: 0
}

.banner-cage .banner-container-subsites-tablet {
    display: none
}

.banner-cage .banner-container-links {
    display: -ms-flexbox;
    display: flex
}

.banner-cage-tablet-dropmenu {
    display: none
}

.banner-cage .red-line {
    width: 100%;
    height: 4px;
    background-color: red
}

.banner-cage .banner-left-dropmenu a {
    padding-left: 10px
}

.banner-cage .banner-left-dropmenu:hover .sub-banner-drop {
    display: block
}

.banner-cage .banner-left-dropmenu .sub-banner-drop {
    display: none;
    position: relative
}

.banner-cage .banner-left-dropmenu .sub-banner-drop .sub-banner-dropdown {
    background-color: #063d5d;
    width: 604px;
    height: auto;
    position: absolute;
    left: -10px;
    z-index: 2
}

.banner-cage .banner-left-dropmenu .sub-banner-drop .sub-banner-dropdown a {
    color: #fff;
    padding: 2px 24px;
    font-family: Gotham-Bold;
    cursor: pointer;
    line-height: 2.46;
    letter-spacing: .26px;
    border-bottom: 1px solid #05314a
}

.banner-cage .banner-left-dropmenu .sub-banner-drop .sub-banner-dropdown a:first-child {
    margin-top: 20px;
    margin-left: 10px;
    border-top: 1px solid #05314a
}

.banner-cage .banner-left-dropmenu .sub-banner-drop .sub-banner-dropdown a:hover {
    color: red;
    background-color: #042b41
}

.banner-cage .banner-right-dropmenu a {
    padding-left: 10px
}

.banner-cage .banner-right-dropmenu:hover .sub-banner-drop {
    display: block!important
}

.banner-cage .banner-right-dropmenu .sub-banner-drop {
    display: none;
    position: relative
}

.banner-cage .banner-right-dropmenu .sub-banner-drop-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #063d5d;
    width: 500px;
    height: auto;
    padding: 30px;
    position: absolute;
    left: -10px;
    z-index: 1
}

.banner-cage .banner-right-dropmenu .sub-banner-drop-content h6,.banner-cage .banner-right-dropmenu .sub-banner-drop-content .h6 {
    color: #fff;
    margin-right: 20px;
    margin-top: 8px;
    font-size: 12px;
    line-height: 2.46;
    letter-spacing: .26px
}

.banner-cage .banner-right-dropmenu .sub-banner-drop-content .drop-first-title {
    margin-right: 35px
}

.banner-cage .banner-right-dropmenu .sub-banner-drop-content select {
    width: 40%;
    font-weight: 500;
    line-height: 1.43
}

.banner-cage .banner-right-dropmenu .sub-banner-drop-content-top {
    height: 46px;
    position: relative
}

.banner-cage .banner-right-dropmenu .sub-banner-drop-content-top button {
    margin-left: 16px
}

.banner-cage .banner-right-dropmenu .sub-banner-drop-content-bottom {
    position: relative;
    height: 46px;
    margin-top: 20px
}

.banner-cage .line {
    width: 1px;
    height: 30px;
    margin: 0 10px;
    background-color: #05314a
}

.banner-cage .line:last-child {
    margin-right: 0
}

.banner-cage select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

.banner-cage .subsite-selectbox-arrow {
    position: absolute;
    right: 150px;
    bottom: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%)
}

.banner-cage .select-control {
    height: 46px;
    border: solid 1px #dcdcdc;
    padding: 0 12px;
    color: #000
}

.banner-cage .select-control:focus,.banner-cage .select-control:active {
    outline: 0
}

.navigation-cage {
    margin-top: 36px
}

.navigation-cage .navigation-container {
    -ms-flex-pack: justify;
    justify-content: space-between
}

.navigation-cage .navigation-container-left-content {
    margin-top: 14px;
    margin-left: 16px
}

.navigation-cage .navigation-container-left-content h2,.navigation-cage .navigation-container-left-content .h2 {
    color: #063d5d;
    font-family: Gotham-Bold;
    font-style: italic
}

.navigation-cage .navigation-container-left-content-buttons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: baseline;
    align-items: baseline;
    margin-top: 15px;
    margin-left: -10px
}

.navigation-cage .navigation-container-left-content-buttons .subsite-search-nav .subsite-search-container .subsite-search-bar {
    position: relative;
    padding: 3px 0
}

.navigation-cage .navigation-container-left-content-buttons .subsite-search-nav .subsite-search-container .subsite-search-bar .subsite-search-button-hidden {
    display: none
}

.navigation-cage .navigation-container-left-content-buttons .subsite-search-nav .subsite-search-container .subsite-search-bar .subsiteTogglesearch {
    display: none;
    position: absolute;
    box-sizing: border-box;
    height: 78px;
    top: 30px;
    right: 0;
    z-index: 5;
    background: #063d5d;
    padding: 16px
}

.navigation-cage .navigation-container-left-content-buttons .subsite-search-nav .subsite-search-container .subsite-search-bar .subsiteTogglesearch input {
    width: 370px;
    margin-right: 8px
}

.navigation-cage .navigation-container-left-content-buttons .subsite-search-nav .subsite-search-container .subsite-search-bar .subsiteTogglesearch input::-webkit-input-placeholder {
    font-weight: 700;
    color: #000
}

.navigation-cage .navigation-container-left-content-buttons .subsite-search-nav .subsite-search-container .subsite-search-bar .subsiteTogglesearch input::-moz-placeholder {
    font-weight: 700;
    color: #000
}

.navigation-cage .navigation-container-left-content-buttons .subsite-search-nav .subsite-search-container .subsite-search-bar .subsiteTogglesearch input:-ms-input-placeholder {
    font-weight: 700;
    color: #000
}

.navigation-cage .navigation-container-left-content-buttons .subsite-search-nav .subsite-search-container .subsite-search-bar .subsiteTogglesearch input::-ms-input-placeholder {
    font-weight: 700;
    color: #000
}

.navigation-cage .navigation-container-left-content-buttons .subsite-search-nav .subsite-search-container .subsite-search-bar .subsiteTogglesearch input::placeholder {
    font-weight: 700;
    color: #000
}

.navigation-cage .navigation-container-left-content-buttons .subsite-search-nav .subsite-search-container .subsite-search-bar .subsiteTogglesearch button {
    width: 140px
}

.navigation-cage .navigation-container-left-content-buttons a {
    text-decoration: none;
    color: #063d5d;
    font-size: 13px;
    font-family: Gotham-Bold;
    padding: 5px 16px
}

.navigation-cage .navigation-container-left-content-buttons a:hover {
    cursor: pointer;
    color: red
}

.navigation-cage .navigation-container-left-content-buttons .line {
    width: 1px;
    height: 15px;
    margin-block:auto;opacity: .22;
    background-color: #063d5d
}

.navigation-cage .navigation-container-left-content-buttons .navigation-dropmenu {
    padding-top: 1px
}

.navigation-cage .navigation-container-left-content-buttons .navigation-dropmenu:hover .sub-navigation-drop {
    display: block
}

.navigation-cage .navigation-container-left-content-buttons .navigation-dropmenu:hover {
    background-color: #fff
}

.navigation-cage .navigation-container-left-content-buttons .navigation-dropmenu .sub-navigation-drop {
    display: none;
    position: relative
}

.navigation-cage .navigation-container-left-content-buttons .navigation-dropmenu .sub-navigation-drop .sub-navigation-dropdown {
    background-color: #fff;
    width: 272px;
    height: auto;
    position: absolute;
    z-index: 2
}

.navigation-cage .navigation-container-left-content-buttons .navigation-dropmenu .sub-navigation-drop .sub-navigation-dropdown a {
    padding: 10px 15px;
    border-bottom: 1px solid #dcdcdc
}

.navigation-cage .navigation-container-right img {
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.16);
    margin-left: 16px
}

.navigation-cage .navigation-container-right img:first-of-type {
    margin-left: 0
}

.navigation-cage .navigation-container-right img.tbmm-logo {
    width: 68px;
    height: 68px;
    box-shadow: none!important;
    margin: auto;
    margin-right: 11px
}

.navigation-cage .navigation-container-right img.young-header-logo {
    max-width: 150px;
    height: 68px;
    box-shadow: none!important;
    margin: auto;
    -o-object-fit: contain;
    object-fit: contain
}

.navigation-cage-mobile {
    display: none
}

@media screen and (min-width: 768px) and (max-width:1024px) {
    .navigation-container-right .young-header-logo {
        display:none!important
    }

    .navigation-container-right .header-logo {
        display: none!important
    }

    .navigation-container-right .tbmm-logo {
        display: none!important
    }
}

@media screen and (max-width: 768px) {
    .banner-container-links {
        display:none
    }

    .banner-cage .banner-container-subsites-desktop {
        display: none
    }

    .banner-cage .banner-container-subsites-tablet {
        display: block;
        position: relative
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-dropdown-title {
        display: -ms-flexbox;
        display: flex;
        color: #fff;
        font-family: Gotham-Bold;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        z-index: 11
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-dropdown-title>*:first-child {
        margin-inline:10px}

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop {
        position: relative
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown {
        background-color: #063d5d;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-top: 30px;
        z-index: 10
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown-row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: baseline;
        align-items: baseline;
        padding: 2px 24px;
        margin: 0 10px
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown-row .subsite-selectbox-arrow {
        right: 12px
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown-row>div {
        margin-block:8px}

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown-title {
        font-size: 13px;
        line-height: 2.46;
        letter-spacing: .26px;
        color: #f8f8f8!important;
        font-family: gotham-bold;
        margin-top: 16px
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown-selectbox-container {
        position: relative;
        margin-top: 16px
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown-selectbox-container-button-container {
        margin-top: 16px;
        margin-inline:5px}

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown a {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        color: #fff;
        padding: 2px 24px;
        font-family: Gotham-Bold;
        cursor: pointer;
        line-height: 2.46;
        letter-spacing: .26px;
        border-bottom: 1px solid #05314a
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown a:hover {
        color: red;
        background-color: #042b41
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown div[class$=list]:first-child {
        margin-top: 20px;
        margin-left: 10px;
        border-top: 1px solid #05314a
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown div[class$=list]>* {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        color: #fff;
        padding: 2px 24px;
        font-family: Gotham-Bold;
        cursor: pointer;
        line-height: 2.46;
        letter-spacing: .26px;
        border-bottom: 1px solid #05314a
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown div[class$=list]>*:hover {
        cursor: pointer
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown-list {
        list-style-type: none;
        margin: 0;
        padding: 0
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown-myk-list {
        height: 100%
    }

    .banner-cage .banner-container-subsites-tablet .sub-banner-drop .sub-banner-dropdown-myk-list span img {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg)
    }

    .banner-cage .banner-container-subsites-tablet-down-arrow {
        background: url(../svg/subsite-tablet-arrow.svg) no-repeat center;
        width: 20px;
        height: 20px
    }

    .banner-cage .banner-container-subsites-tablet-up-arrow {
        display: none;
        background: url(../svg/up-arrow.svg) no-repeat center;
        width: 20px;
        height: 20px
    }

    .banner-cage-tablet-dropmenu-section {
        display: none
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
        position: absolute;
        top: 34px;
        min-height: 350px;
        background-color: #05314a;
        z-index: 2
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu-myk {
        display: none
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu-myk-content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu-item:first-child {
        margin-top: 20px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu-item:last-child {
        border-bottom: 1px solid #042b41
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu-item:nth-child(2) {
        background: url(../svg/right-arrow.svg) no-repeat right
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu-item:hover {
        cursor: pointer
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu a {
        color: #fff;
        text-decoration: none;
        font-family: Gotham-Bold;
        font-size: 13px;
        line-height: 1.46;
        letter-spacing: .26px;
        border-top: 1px solid #042b41;
        padding-top: 15px;
        padding-bottom: 15px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu a:hover {
        color: red
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop {
        margin-top: 30px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content-top {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content-top select {
        width: 40%;
        height: 46px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        padding-left: 10px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content-top button {
        width: 30%;
        margin-left: 10px;
        height: 46px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content-bottom {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 15px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content-bottom select {
        width: 40%;
        height: 46px;
        font-size: 14px;
        margin-left: 14px;
        font-weight: 500;
        line-height: 1.43;
        padding-left: 10px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content h6,.banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content .h6 {
        font-size: 13px;
        font-family: Gotham-Bold;
        line-height: 2.46;
        letter-spacing: .26px;
        color: #fff;
        margin-right: 20px
    }

    .banner-cage .subsite-selectbox-arrow-tablet {
        position: absolute;
        right: 225px;
        bottom: 10px
    }

    .navigation-cage {
        margin-top: 20px
    }

    .navigation-cage .navigation-container {
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .navigation-cage .navigation-container-left-logo img {
        width: 50px;
        height: 59px
    }

    .navigation-cage .navigation-container-left-content {
        margin-top: 14px;
        margin-left: 16px
    }

    .navigation-cage .navigation-container-left-content h2,.navigation-cage .navigation-container-left-content .h2 {
        width: 150px;
        font-size: 16px
    }

    .navigation-cage .navigation-container-left-content-buttons {
        display: none
    }

    .navigation-cage .navigation-container-left-content-buttons .line {
        width: 1px;
        height: 15px;
        margin-top: 10px;
        opacity: .22;
        background-color: #063d5d
    }

    .navigation-cage .navigation-container-left-content-buttons .navigation-dropmenu {
        margin-top: 5px
    }

    .navigation-cage .navigation-container-left-content-buttons .navigation-dropmenu:hover .sub-navigation-drop {
        display: block
    }

    .navigation-cage .navigation-container-left-content-buttons .navigation-dropmenu .sub-navigation-drop {
        display: none;
        position: relative
    }

    .navigation-cage .navigation-container-left-content-buttons .navigation-dropmenu .sub-navigation-drop .sub-navigation-dropdown {
        background-color: #fff;
        width: 272px;
        height: auto;
        position: absolute;
        top: 3px;
        z-index: 1
    }

    .navigation-cage .navigation-container-left-content-buttons .navigation-dropmenu .sub-navigation-drop .sub-navigation-dropdown a {
        padding: 10px 15px;
        border-bottom: 1px solid #dcdcdc
    }

    .navigation-cage .navigation-container-right {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

    .navigation-cage .navigation-container-right .young-header-logo {
        max-width: 150px!important
    }

    .navigation-cage .navigation-container-right img {
        width: 50px!important;
        height: 59px!important;
    }

    .navigation-cage .navigation-container-right-navbar {
        background: url(../svg/mobile-menu-icon.svg) no-repeat center;
        width: 30px;
        height: 30px
    }

    .navigation-cage .navigation-container-right-close {
        display: none;
        background: url(../svg/subclose-24px.svg) no-repeat center;
        width: 30px;
        height: 30px
    }

    .navigation-cage-mobile {
        display: none;
        margin-top: 20px;
        width: 100%;
        height: 700px;
        background-color: #fff
    }

    .navigation-cage-mobile-main input {
        margin-top: 10px;
        width: 100%;
        border: 1px solid #dcdcdc;
        height: 52px;
        padding-left: 40px
    }

    .navigation-cage-mobile-main input:focus {
        outline: 0
    }

    .navigation-cage-mobile-main-icon {
        position: relative
    }

    .navigation-cage-mobile-main-icon img {
        position: absolute;
        top: 27px;
        left: 15px
    }

    /*.navigation-cage-mobile-main a:nth-child(2) {*/
    /*    background: url(../svg/subsite-right-arrow.svg) no-repeat right*/
    /*}*/

    /*.navigation-cage-mobile-main a:nth-child(5) {*/
    /*    background: url(../svg/subsite-right-arrow.svg) no-repeat right*/
    /*}*/

    .navigation-cage-mobile-managementcage {
        display: none
    }

    .navigation-cage-mobile-mediacage {
        display: none
    }

    .navigation-cage-mobile-groupcage {
        display: none
    }

    .navigation-cage-mobile a {
        text-decoration: none;
        color: #063d5d;
        font-family: Gotham-Bold;
        font-size: 13px;
        line-height: 1.46;
        letter-spacing: .26px;
        border-bottom: 1px solid #dcdcdc;
        padding-top: 15px;
        padding-bottom: 15px
    }

    .navigation-cage-mobile a:hover {
        color: red
    }
}

@media screen and (max-width: 415px) {
    .banner-cage .banner-container-links {
        display:none
    }

    .banner-cage-tablet-dropmenu-section {
        display: none
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
        position: absolute;
        top: 34px;
        min-height: 500px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu a {
        color: #fff;
        text-decoration: none;
        font-family: Gotham-Bold;
        font-size: 13px;
        line-height: 1.46;
        letter-spacing: .26px;
        border-top: 1px solid #042b41;
        padding-top: 15px;
        padding-bottom: 15px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu a:first-child {
        margin-top: 20px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu a:last-child {
        border-bottom: 1px solid #042b41
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu a:hover {
        cursor: pointer
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop {
        margin-top: 10px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content-top {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content-top select {
        width: 100%
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content-top span {
        margin-bottom: 56px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content-top button {
        width: 100%;
        margin-top: 10px;
        margin-left: 0
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content-bottom {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
        margin-top: 15px
    }

    .banner-cage-tablet-dropmenu-section .banner-cage-tablet-dropmenu .banner-cage-tablet-drop-content-bottom select {
        width: 100%;
        margin-left: 0
    }

    .banner-cage .subsite-selectbox-arrow-tablet {
        right: 10px
    }

    .navigation-container-right .young-header-logo {
        display: none!important
    }

    .navigation-container-right .header-logo {
        display: none!important
    }

    .navigation-container-right .tbmm-logo {
        display: none!important
    }

    .navigation-cage.navigation-container-right img {
        display: none
    }

    .navigation-cage-mobile {
        height: 600px
    }
}

.footer-cage {
    background-color: #063d5d;
    height: 154px
}

.footer-cage-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.footer-cage-content .footer-text p {
    line-height: 1.46;
    color: #fff
}

@media screen and (max-width: 415px) {
    .footer-cage {
        height:154px
    }

    .footer-cage-content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .footer-cage-content .footer-text p {
        font-size: 13px
    }
}

.sub-slider-hero-section {
    margin-top: 36px;
    margin-bottom: 64px
}

.sub-slider-hero-section .sub-slider {
    position: relative
}

.sub-slider-hero-section .sub-slider-arrow {
    position: absolute;
    top: 25%;
    background: #063d5d;
    width: 48px;
    height: 48px;
    padding: 12px 13px 12px 11px;
    border-radius: 24px;
    z-index: 4;
    background-position: center;
    background-repeat: no-repeat
}

.sub-slider-hero-section .sub-slider-arrow:hover {
    cursor: pointer
}

.sub-slider-hero-section .sub-slider-arrow-left {
    left: 24px;
    background-image: url(../svg/arrow-left.svg)
}

.sub-slider-hero-section .sub-slider-arrow-right {
    right: 24px;
    background-image: url(../svg/arrow-right.svg)
}

.sub-slider-hero-section .sub-slider-card {
    text-decoration: none
}

.sub-slider-hero-section .sub-slider-card-image {
    width: 100%;
    height: auto
}

.sub-slider-hero-section .sub-slider-card-content {
    margin: 20px 0
}

.sub-slider-hero-section .sub-slider-card-content-title {
    font-family: Gotham-Bold;
    line-height: 1.33;
    text-align: left;
    color: #000
}

.sub-slider-mobile-hidden {
    display: none
}

@media screen and (min-width: 769px) and (max-width:991px) {
    .sub-slider-hero-section .sub-slider-arrow {
        top:35%
    }
}

@media screen and (max-width: 768px) {
    .sub-slider-mobile-hidden {
        display:block
    }

    .sub-slider-mobile-hidden .sub-slider-mobile {
        margin-top: 36px;
        position: relative
    }

    .sub-slider-mobile-hidden .sub-slider-mobile-arrow {
        position: absolute;
        top: 25%;
        background: #063d5d;
        width: 40px;
        height: 40px;
        padding: 12px 13px 12px 11px;
        border-radius: 24px;
        z-index: 4;
        background-position: center;
        background-repeat: no-repeat
    }

    .sub-slider-mobile-hidden .sub-slider-mobile-arrow:hover {
        cursor: pointer
    }

    .sub-slider-mobile-hidden .sub-slider-mobile-arrow-left {
        left: 5px;
        background-image: url(../svg/arrow-left.svg)
    }

    .sub-slider-mobile-hidden .sub-slider-mobile-arrow-right {
        right: 5px;
        background-image: url(../svg/arrow-right.svg)
    }

    .sub-slider-mobile-hidden .sub-slider-mobile-card-image {
        width: 100%;
        height: auto
    }

    .sub-slider-mobile-hidden .sub-slider-mobile-card-content {
        padding: 40px;
        padding-top: 0
    }

    .sub-slider-mobile-hidden .sub-slider-mobile-card-content-date {
        margin: 22px 87px 9px 23px;
        font-family: Gotham-Bold;
        line-height: 1.38;
        text-align: left;
        color: #dcdcdc
    }

    .sub-slider-mobile-hidden .sub-slider-mobile-card-content-title {
        width: 90%;
        font-size: 30px;
        margin: 9px 106px 0 23px;
        font-family: Gotham-Bold;
        line-height: 1.33;
        text-align: left;
        color: #000
    }

    .sub-slider-mobile-hidden .sub-slider-mobile a {
        text-decoration: none
    }

    .sub-slider-hero-section {
        display: none
    }

    .sub-slider-hero-section .sub-slider {
        width: 360px;
        height: 183px
    }
}

@media screen and (max-width: 415px) {
    .sub-slider-mobile-hidden {
        display:block
    }

    .sub-slider-mobile-hidden .sub-slider-mobile-card-content {
        padding: 10px;
        padding-top: 0
    }

    .sub-slider-mobile-hidden .sub-slider-mobile-card-content-title {
        font-size: 20px
    }
}

@media screen and (max-width: 300px) {
    .sub-slider-mobile-hidden .sub-slider-mobile-arrow {
        top:15%
    }
}

.sub-invitation-section {
    margin-bottom: 64px
}

.sub-invitation-section-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center
}

.sub-invitation-section-content .sub-invitation {
    background-image: url(../img/subSitegokyuzu.webp);
    background-repeat: no-repeat;
    background-size: cover
}

.sub-invitation-section-content .sub-invitation-content-image img {
    width: 100%;
    height: 250px;
    padding: 12px 20px 10px
}

.sub-invitation-section-content .sub-invitation-content-text {
    margin-left: 20px
}

.sub-invitation-section-content .sub-invitation-content-text h2,.sub-invitation-section-content .sub-invitation-content-text .h2 {
    color: #063d5d;
    margin-top: 25px;
    font-size: 48px;
    font-family: Gotham-Bold;
    line-height: 1.25
}

.sub-invitation-section-content .sub-invitation-content-text h6,.sub-invitation-section-content .sub-invitation-content-text .h6 {
    color: red;
    margin-top: 40px;
    font-family: Gotham-Bold;
    line-height: 2.14;
    text-decoration: underline
}

.sub-invitation-section-content .sub-invitation-content-text h6:hover,.sub-invitation-section-content .sub-invitation-content-text .h6:hover {
    cursor: pointer
}

.sub-invitation-section-content .activity-report {
    background-color: #063d5d;
    text-align: center;
    width: 290px;
    height: 250px
}

.sub-invitation-section-content .activity-report-content {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 16px;
    padding: 35px 16px 16px;
    border: 1px solid #fff
}

.sub-invitation-section-content .activity-report-content h4,.sub-invitation-section-content .activity-report-content .h4 {
    margin-top: -10px;
    font-family: Gotham-Bold;
    color: #fff
}

.sub-invitation-section-content .activity-report-content h6,.sub-invitation-section-content .activity-report-content .h6 {
    margin: 35px 10px 0;
    color: #fff;
    text-decoration: underline
}

.sub-invitation-section-content .activity-report-content h6:hover,.sub-invitation-section-content .activity-report-content .h6:hover {
    cursor: pointer
}

@media screen and (width: 1024px) {
    .sub-invitation-section {
        margin-top:100px;
        margin-bottom: 100px
    }

    .sub-invitation-section-content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center
    }

    .sub-invitation-section-content .sub-invitation {
        width: 100%
    }

    .sub-invitation-section-content .sub-invitation-content-image img {
        width: 160px;
        height: 200px;
        padding: 12px 20px 10px
    }

    .sub-invitation-section-content .sub-invitation-content-text {
        margin-left: 10px
    }

    .sub-invitation-section-content .sub-invitation-content-text h2,.sub-invitation-section-content .sub-invitation-content-text .h2 {
        font-size: 40px
    }

    .sub-invitation-section-content .sub-invitation-content-text h6,.sub-invitation-section-content .sub-invitation-content-text .h6 {
        margin-top: 30px;
        font-size: 14px
    }

    .sub-invitation-section-content .activity-report {
        display: none
    }
}

@media screen and (max-width: 768px) {
    .sub-invitation-section {
        margin-top:100px;
        margin-bottom: 100px
    }

    .sub-invitation-section-content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center
    }

    .sub-invitation-section-content .sub-invitation {
        width: 100%
    }

    .sub-invitation-section-content .sub-invitation-content-image img {
        width: 160px;
        height: 200px;
        padding: 12px 20px 10px
    }

    .sub-invitation-section-content .sub-invitation-content-text {
        margin-left: 10px
    }

    .sub-invitation-section-content .sub-invitation-content-text h2,.sub-invitation-section-content .sub-invitation-content-text .h2 {
        font-size: 40px
    }

    .sub-invitation-section-content .sub-invitation-content-text h6,.sub-invitation-section-content .sub-invitation-content-text .h6 {
        margin-top: 30px;
        font-size: 14px
    }

    .sub-invitation-section-content .activity-report {
        display: none
    }
}

@media screen and (max-width: 415px) {
    .sub-invitation-section {
        margin-top:100px;
        margin-bottom: 100px
    }

    .sub-invitation-section-content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center
    }

    .sub-invitation-section-content .sub-invitation {
        width: 100%
    }

    .sub-invitation-section-content .sub-invitation-content-image img {
        display: none
    }

    .sub-invitation-section-content .sub-invitation-content-text {
        margin-left: 0;
        text-align: center
    }

    .sub-invitation-section-content .sub-invitation-content-text h2,.sub-invitation-section-content .sub-invitation-content-text .h2 {
        font-size: 25px;
        line-height: 1.6
    }

    .sub-invitation-section-content .sub-invitation-content-text h6,.sub-invitation-section-content .sub-invitation-content-text .h6 {
        margin-top: 20px;
        font-size: 14px
    }

    .sub-invitation-section-content .activity-report {
        display: block;
        margin-top: 70px
    }

    .sub-invitation-section-content .activity-report-content {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 30px
    }
}

.sub-twitter-cage {
    margin-top: 17px
}

.sub-twitter-cage .sub-twitter {
    width: 290px
}

.sub-twitter-cage .sub-twitter-title h5,.sub-twitter-cage .sub-twitter-title .h5 {
    color: #063d5d;
    font-family: Gotham-Bold;
    line-height: .92;
    border-left: 2px solid red;
    padding: 10px 10px 10px 24px;
    margin-bottom: 20px
}

.sub-twitter-cage .sub-twitter-card {
    height: 800px
}

@media screen and (width: 1024px) {
    .sub-twitter-cage .sub-twitter {
        width:235px
    }
}

@media screen and (max-width: 768px) {
    .sub-twitter-cage .sub-twitter {
        width:100%
    }

    .sub-twitter-card iframe {
        width: 90%!important
    }
}

@media screen and (max-width: 415px) {
    .sub-twitter-card iframe {
        width:unset
    }
}

.sub-pre-card {
    margin-bottom: 0;
    border-bottom: solid 1px #dcdcdc;
    border-right: solid 1px #dcdcdc;
    border-left: solid 1px #dcdcdc;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.1)
}

.sub-pre-card h3,.sub-pre-card .h3 {
    font-family: Gotham-Bold;
    line-height: 1.5;
    text-align: center;
    color: #063d5d;
    padding: 16px 40px 12px
}

.sub-pre-card h6,.sub-pre-card .h6 {
    color: red;
    text-align: center;
    font-family: Gotham-Bold;
    margin: -7px 0 11px 0
}

.sub-pre-card-image {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.sub-pre-card-icons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid #dcdcdc
}

.sub-pre-card-icons a {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 18px;
    border-right: 1px solid #dcdcdc
}

.sub-pre-card-icons .link-button.icon {
    width: 100%
}

.sub-pre-card-icons .icon:first-child {
    border-left: none
}

.sub-pre-card-icons .icon:last-child {
    border-right: none!important
}

.sub-pre-card-icons .navigation .link-button .icon:hover {
    background-color: transparent
}

.sub-pre-card-tablet {
    display: none;
    border: solid 1px #e6ecef
}

.sub-pre-card-tablet-img img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover
}

.sub-pre-card-tablet-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%
}

.sub-pre-card-tablet-content h3,.sub-pre-card-tablet-content .h3 {
    font-family: Gotham-Bold;
    font-size: 30px;
    line-height: 1.5;
    text-align: center;
    color: #063d5d;
    padding: 16px 52px 12px
}

.sub-pre-card-tablet-content h6,.sub-pre-card-tablet-content .h6 {
    color: red;
    text-align: center;
    font-size: 14px;
    font-family: Gotham-Bold;
    margin: -7px 0 11px 0
}

.sub-pre-card-tablet-content .sub-pre-card-tablet-icons {
    width: 100%
}

.sub-pre-card-tablet-content .sub-pre-card-tablet-icons-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: none
}

.sub-pre-card-tablet-content .sub-pre-card-tablet-icons-content a {
    padding: 15px;
    width: 100%;
    text-align: center;
    border-right: 1px solid #dcdcdc
}

.sub-pre-card-tablet-content .sub-pre-card-tablet-icons-content a:last-child {
    border-right: none
}

.sub-pre-card-tablet-content .sub-pre-card-tablet-icons-content .link-button.icon {
    max-width: 100%
}

.sub-pre-card-mobile {
    display: none;
    border: solid 1px #e6ecef
}

.sub-pre-card-mobile-cage-top-img img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover
}

.sub-pre-card-mobile-cage-top-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center
}

.sub-pre-card-mobile-cage-top-content h3,.sub-pre-card-mobile-cage-top-content .h3 {
    font-family: Gotham-Bold;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #063d5d;
    padding-right: 25px;
    padding-left: 25px
}

.sub-pre-card-mobile-cage-top-content h6,.sub-pre-card-mobile-cage-top-content .h6 {
    color: red;
    text-align: center;
    font-size: 14px;
    font-family: Gotham-Bold
}

.sub-pre-card-mobile-cage-bottom-icons {
    border-top: 1px solid #dcdcdc
}

.sub-pre-card-mobile-cage-bottom-icons-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center
}

.sub-pre-card-mobile-cage-bottom-icons-content a {
    padding: 20px;
    width: 100%;
    text-align: center
}

.sub-pre-card-mobile-cage-bottom-icons-content .link-button.icon {
    max-width: 100%;
    border-left: 1px solid #dcdcdc
}

.sub-pre-card-mobile-cage-bottom-icons-content .link-button.icon:first-child {
    border-left: none
}

@media screen and (max-width: 1024px) {
    .sub-pre-card h3,.sub-pre-card .h3 {
        padding:16px 20px;
        font-size: 24px
    }
}

@media screen and (max-width: 768px) {
    .sub-pre {
        margin-top:50px
    }

    .sub-pre-card {
        display: none
    }

    .sub-pre-card-tablet {
        display: block
    }

    .sub-pre-card-mobile {
        display: none
    }
}

@media screen and (max-width: 415px) {
    .sub-pre-card {
        display:none
    }

    .sub-pre-card-tablet {
        display: none
    }

    .sub-pre-card-mobile {
        display: block
    }
}

.sub-management-section {
    margin-top: 75px
}

.sub-management-section .sub-management-main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center
}

.sub-management-section .sub-management-main-title h2,.sub-management-section .sub-management-main-title .h2 {
    line-height: 1.67;
    font-family: Gotham-Bold;
    color: #063d5d
}

.sub-management-section .sub-management-main-input-filter-cage {
    position: relative
}

.sub-management-section .sub-management-main-input-filter-cage input {
    height: 46px
}

.sub-management-section .sub-management-main-input-filter-cage input::-webkit-input-placeholder {
    font-family: Gotham-Bold
}

.sub-management-section .sub-management-main-input-filter-cage input::-moz-placeholder {
    font-family: Gotham-Bold
}

.sub-management-section .sub-management-main-input-filter-cage input:-ms-input-placeholder {
    font-family: Gotham-Bold
}

.sub-management-section .sub-management-main-input-filter-cage input::-ms-input-placeholder {
    font-family: Gotham-Bold
}

.sub-management-section .sub-management-main-input-filter-cage input::placeholder {
    font-family: Gotham-Bold
}

.sub-management-section .sub-management-main-input-filter-cage .filter-icon {
    position: absolute;
    top: 13px;
    right: 10px;
    width: 28px;
    height: 23px
}

.sub-management-section .sub-management-main-input-filter-cage .filter-icon:hover {
    cursor: pointer
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share {
    display: -ms-flexbox;
    display: flex;
    margin-left: 30px
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share:hover .sub-management-main-input-share-icon-hidden {
    display: block;
    -webkit-animation: slide-in .5s ease;
    animation: slide-in .5s ease
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-icon {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-icon:hover {
    cursor: pointer
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-icon-show img {
    padding: 10px;
    border: 1px solid #dcdcdc
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-icon-show:hover+.management-share-icon-show {
    display: none
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-icon-hidden {
    display: none;
    position: relative
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-icon-hidden-content {
    position: absolute;
    background-color: #fff;
    top: -23px;
    left: -181px
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-icon-hidden-content .active-share img {
    background-color: #063d5d;
    border-left: 1px solid #dcdcdc
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-icon-hidden-content img {
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-left: 0
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-icon-hidden-content img:hover {
    border: solid 1px #9bb1be
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-text {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 95px;
    color: #063d5d;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-left: 0
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-text a {
    color: #063d5d;
    font-family: Gotham-Bold;
    line-height: 1.86
}

.sub-management-section .sub-management-main-input-filter .sub-management-main-input-share-text a:hover {
    cursor: pointer
}

.sub-management-section .sub-management {
    margin-bottom: 129px
}

.sub-management-section .sub-management-card {
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.1);
    border: solid 1px #e3f4fd;
    margin-top: 16px;
    padding: 10px;
    text-decoration: none
}

.sub-management-section .sub-management-card img {
    box-shadow: 0 0 4px 0 rgba(0,0,0,.11)
}

.sub-management-section .sub-management-card-content {
    margin-left: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly
}

.sub-management-section .sub-management-card-content-top h4,.sub-management-section .sub-management-card-content-top .h4 {
    font-size: 24px;
    line-height: 1.5
}

.sub-management-section .sub-management-card-content-top h6,.sub-management-section .sub-management-card-content-top .h6 {
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    margin-top: -10px
}

.sub-management-section .sub-management-card-content-top h4,.sub-management-section .sub-management-card-content-top .h4,.sub-management-section .sub-management-card-content-top h6,.sub-management-section .sub-management-card-content-top .h6 {
    font-family: Gotham-Bold;
    color: #063d5d
}

.sub-management-section .sub-management-card-content p {
    line-height: 1.33;
    color: #063d5d;
    font-size: 12px
}

@-webkit-keyframes slide-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes slide-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@media screen and (max-width: 768px) {
    .sub-management-section {
        margin-top:75px
    }

    .sub-management-section .sub-management-main {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .sub-management-section .sub-management-main-title h2,.sub-management-section .sub-management-main-title .h2 {
        line-height: 1.67;
        font-family: Gotham-Bold;
        color: #063d5d
    }

    .sub-management-section .sub-management-card-content {
        margin-left: 20px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly
    }

    .sub-management-section .sub-management-card-content p {
        line-height: 1.33;
        color: #063d5d;
        font-size: 12px
    }
}

@media screen and (width: 540px) {
    .sub-management-section .sub-management-main-input-filter-cage input {
        width:345px
    }
}

@media screen and (max-width: 415px) {
    .sub-management-section {
        margin-top:75px
    }

    .sub-management-section .sub-management-main {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .sub-management-section .sub-management-main-title h2,.sub-management-section .sub-management-main-title .h2 {
        line-height: 1.67;
        font-family: Gotham-Bold;
        color: #063d5d
    }

    .sub-management-section .sub-management-main-input-filter {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .sub-management-section .sub-management-main-input-filter .sub-management-main-input-share {
        display: none
    }

    .sub-management-section .sub-management-card-content {
        margin-left: 20px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly
    }

    .sub-management-section .sub-management-card-content p {
        line-height: 1.33;
        color: #063d5d;
        font-size: 12px
    }
}

.sub-management-main-filter-input {
    position: relative
}

.sub-management-main-filter-input input {
    height: 46px
}

.sub-management-main-filter-input input::-webkit-input-placeholder {
    font-family: Gotham-Bold
}

.sub-management-main-filter-input input::-moz-placeholder {
    font-family: Gotham-Bold
}

.sub-management-main-filter-input input:-ms-input-placeholder {
    font-family: Gotham-Bold
}

.sub-management-main-filter-input input::-ms-input-placeholder {
    font-family: Gotham-Bold
}

.sub-management-main-filter-input input::placeholder {
    font-family: Gotham-Bold
}

.sub-management-main-filter-input .filter-icon {
    position: absolute;
    top: 13px;
    right: 10px;
    width: 28px;
    height: 23px
}

.sub-management-main-filter-input .filter-icon:hover {
    cursor: pointer
}

.sub-management-main-input-province-filter {
    position: relative
}

.sub-management-main-input-province-filter .subsite-selectbox-arrow {
    position: absolute;
    right: 10px;
    bottom: 10px
}

.sub-management-main-input-province-filter .select-control {
    height: 46px;
    border: solid 1px #dcdcdc;
    padding: 0 12px;
    margin-left: 20px;
    width: 150px;
    color: #dcdcdc;
    background-color: #fff;
    line-height: 1.43;
    font-family: Gotham-Bold;
    font-size: 14px
}

.sub-management-main-input-province-filter .select-control:focus,.sub-management-main-input-province-filter .select-control:active {
    outline: 0
}

.sub-management-main-input-province-filter select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

.sub-management-main-input-filter-cage {
    width: 55%
}

.sub-management-main-input-filter {
    -ms-flex-pack: end;
    justify-content: flex-end
}

@media screen and (max-width: 768px) {
    .sub-management-main-filter-input {
        margin-top:0
    }
}

@media screen and (max-width: 768px) {
    .sub-management-main-input-filter-cage {
        width:100%
    }
}

@media screen and (max-width: 415px) {
    .sub-management-main-filter {
        -ms-flex-direction:column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .sub-management-main-filter-input {
        margin-top: 10px;
        position: relative
    }

    .sub-management-main-filter-input input {
        height: 46px
    }

    .sub-management-main-filter-input input::-webkit-input-placeholder {
        padding-left: 0
    }

    .sub-management-main-filter-input input::-moz-placeholder {
        padding-left: 0
    }

    .sub-management-main-filter-input input:-ms-input-placeholder {
        padding-left: 0
    }

    .sub-management-main-filter-input input::-ms-input-placeholder {
        padding-left: 0
    }

    .sub-management-main-filter-input input::placeholder {
        padding-left: 0
    }

    .sub-management-main-filter-input .filter-icon {
        position: absolute;
        top: 13px;
        right: 10px;
        width: 28px;
        height: 23px
    }
}

.sub-management-news-section {
    margin-top: 75px
}

.sub-management-news-section .sub-management-news-main {
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center
}

.sub-management-news-section .sub-management-news-main-title h2,.sub-management-news-section .sub-management-news-main-title .h2 {
    font-family: Gotham-Bold;
    line-height: 1.67;
    color: #063d5d
}

.sub-management-news-section .sub-management-news {
    margin-top: 25px
}

.sub-management-news-section .sub-management-news-card-content {
    text-decoration: none
}

.sub-management-news-section .sub-management-news-card-content img {
    width: 100%
}

.sub-management-news-section .sub-management-news-card-content-date {
    padding: 10px 0 0 20px
}

.sub-management-news-section .sub-management-news-card-content h2,.sub-management-news-section .sub-management-news-card-content .h2 {
    color: #000;
    line-height: 1.33;
    font-family: Gotham-Bold;
    margin-top: 10px;
    font-size: 36px
}

.sub-management-news-section .sub-management-news-card-content h2:hover,.sub-management-news-section .sub-management-news-card-content .h2:hover {
    color: #063d5d
}

.sub-management-news-section .sub-management-news-card-content-show {
    position: relative
}

.sub-management-news-section .sub-management-news-card-content-show h6,.sub-management-news-section .sub-management-news-card-content-show .h6 {
    line-height: 1.33;
    font-family: Gotham-Bold;
    margin-top: 40px;
    color: red;
    font-size: 12px
}

.sub-management-news-section .sub-management-news-card-content-show h6:hover+.subsite-news-arrow,.sub-management-news-section .sub-management-news-card-content-show .h6:hover+.subsite-news-arrow {
    display: block
}

.sub-management-news-section .sub-management-news-card-content-show h6:hover,.sub-management-news-section .sub-management-news-card-content-show .h6:hover {
    cursor: pointer
}

.sub-management-news-section .sub-management-news-card-content-show .subsite-news-arrow {
    background: url(../svg/subsite-news-arrow.svg) top center no-repeat;
    display: none;
    position: absolute;
    top: 0;
    left: 100px;
    width: 16px;
    height: 16px
}

.sub-management-news-card-content:hover .effect {
    color: red
}

.sub-management-news-card-content:hover h2,.sub-management-news-card-content:hover .h2 {
    color: #063d5d
}

@media screen and (max-width: 768px) {
    .sub-management-news-section .sub-management-news-card-content {
        margin-top:20px
    }

    .sub-management-news-section .sub-management-news-card-content-show {
        display: none
    }
}

@media screen and (max-width: 415px) {
    .sub-management-news-section .sub-management-news-card-content h2,.sub-management-news-section .sub-management-news-card-content .h2 {
        font-size:16px
    }
}

.management-share {
    margin-left: 32px;
    margin-top: 1px
}

.management-share:hover .management-share-icon-hidden {
    display: block;
    -webkit-animation: slide-in .5s ease;
    animation: slide-in .5s ease
}

.management-share-icon {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff
}

.management-share-icon:hover {
    cursor: pointer
}

.management-share-icon-show img {
    padding: 10px;
    border: 1px solid #dcdcdc
}

.management-share-icon-show:hover+.management-share-icon-show {
    display: none
}

.management-share-icon-hidden {
    display: none;
    position: relative
}

.management-share-icon-hidden-content {
    position: absolute;
    background-color: #fff;
    top: -23px;
    left: -181px
}

.management-share-icon-hidden-content .active-share img {
    background-color: #063d5d;
    border-left: 1px solid #dcdcdc
}

.management-share-icon-hidden-content a img {
    padding: 10px;
    border-left: 0;
    outline: 1px solid transparent;
    border: 1px solid #dcdcdc
}

.management-share-icon-hidden-content a img:hover {
    outline: 0 solid transparent;
    border: 1px solid #9bb1be
}

.management-share-icon-hidden-content a a:last-child img {
    background-color: #fff
}

.management-share-text {
    font-family: Gotham-Bold;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 95px;
    color: #063d5d;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-left: 0
}

.management-share-text a {
    color: #063d5d;
    font-family: Gotham-Bold;
    line-height: 1.86
}

.management-share-text:hover {
    cursor: pointer
}

@keyframes slide-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@media screen and (max-width: 768px) {
    .management-share {
        margin-left:5px
    }

    .management-share-icon {
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media screen and (max-width: 415px) {
    .management-share {
        margin-left:0
    }

    .management-share-icon {
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.sub-newsdetail-section {
    margin-top: 80px;
    margin-bottom: 20px
}

.sub-newsdetail-section h2,.sub-newsdetail-section .h2 {
    font-family: Gotham-Bold;
    line-height: 1.25
}

.sub-newsdetail-section p {
    font-family: Gotham-Bold;
    line-height: 1.38;
    color: red
}

.sub-news-section .sub-news {
    margin-top: 21px;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.sub-news-section .sub-news-detail img {
    width: 100%
}

.sub-news-section .sub-news-detail-content {
    padding-top: 35px
}

.sub-news-section .sub-news-detail-content p {
    font-weight: 500;
    line-height: 1.75;
    color: #4a4a4a;
    margin-top: 5px
}

.sub-news-section .sub-news-detail-content img {
    margin-top: 70px
}

.sub-news-section .sub-news-detail-content .newsdetail-video {
    position: relative
}

.sub-news-section .sub-news-detail-content .newsdetail-video-red {
    background-color: red;
    width: 80px;
    height: 10px
}

.sub-news-section .sub-news-detail-content .newsdetail-video-blue {
    background-color: #063d5d;
    width: 712px;
    height: 10px
}

.sub-news-section .sub-news-detail-content .newsdetail-video-play {
    background: url(../svg/subsite-newsdetail-play.svg) no-repeat center;
    position: absolute;
    width: 59px;
    height: 59px;
    top: 230px;
    left: 300px
}

.sub-news-section .sub-news-detail-content .newsdetail-video-play:hover {
    cursor: pointer
}

.sub-news-section .sub-news-detail-content .newsdetail-video h5,.sub-news-section .sub-news-detail-content .newsdetail-video .h5 {
    border: 1px solid #dcdcdc;
    border-top: none;
    color: #063d5d;
    font-family: Gotham-Bold;
    line-height: 1.75;
    padding: 25px
}

.sub-news-section .sub-news-detail-content .newsdetail-quote h6,.sub-news-section .sub-news-detail-content .newsdetail-quote .h6 {
    color: #063d5d;
    font-weight: 500;
    font-style: italic;
    line-height: 1.6;
    margin: 50px 0 200px 70px;
    padding-left: 15px;
    border-left: 5px solid #063d5d
}

.sub-news-section .sub-news-similarnews h4,.sub-news-section .sub-news-similarnews .h4 {
    font-family: Gotham-Bold;
    line-height: 1.5;
    color: #063d5d
}

.sub-news-section .sub-news-similarnews-card {
    margin: 30px;
    text-decoration: none
}

.sub-news-section .sub-news-similarnews-card p {
    line-height: 1.57;
    color: #999
}

.sub-news-section .sub-news-similarnews-card h6,.sub-news-section .sub-news-similarnews-card .h6 {
    color: #000;
    font-family: Gotham-Bold;
    line-height: 1.38;
    margin-top: 10px
}

@media screen and (max-width: 768px) {
    .sub-newsdetail-section h2,.sub-newsdetail-section .h2 {
        font-size:24px;
        line-height: 1.5
    }

    .sub-newsdetail-section-inside-share {
        display: none
    }

    .sub-news-section {
        margin-top: -30px
    }

    .sub-news-section .sub-news {
        margin-top: 40px;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .sub-news-section .sub-news-detail img {
        width: 100%
    }

    .sub-news-section .sub-news-detail-content .newsdetail-video-play {
        top: 200px;
        left: 250px
    }

    .sub-news-section .sub-news-detail-content .newsdetail-video h5,.sub-news-section .sub-news-detail-content .newsdetail-video .h5 {
        padding: 10px;
        font-size: 15px
    }

    .sub-news-section .sub-news-detail-content .newsdetail-quote h6,.sub-news-section .sub-news-detail-content .newsdetail-quote .h6 {
        margin-left: 60px
    }

    .sub-news-section .sub-news-similarnews {
        display: none
    }
}

@media screen and (max-width: 415px) {
    .sub-news-section .sub-news {
        margin-top:40px;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .sub-news-section .sub-news-detail img {
        width: 100%
    }

    .sub-news-section .sub-news-detail-content .newsdetail-video-play {
        top: 130px;
        left: 130px
    }

    .sub-news-section .sub-news-detail-content .newsdetail-video h5,.sub-news-section .sub-news-detail-content .newsdetail-video .h5 {
        padding: 10px;
        font-size: 15px
    }

    .sub-news-section .sub-news-detail-content .newsdetail-quote h6,.sub-news-section .sub-news-detail-content .newsdetail-quote .h6 {
        margin-left: 0
    }

    .sub-news-section .sub-news-similarnews {
        display: none
    }
}

.sub-management-news-section .sub-management-news-section-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center
}

.sub-member .resume-title {
    margin-top: 55px;
    margin-bottom: 30px
}

.sub-member .info-title {
    margin-bottom: 55px
}

.sub-member-detail {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 40px
}

.sub-member-detail-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center
}

.sub-member-detail-content-info {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 50px
}

.sub-member-detail-content-info h3,.sub-member-detail-content-info .h3 {
    font-size: 24px;
    line-height: 1.5;
    color: #063d5d
}

.sub-member-detail-content-info h6,.sub-member-detail-content-info .h6 {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    color: red;
    margin-bottom: 19px
}

.sub-member-detail-content-info p {
    font-family: Gotham-Medium;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.46;
    letter-spacing: .26px;
    color: #000
}

.sub-member-detail-content-links {
    margin-top: 40px
}

.sub-member-detail-resume {
    padding-inline:33px}

.sub-member-detail-resume .text-muted-resume {
    font-family: Gotham-Bold;
    font-size: 13px;
    color: #b7b7b7
}

.sub-member-detail-resume .text-muted-resume+p {
    font-family: Gotham-Medium;
    font-size: 14px;
    color: #063d5d
}

.sub-member-detail-resume h3,.sub-member-detail-resume .h3 {
    font-family: Gotham-Bold;
    font-size: 36px;
    line-height: 1.67;
    color: #063d5d
}

.sub-member-detail-resume p {
    font-family: Gotham-Light;
    font-size: 16px;
    line-height: 1.75;
    color: #4a4a4a
}

@media screen and (max-width: 768px) {
    .sub-member-detail {
        -ms-flex-direction:column;
        flex-direction: column
    }

    .info-title {
        margin-top: 55px
    }
}

@media screen and (max-width: 415px) {
    .sub-member-detail {
        -ms-flex-pack:center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.subsite-search {
    margin-top: 80px
}

.subsite-search-title p {
    color: #000;
    opacity: .8;
    font-family: Gotham-Light;
    font-size: 13px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: .26px
}

.subsite-search-left-content {
    margin-top: 9px
}

.subsite-search-left-content-dropdown {
    display: none
}

.subsite-search-left-content:first-child {
    border-top: 1px solid #c7eafb
}

.subsite-search-left-box {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    border: 1px solid #c7eafb;
    border-top: none;
    padding: 30px 10px 20px 20px
}

.subsite-search-left-box p {
    color: #98b0bd;
    font-size: 14px;
    font-stretch: normal;
    line-height: 1.57
}

.subsite-search-left-box a {
    text-decoration: none
}

.subsite-search-left-box a h4,.subsite-search-left-box a .h4 {
    font-family: Gotham-Bold;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #063d5d
}

.subsite-search-left-box:hover .effect {
    color: red
}

.subsite-search-left-box:hover .title-effect {
    color: #063d5d
}

.subsite-search-right {
    padding-left: 0
}

.subsite-search-right-category {
    font-family: Gotham-Bold;
    font-size: 16px;
    line-height: 1.38;
    color: #063d5d;
    border-top: 1px solid #c7eafb;
    padding: 24px;
    margin-top: 9px
}

.subsite-search-right ul {
    list-style: none;
    padding-left: 28px
}

.subsite-search-right ul li {
    margin-top: 20px
}

.subsite-search-right ul li a {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    text-decoration: none;
    color: #98b0bd
}

.subsite-search-right ul li a:hover {
    color: red
}

.subsite-search-right ul li a.active {
    color: red
}

@media screen and (max-width: 768px) {
    .subsite-search-left-content:first-child {
        border-top:none
    }

    .subsite-search-left-content-dropdown {
        display: block;
        width: 100%;
        border: 1px solid #c7eafb;
        position: relative;
        padding: 20px;
        margin: 0;
        list-style: none
    }

    .subsite-search-left-content-dropdown-list:hover>ul {
        display: block;
        height: 530px;
        overflow-y: auto
    }

    .subsite-search-left-content-dropdown-list p {
        font-family: Gotham-Bold;
        font-size: 16px;
        line-height: 1.38;
        color: #063d5d
    }

    .subsite-search-left-content-dropdown-list-content {
        width: 100%;
        padding: 0;
        list-style: none;
        position: absolute;
        background-color: #fff;
        top: 64px;
        left: 0;
        display: none
    }

    .subsite-search-left-content-dropdown-list-content li {
        border-bottom: 1px solid #c7eafb;
        padding: 20px;
        font-family: Gotham-Bold;
        font-size: 13px;
        line-height: 1.46;
        letter-spacing: .26px
    }

    .subsite-search-left-content-dropdown-list-content li:hover {
        background-color: #f8f8f8
    }

    .subsite-search-left-content-dropdown-list-content li a {
        text-decoration: none;
        color: #000
    }

    .subsite-search-left-box a h4,.subsite-search-left-box a .h4 {
        font-size: 16px
    }

    .subsite-search-right {
        display: none
    }
}

.slider.image {
    width: 100%;
    height: auto
}

.slider {
    min-height: 300px
}

.slider .slider-item {
    display: none
}

.slider .slider-item.active {
    display: block
}

.slider-card {
    padding-bottom: 165px;
    position: relative
}

.slider-card-box {
    padding: 0;
    margin: 0 10px;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: -96px;
    border-top: 8px solid rgba(255,255,255,.253)
}

.slider-card-news {
    border: solid 1px #dcdcdc;
    background-color: #fff;
    border-left: solid 2px red;
    min-height: 255px;
    max-height: 300px;
    padding: 20px 0;
    width: 100%
}

.slider-card-icons-first,.slider-card-icons-second {
    height: 88px;
    width: 88px;
    background-color: #063d5d;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer
}

.slider-card-icons-first {
    margin-bottom: 1px
}

.slider-card-date {
    padding: 24px 0 0 50px
}

.slider-card-mobile {
    display: none
}

.slider-card-news a {
    text-decoration: none
}

.title {
    font-weight: bolder;
    font-size: 36px;
    letter-spacing: .26px;
    padding: 0 30px 0 50px
}

.news-with-card {
    display: -ms-flexbox;
    display: flex
}

.swiper-base {
    display: none
}

img.swiper.image {
    width: 100%;
    height: auto
}

.next-slide,.prev-slide {
    border-right: 8px solid rgba(255,255,255,.253)
}

@media screen and (max-width: 1024px) {
    .slider-card .next-slide,.slider-card .prev-slide {
        border-right:none
    }
}

@media screen and (max-width: 768px) {
    .slider {
        display:none
    }

    .swiper-container {
        display: block
    }

    .slider-card {
        display: none
    }

    .slider-card-box {
        display: none
    }

    .slider-card-icons {
        display: none
    }

    .slider-card-title {
        font-size: 24px;
        padding: 20px 30px 30px
    }

    .slider-card-mobile {
        display: block;
        position: relative
    }

    .slider-card-news-details {
        width: 100%
    }

    .slider-card-news-mobile {
        border: solid 1px #dcdcdc;
        background-color: #fff;
        border-left: solid 2px red
    }

    .slider-card-news-mobile a {
        color: #000;
        text-decoration: none
    }

    .slider-card-date {
        padding: 0 0 10px 30px
    }

    .news-with-card {
        display: block
    }
}

@media screen and (max-width: 415px) {
    .slider-card-news-mobile {
        border-right:none;
        border-bottom: none
    }

    .slider-card-title {
        font-size: 15px
    }

    .slider-card .title {
        padding: 0 30px 0 24px
    }
}

.journal-titles {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-transform: uppercase;
    border-left: 2px solid red;
    padding: 0 0 0 24px;
    margin-bottom: 30px
}

.journal-titles h2,.journal-titles .h2 {
    margin-block:auto}

.journal-titles h1,.journal-titles .h1,.journal-titles a {
    color: #063d5d;
    font-family: Gotham-Bold;
    line-height: 1.38;
    letter-spacing: 3.2px
}

.journal-titles a {
    text-decoration: none
}

.journal-titles-show:hover .arrow {
    display: block;
    transition: 2s
}

.arrow {
    display: none;
    margin-left: 15px
}

.journal-section {
    margin-top: 17px
}

.journal-image {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto
}

.journal-blocks {
    padding-right: 16px;
    padding-left: 0;
    border-left: 1px solid #dcdcdc;
    color: #000;
    text-decoration: none
}

.journal-blocks:last-child {
    padding-bottom: 58px
}

.journal-blocks:hover .date {
    color: red
}

.journal-blocks-date {
    padding: 16px 0 40px 20px
}

.journal-blocks:hover {
    text-decoration: none
}

.journal-blocks:hover .subtitle {
    color: #063d5d
}

.journal-blocks picture>img {
    -o-object-fit: contain;
    object-fit: contain;
    width: 100%
}

.journal-mobile {
    display: none
}

.subtitle {
    font-size: 16px;
    font-family: Gotham-Bold;
    line-height: 1.38;
    -ms-flex-align: center;
    align-items: center
}

@media screen and (max-width: 768px) {
    .journal {
        padding-top:80px;
        border-right: 1px solid #dcdcdc;
        border-left: 1px solid #dcdcdc
    }

    .journal-section {
        margin-top: 20px
    }

    .journal-titles {
        padding: 0 0 0 24px
    }

    .journal-section {
        padding-top: 30px
    }
}

@media screen and (max-width: 540px) {
    .journal-blocks {
        display:-ms-flexbox;
        display: flex;
        padding-right: 0;
        border-left: none!important;
        margin-bottom: 40px
    }

    .journal-blocks-date {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: column-reverse;
        flex-flow: column-reverse;
        padding: 0 10px 0 11px;
        width: 60%
    }

    .journal-blocks>picture {
        width: 40%
    }

    .journal-blocks>picture img {
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        width: 100%
    }

    .journal-blocks>img {
        -o-object-fit: contain;
        object-fit: contain;
        width: 40%
    }
}

@media screen and (max-width: 415px) {
    .journal {
        border-right:none
    }

    .journal-section {
        border-left: 1px solid #dcdcdc;
        padding-top: 0
    }

    .journal-image {
        width: 105px;
        height: 56px
    }

    .journal-titles {
        margin-bottom: 30px
    }

    .journal-titles-show {
        display: none!important
    }

    .journal-news {
        border-right: none;
        margin-bottom: 100px
    }

    .subtitle {
        font-size: 14px;
        margin: 0
    }
}

.pre-card {
    padding-top: 85px;
    margin-bottom: 58px;
    border-bottom: solid 1px #dcdcdc;
    border-right: solid 1px #dcdcdc;
    border-left: solid 1px #dcdcdc
}

.pre-card .personcard-image {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover
}

.pre-card h3,.pre-card .h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    color: #063d5d;
    padding: 16px 10px 13px;
    margin: 0
}

.pre-card-icons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 1px solid #dcdcdc
}

.pre-card-icons .pre-card-button.icon {
    width: 100%
}

.pre-card-icons .icon:first-child {
    border-left: none
}

.pre-card-icons .icon:last-child {
    border-right: none!important
}

.pre-card-icons .baskan-icon:hover .blue-icon {
    display: none
}

.pre-card-icons .baskan-icon:hover .red-icon {
    display: block
}

.pre-card-icons .red-icon {
    display: none
}

.pre-card-icons .pre-card-button.icon:hover {
    background-color: transparent
}

.pre-card-mobile {
    display: none
}

.pre-card-button {
    height: 58px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    border-left: 1px solid #dcdcdc;
    -ms-flex-pack: center;
    justify-content: center
}

@media screen and (max-width: 1024px) {
    .pre-card h3,.pre-card .h3 {
        padding:16px 20px;
        font-size: 24px
    }
}

@media screen and (max-width: 768px) {
    .pre-card {
        display:none
    }

    .pre-card-mobile {
        display: block;
        padding-bottom: 100px;
        border-left: 1px solid #dcdcdc;
        border-right: 1px solid #dcdcdc
    }

    .pre-card-mobile-box-right {
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc
    }

    .pre-card-mobile-icons {
        border-top: 1px solid #dcdcdc;
        -ms-flex-pack: center;
        justify-content: center
    }

    .pre-card-mobile-icons .pre-card-button.icon {
        width: 100%
    }

    .pre-card-mobile-icons .icon:last-child {
        border-right: none!important
    }

    .pre-card-mobile-image {
        width: 100%;
        height: auto;
        -o-object-fit: cover;
        object-fit: cover
    }

    .pre-card-mobile-title {
        padding: 37px 35px 0 19px
    }
}

@media screen and (max-width: 540px) {
    .pre-card-mobile-title {
        padding:37px 35px 20px 19px;
        text-align: center
    }
}

@media screen and (max-width: 415px) {
    .pre-card-mobile {
        margin-top:100px;
        border: none
    }

    .pre-card-mobile-title {
        padding: 16px 50px 12px;
        text-align: center
    }

    .pre-card-mobile-image {
        height: 365px
    }

    .pre-card-mobile-icons .icon:first-child {
        border-left: none!important
    }

    .pre-card-mobile-box-right {
        border-bottom: none;
        border: 1px solid #dcdcdc
    }

    .pre-card-icon {
        display: -ms-flexbox!important;
        display: flex!important
    }
}

.chp-tv {
    background-image: url(../img/chp-tv/chptv-overlay.png),url(../img/chp-tv/chptv-back.png);
    background-size: cover;
    margin-bottom: 100px
}

.chp-tv a {
    text-decoration: none
}

.chp-tv-content {
    padding: 60px 10px 40px 5px
}

.chp-tv-content .view {
    line-height: 1.38;
    letter-spacing: 3.2px
}

.chp-tv-full {
    padding-bottom: 100px;
    -ms-flex-align: start;
    align-items: flex-start
}

.chp-tv-title {
    color: #fff;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.25;
    margin-top: 25px
}

.chp-tv-title-show {
    -ms-flex-align: baseline;
    align-items: baseline
}

.chp-tv-title-show a {
    color: #fff
}

.chp-tv-subtitle {
    font-family: Gotham-Bold;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: 3.2px;
    text-decoration: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.chp-tv-subtitle:hover .arrow {
    display: block;
    transition-duration: 2s
}

.chp-tv-video {
    position: relative
}

.chp-tv-video-play {
    position: absolute;
    left: 47%;
    top: 40%
}

.chp-tv-box {
    position: relative;
    margin-bottom: 40px
}

.chp-tv-box-play {
    position: absolute;
    top: 80px;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.chp-tv-box-play-big {
    position: absolute;
    top: 160px;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.chp-tv-hour {
    background-color: red;
    padding: 0 12px 0 13px;
    position: absolute;
    left: 25px;
    top: 10px
}

.chp-tv-hour-big {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: red;
    padding: 0 12px 0 13px
}

.chp-tv-hour-text {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 2.08
}

.chp-tv-right-side-image {
    width: 100%
}

.chp-tv-right-side-time {
    padding: 16px 10px 10px 0
}

.chp-tv-right-side-time a {
    text-decoration: none
}

.chp-tv-right-side-time h4,.chp-tv-right-side-time .h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;
    color: #fff;
    margin-top: 14px
}

.chp-tv-right-side-time h4:hover,.chp-tv-right-side-time .h4:hover {
    text-decoration: underline
}

.main {
    border-left: 2px solid red;
    margin: 0;
    padding: 0
}

@media screen and (max-width: 768px) {
    .chp-tv-right-side {
        margin-bottom:60px
    }
}

@media screen and (max-width: 415px) {
    .chp-tv-mobile {
        display:none
    }

    .chp-tv-title-show {
        display: none!important
    }

    .chp-tv-content {
        padding-left: 30px
    }

    .chp-tv-right-side-time {
        margin-bottom: 37px;
        padding-top: 12px;
        padding-left: 10px
    }

    .chp-tv-full {
        padding-bottom: 0
    }
}

.invitation {
    background-image: url(../img/CHP.png);
    background-repeat: no-repeat;
    background-position: 82px
}

.invitation-left {
    padding-top: 180px
}

.invitation-left-subtext {
    margin-top: 100px
}

.invitation-left-link {
    text-decoration: none
}

.invitation-left-text {
    color: red;
    font-size: 14px;
    font-family: Gotham-Bold;
    line-height: 2.14
}

.invitation-left-title {
    font-size: 60px;
    font-family: Gotham-Bold;
    line-height: 1.37;
    color: #063d5d;
    border-left: 2px solid red;
    padding-left: 24px;
    margin: 0
}

.invitation-right-side {
    background-color: rgba(245,0,0,.8);
    height: 560px
}

.invitation-right-side-content {
    padding: 60px 60px 15px
}

.invitation-right-side-title {
    font-weight: 700;
    line-height: 1.33;
    color: #fff;
    text-decoration: underline;
    margin: 0
}

.invitation-right-side-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.88;
    color: #fff;
    padding: 0 40px 80px 60px
}

.invitation-right-side-btn {
    background-color: #063d5d;
    padding: 8px 50px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.86;
    font-family: Gotham-Bold
}

.invitation-btn {
    text-align: center;
    margin: 20px 0 60px -30px
}

@media screen and (min-width: 992px) and (max-width:1200px) {
    .invitation-left-title {
        font-size:55px
    }
}

@media screen and (max-width: 768px) {
    .invitation {
        background-size:140%;
        background-position: left top 120px
    }
}

@media screen and (max-width: 415px) {
    .invitation {
        background-image:none
    }

    .invitation-left-title {
        font-size: 24px
    }

    .invitation-left {
        padding-top: 0;
        padding-right: 100px
    }

    .invitation-left-subtext {
        padding-left: 24px;
        margin-top: 26px;
        -ms-flex-align: center;
        align-items: center
    }

    .invitation-left-text {
        width: 168px
    }

    .invitation-right-side-content {
        padding: 36px 23px 0
    }

    .invitation-right-side-text {
        padding: 28px 23px
    }

    .invitation-right-side-btn {
        padding: 17px 12px;
        width: 100%
    }

    .invitation-btn {
        margin: 0
    }
}

.chp-articles {
    margin-top: 200px;
    margin-bottom: 200px
}

.chp-articles-title {
    color: #063d5d;
    font-size: 48px;
    line-height: 1.67;
    margin-bottom: 30px;
    border-left: 2px solid red;
    padding-left: 20px;
    margin-top: 100px
}

.chp-articles-box {
    text-decoration: none;
    padding: 0 34px 0 30px
}

.chp-articles-box-text {
    font-size: 16px;
    font-family: Gotham-Bold;
    line-height: 1.38;
    margin-top: 5px;
    color: #000
}

.chp-articles-box-category {
    margin-top: 16px
}

.chp-articles-box-link-title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.chp-articles-box-link-title p {
    font-family: Gotham-Bold;
    font-size: 12px;
    line-height: 1.33;
    margin: 0;
    color: red
}

.chp-articles-box-img {
    margin-block:10px}

.chp-articles-box-img img {
    width: 100%
}

.chp-articles-box:hover {
    text-decoration: none
}

.chp-articles-content {
    height: 89px
}

.chp-articles-box:hover .chp-articles-box-link {
    margin-left: 30px;
    transition-duration: .2s
}

@media screen and (max-width: 768px) {
    .chp-articles-title {
        margin-top:50px
    }
}

@media screen and (max-width: 415px) {
    .chp-articles {
        margin-top:132px;
        margin-bottom: 100px
    }

    .chp-articles-title {
        font-size: 24px;
        margin-bottom: 0
    }

    .chp-articles-box-human {
        margin-top: 40px
    }

    .chp-articles-box-human-img,.chp-articles-box-economi-img,.chp-articles-box-education-img,.chp-articles-box-turkey-img {
        width: 90%
    }

    .chp-articles-box-link {
        margin-top: 60px
    }

    .chp-articles-box-category {
        margin-top: 43px
    }

    .chp-articles-content {
        height: auto
    }
}

@media screen and (max-width: 376px) {
    .chp-articles-box-human-img,.chp-articles-box-economi-img,.chp-articles-box-education-img,.chp-articles-box-turkey-img {
        width:100%
    }
}

.chp-suggestion-category {
    font-weight: 700;
    line-height: 2.08;
    color: red
}

.chp-suggestion-category-text {
    font-size: 16px;
    line-height: 1.88;
    color: #414042;
    opacity: .5
}

.chp-suggestion-link {
    text-decoration: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.chp-suggestion-link p {
    font-size: 12px;
    font-family: Gotham-Bold;
    line-height: 1.33;
    color: #063d5d;
    margin: 0
}

.chp-suggestion-link:hover {
    color: #063d5d
}

.chp-suggestion-content {
    margin-top: 33px
}

.chp-articles-box:hover .chp-suggestion-content {
    margin-left: 30px;
    transition-duration: .2s
}

@media screen and (max-width: 415px) {
    .chp-suggestion-content img {
        display:none
    }
}

.pre-card-full {
    margin-bottom: 60px
}

.pre-card-full-content {
    text-align: center;
    margin-top: 20px
}

.pre-card-full-content-title {
    font-size: 48px;
    line-height: 1;
    color: #063d5d;
    margin-bottom: 10px
}

.pre-card-full-content-grade {
    font-size: 16px;
    color: red;
    margin-bottom: 24px
}

.pre-card-full-content-text {
    font-family: Gotham-Light;
    line-height: 1.75;
    color: #063d5d;
    font-weight: 600
}

.pre-card-full-content-text-second {
    margin-top: 30px;
    color: #063d5d;
    line-height: 1.75;
    font-family: Gotham-Light
}

.pre-card-full-image {
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 190px;
    height: 190px;
    border: solid 10px rgba(185,185,185,.41)
}

@media screen and (max-width: 415px) {
    .pre-card-full-content-title {
        font-size:24px
    }

    .pre-card-full-content-grade {
        line-height: 1.5
    }
}

.twitter-card {
    margin-bottom: 56px;
    padding: 25px 37px;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.1)
}

.twitter-card a {
    text-decoration: none
}

.twitter-card-head {
    margin-bottom: 30px
}

.twitter-card-round {
    border-radius: 50%;
    background-color: #657786;
    width: 32px;
    height: 32px
}

.twitter-card-content {
    background-color: #dcdcdc;
    width: 100%;
    height: 276px;
    border-radius: 10px;
    margin-top: 35px
}

.twitter-card-brand,.twitter-card-user {
    margin: 0;
    font-size: 13px;
    color: #161616
}

.twitter-card-tweet {
    font-family: Arial;
    font-size: 24px;
    line-height: 1.17;
    color: #161616
}

.twitter-card-actions {
    width: 400px;
    margin-top: 20px;
    -ms-flex-align: end;
    align-items: flex-end
}

.twitter-card-actions p {
    font-size: 13px;
    line-height: 1.31;
    color: #657786;
    padding-bottom: 3px
}

.twitter-card-date {
    color: #607482;
    font-size: 13px;
    line-height: 1.02;
    margin-top: 19px
}

@media screen and (max-width: 415px) {
    .twitter-card-actions {
        width:100%
    }
}

.chp-calendar {
    border-bottom: 1px solid #dcdcdc;
    overflow: hidden;
    position: relative
}

.chp-calendar-title {
    font-weight: 700;
    line-height: 1.33;
    color: #063d5d
}

.chp-calendar-box {
    height: 109px;
    background-color: #063d5d;
    border-right: 1px solid #dcdcdc;
    padding-top: 9px
}

.chp-calendar-box-vertical {
    position: absolute;
    top: 0;
    height: 109px;
    opacity: .3;
    background-image: linear-gradient(to right,rgba(0,0,0,0) 1%,#000000 99%);
    width: 10px
}

.chp-calendar-box-vertical-month {
    font-size: 12px;
    font-family: Gotham-Medium;
    color: #dcdcdc
}

.chp-calendar-box-vertical-date {
    font-size: 36px;
    font-family: Gotham-Bold;
    line-height: 1.33;
    color: #fff
}

.chp-calendar-box-vertical-day {
    font-size: 12px;
    font-family: Gotham-Medium;
    color: #fff
}

.chp-calendar-box-horizontal {
    height: 8px;
    opacity: .3;
    background-color: #05314a;
    position: relative;
    top: 1px
}

.chp-calendar-box-first,.chp-calendar-box-last {
    width: 100%
}

.chp-calendar .no-border {
    border: none
}

.chp-calendar .arrows {
    width: 59px
}

.chp-calendar .arrows-box {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 54px;
    cursor: pointer
}

.chp-calendar .owl-nav {
    display: none
}

.chp-calendar-nav {
    background-color: #063d5d;
    position: absolute;
    z-index: 10;
    top: 0;
    right: -30px;
    height: 109px
}

.chp-calendar-slider-icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 59px;
    height: 54px;
    background-color: transparent;
    border: none
}

.chp-calendar-position {
    position: relative
}

.color-box {
    background-color: #dcdcdd70
}

.color-day,.color-date {
    color: red
}

.color-horizontal {
    background-color: #dcdcdc;
    border-bottom: 1px solid red
}

.chp-program-box {
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #dcdcdc
}

.chp-program-hour h4,.chp-program-hour .h4 {
    font-size: 16px;
    font-weight: 900;
    line-height: 1.38;
    letter-spacing: 3.2px;
    color: #063d5d;
    padding: 40px 0
}

.chp-program-content {
    padding: 35px 100px 35px 45px
}

.chp-program-content p {
    font-size: 16px;
    line-height: 1.88;
    color: #063d5d;
    font-family: Gotham-Light
}

@media screen and (max-width: 768px) {
    .chp-calendar-box.mobile-medium {
        display:none
    }

    .chp-program-content {
        padding-right: 0
    }
}

@media screen and (max-width: 415px) {
    .chp-calendar-box.mobile {
        display:none
    }

    .chp-calendar-box-vertical {
        display: none
    }

    .chp-program-line {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .chp-program-content {
        padding: 10px 32px 36px
    }

    .chp-program-hour h4,.chp-program-hour .h4 {
        padding: 36px 2px 0 32px
    }

    .chp-calendar-nav {
        right: 0
    }
}

.current-publications {
    margin-bottom: 100px;
    margin-top: 100px;
    overflow: hidden
}

.current-publications-titles {
    margin-bottom: 40px
}

.current-publications-title {
    font-size: 48px;
    font-weight: 700;
    color: #063d5d;
    border-left: 2px solid red;
    padding-left: 20px
}

.current-publications-all-journal {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;
    letter-spacing: 3.2px
}

.current-publications-all-journal a {
    text-decoration: none;
    color: #063d5d
}

.current-publications-all-journal a:hover {
    color: #063d5d
}

.current-publications-left {
    z-index: 3;
    padding: 77px 0 74px
}

.current-publications-left img {
    z-index: 3
}

.current-publications-left-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.33;
    color: #fff
}

.current-publications-left-content {
    margin-left: 16px;
    z-index: 3
}

.current-publications-left-text {
    font-size: 16px;
    line-height: 1.75;
    color: #fff;
    margin-top: 10px;
    width: 270px
}

.current-publications-left-btn {
    width: 192px;
    height: 46px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.86;
    z-index: 3;
    font-family: Gotham-Bold
}

.current-publications-left-all-journal {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 2.14;
    margin-left: 16px;
    z-index: 3
}

.current-publications-left-all-journal a {
    color: #fff;
    text-decoration: none
}

.current-publications-left-second-line {
    padding-bottom: 90px
}

.current-publications-left-second-line button a {
    color: #fff;
    text-decoration: none
}

.current-publications-left-second-line button:hover a {
    color: #063d5d
}

.current-publications-left-second-line-under button a {
    color: #fff;
    text-decoration: none
}

.current-publications-left-second-line-under button:hover a {
    color: #063d5d
}

.current-publications-right {
    z-index: 3;
    padding: 77px 0 74px 100px
}

.current-publications-right img {
    z-index: 3
}

.current-publications-right-title {
    width: 296px
}

.current-publications-right-btn {
    background-color: red;
    width: 192px;
    height: 46px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.86;
    z-index: 3;
    font-family: Gotham-Bold
}

.current-publications-right-all-journal {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 2.14;
    margin-left: 16px;
    z-index: 3
}

.current-publications-right-all-journal a {
    color: #fff;
    text-decoration: none
}

.current-publications-right-second-line {
    padding: 0 0 90px 110px
}

.current-publications-right-second-line button a {
    color: #fff;
    text-decoration: none
}

.current-publications-right-second-line button:hover a {
    color: red
}

.current-publications img {
    width: 192px;
    height: 270px
}

.current-publications-mobile {
    display: none
}

.current-publications .scheduler-col {
    position: relative
}

.current-publications .scheduler-col:before {
    position: absolute;
    content: " ";
    top: 0;
    width: 6000px;
    height: 558px
}

.current-publications .scheduler-col.is-left::before {
    right: -10px;
    background: linear-gradient(0deg,rgba(255,0,0,0.7),rgba(255,0,0,0.7)),url("../img/yayin.png")
}

.current-publications .scheduler-col.is-right::before {
    left: -8px;
    background-color: #063d5d;
    background-image: url(../img/kitap.png)
}

.current-publications-pic {
    z-index: 2
}

@media screen and (max-width: 1024px) {
    .current-publications-right {
        padding-left:10px
    }

    .current-publications-right-title {
        width: 100%
    }

    .current-publications-right-second-line {
        padding-left: 10px
    }

    .current-publications-left-text {
        display: none
    }
}

@media screen and (max-width: 768px) {
    .current-publications .scheduler-col {
        display:none
    }

    .current-publications-all-journal {
        display: none
    }

    .current-publications-left-text {
        display: none
    }

    .current-publications-mobile {
        display: block
    }

    .current-publications-left {
        background: linear-gradient(0deg,rgba(255,0,0,0.7),rgba(255,0,0,0.7)),url("../img/yayin.png");
        padding-bottom: 0
    }

    .current-publications-right {
        background-color: #063d5d;
        background-image: url(../img/kitap.png);
        padding: 77px 0 74px
    }

    .current-publications-box {
        display: none
    }

    .current-publications-right {
        padding-right: 0
    }

    .current-publications-right-title {
        width: 115%
    }

    .current-publications-left-second-line {
        margin-top: 110px
    }

    .current-publications-left-second-line-under {
        margin-top: 65px
    }
}

@media screen and (max-width: 720px) {
    .current-publications-left-second-line-under {
        margin-top:30px
    }
}

@media screen and (max-width: 540px) {
    .current-publications-left,.current-publications-right {
        padding:10px
    }

    .current-publications-left img,.current-publications-right img {
        display: none
    }

    .current-publications-left-second-line,.current-publications-left-second-line-under {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start!important;
        align-items: flex-start!important;
        margin-bottom: 30px
    }

    .current-publications-left-second-line {
        margin-top: 50px
    }

    .current-publications-left-all-journal {
        margin-top: 24px;
        margin-left: 0
    }

    .current-publications-left-title {
        margin-top: 36px
    }

    .current-publications-right-title {
        width: 80%
    }

    .current-publications-right-all-journal {
        margin-top: 24px;
        margin-left: 0
    }

    .current-publications-title {
        font-size: 24px
    }
}

@media screen and (max-width: 415px) {
    .current-publications-left-second-line {
        margin-bottom:160px
    }
}

.chp-be-member-left {
    background: linear-gradient(0deg,rgba(60,175,214,0.63),rgba(60,175,214,0.644)),url("../img/chp-articles/Baskan-halk.png");
    background-size: cover;
    height: 367px
}

.chp-be-member-left-content {
    padding: 60px 50px 59px 100px
}

.chp-be-member-left-content-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.39
}

.chp-be-member-left-content-text {
    font-size: 16px;
    line-height: 1.75;
    color: #fff;
    font-family: Gotham-Light
}

.chp-be-member-left-content-btn {
    width: 50%;
    border: solid 1px #fff;
    color: #fff;
    background-color: transparent;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: 2.4px;
    height: 46px;
    margin-top: 52px;
    font-family: Gotham-Bold
}

.chp-be-member-right {
    background: linear-gradient(0deg,rgba(253,186,88,0.63),rgba(253,186,88,0.644)),url("../img/chp-articles/Baskan-halk.png");
    background-size: cover;
    height: 367px
}

.chp-be-member-right-content {
    padding: 60px 50px 59px 100px
}

@media screen and (max-width: 1024px) {
    .chp-be-member-right-content-text,.chp-be-member-left-content-text {
        padding-right:78px
    }

    .chp-be-member-right-content-btn,.chp-be-member-left-content-btn {
        width: 45%
    }
}

@media screen and (max-width: 415px) {
    .chp-be-member {
        padding:0 32px
    }

    .chp-be-member-left,.chp-be-member-right {
        background-position: center;
        height: auto
    }

    .chp-be-member-left-content,.chp-be-member-right-content {
        padding: 34px 24px
    }

    .chp-be-member-left-content-text,.chp-be-member-right-content-text {
        padding-right: 0
    }

    .chp-be-member-left-content-btn,.chp-be-member-right-content-btn {
        width: 100%;
        margin-top: 52px
    }

    .chp-be-member-left-content-title,.chp-be-member-right-content-title {
        font-size: 24px
    }
}

.newsletter-subscription {
    padding-bottom: 79px;
    margin-top: 180px;
    border: 1px solid #dcdcdc;
    border-bottom: none;
    position: relative
}

.newsletter-subscription-logo {
    position: absolute;
    left: 50%
}

.newsletter-subscription-image {
    width: 110px;
    height: 130px;
    position: relative;
    top: -110px;
    left: -50%
}

.newsletter-subscription-input {
    margin-bottom: 80px;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center
}

.newsletter-subscription-input input:focus {
    outline: none
}

.newsletter-subscription-input input:hover {
    border: 1px solid #6fa5a2
}

.newsletter-subscription-input ::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #dcdcdc
}

.newsletter-subscription-input ::-moz-placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #dcdcdc
}

.newsletter-subscription-input :-ms-input-placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #dcdcdc
}

.newsletter-subscription-input ::-ms-input-placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #dcdcdc
}

.newsletter-subscription-input ::placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #dcdcdc
}

.newsletter-subscription-input button {
    margin-top: 27px;
    height: 47px
}

.newsletter-subscription-content {
    padding-top: 34px;
    margin-bottom: 40px
}

.newsletter-subscription-content-title {
    font-size: 36px;
    font-weight: 700;
    color: #063d5d
}

.newsletter-subscription-content-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 2;
    color: #063d5d;
    font-family: Gotham-Medium
}

.newsletter-subscription-btn {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.86;
    color: #f8f8f8;
    width: 140px;
    font-family: Gotham-Light
}

.newsletter-subscription-subsites-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.75;
    color: red;
    margin: 0
}

.newsletter-subscription-subsites p {
    font-size: 12px;
    font-weight: 500;
    line-height: 2;
    color: #414042;
    opacity: .5;
    font-family: Gotham-Medium
}

.newsletter-subscription-subsites a {
    text-decoration: none
}

.form-group {
    margin-bottom: 0;
    margin-right: 16px;
    padding: 0;
    position: relative
}

label.error {
    border: solid 1px #ebcccc;
    background-color: #fff6f6;
    color: #973937;
    font-family: Gotham-Medium;
    line-height: 1.71;
    font-size: 12px;
    margin-top: 12px;
    padding: 10px;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.error-btn {
    background-color: transparent;
    border: none
}

@media screen and (max-width: 768px) {
    .newsletter-subscription-subsites-content {
        -ms-flex-direction:column;
        flex-direction: column
    }

    .newsletter-subscription-subsites.second {
        margin-top: 25px
    }

    .newsletter-subscription-subsites:last-child {
        margin-top: 40px
    }

    .newsletter-subscription-input {
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .newsletter-subscription-input .form-group {
        margin-bottom: 20px;
        width: 100%
    }
}

@media screen and (max-width: 415px) {
    .newsletter-subscription {
        border-left:none;
        border-right: none
    }

    .newsletter-subscription-content-title {
        font-size: 24px
    }

    .newsletter-subscription-input {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }

    .newsletter-subscription-btn {
        margin-top: 6px;
        height: 46px
    }

    .newsletter-subscription-subsites-title {
        text-align: center
    }
}

@media(min-width: 992px) {
    .newsletter-subscription-input {
        -ms-flex-align:end;
        align-items: flex-end
    }

    .newsletter-subscription-input label.error {
        border: solid 1px #ebcccc;
        background-color: #fff6f6;
        color: #973937;
        font-family: Gotham-Medium;
        line-height: 1.71;
        font-size: 12px;
        margin-top: 12px;
        padding: 10px;
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .newsletter-subscription-input button {
        margin-top: unset
    }
}

.svg-turkiye-haritasi {
    max-width: 850px;
    margin: 0 auto;
    text-align: center
}

.svg-turkiye-haritasi svg {
    width: 100%;
    height: auto
}

.il-isimleri {
    position: absolute;
    z-index: 2
}

.il-isimleri div {
    font-family: open sans;
    display: block;
    color: #063d5d
}

#svg-turkiye-haritasi path {
    cursor: pointer
}

#svg-turkiye-haritasi path:hover {
    fill: red;
    opacity: 1!important;
    color: #fff
}

.map-text {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.46;
    letter-spacing: .26px;
    color: #063d5d
}

.contact-page-section iframe {
    width: 100%;
    height: 727px
}

.contact-page-card {
    position: absolute;
    top: 150px;
    right: 200px;
    background-color: #fff;
    width: 400px;
    height: 480px
}

.contact-page-card-content .contact-card-select {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f8f8f8;
    height: 90px;
    position: relative
}

.contact-page-card-content .contact-card-select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

.contact-page-card-content .contact-card-select .subsite-selectbox-arrow {
    position: absolute;
    right: 35px;
    bottom: 33px
}

.contact-page-card-content .contact-card-select .select-control {
    width: 360px;
    height: 46px;
    border: solid 1px #dcdcdc;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    text-indent: 15px
}

.contact-page-card-content .contact-card-select .select-control:focus,.contact-page-card-content .contact-card-select .select-control:active {
    outline: 0
}

.contact-page-card-content .contact-card-info {
    margin-left: 20px;
    margin-right: 20px
}

.contact-page-card-content .contact-card-info>div {
    margin-top: 10px
}

.contact-page-card-content .contact-card-info>div img {
    margin-right: 10px;
    -o-object-fit: contain;
    object-fit: contain
}

.contact-page-card-content .contact-card-info>div h6,.contact-page-card-content .contact-card-info>div .h6 {
    color: #000;
    font-family: Gotham-Bold;
    line-height: 1.46;
    letter-spacing: .26px;
    margin-top: 5px;
    font-size: 13px
}

.contact-page-card-content .contact-card-info>div p {
    line-height: 1.64;
    margin-left: 40px;
    margin-top: -5px;
    font-size: 14px
}

.contact-page-card-content .contact-card-info>div a {
    margin-left: 40px;
    line-height: 1.64;
    color: #e03997;
    font-size: 14px
}

.contact-page-card-content .contact-card-info>div:first-child {
    margin-top: 10px
}

.contact-page-card-mobile {
    display: none
}

@media screen and (max-width: 768px) {
    .contact-page-section {
        display:none
    }

    .contact-page-card {
        display: none
    }

    .contact-page-card-mobile {
        display: block;
        margin-top: 50px;
        width: 90%
    }

    .contact-page-card-mobile-title {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

    .contact-page-card-mobile-title h2,.contact-page-card-mobile-title .h2 {
        line-height: 1.33;
        font-size: 36px;
        border-left: 2px solid red;
        padding-left: 10px;
        margin-bottom: 30px
    }

    .contact-page-card-mobile .contact-page-card-content {
        border: 1px solid #dcdcdc
    }

    .contact-page-card-mobile .contact-page-card-content .contact-card-select {
        background: 0 0;
        border-bottom: 1px solid #dcdcdc;
        background-color: #f8f8f8;
        padding-left: 20px;
        padding-right: 20px
    }

    .contact-page-card-mobile .contact-page-card-content .contact-card-select select {
        width: 100%
    }

    .contact-page-card-mobile .contact-page-card-content .contact-card-info:last-child {
        margin-bottom: 10px
    }

    .contact-page-card-mobile h6,.contact-page-card-mobile .h6 {
        color: #000;
        line-height: 1.46;
        letter-spacing: .26px;
        font-size: 13px;
        text-align: center;
        margin-top: 12px
    }

    .contact-page-card-mobile h6:hover,.contact-page-card-mobile .h6:hover {
        cursor: pointer
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none
    }

    .select-control {
        width: 360px;
        height: 46px;
        border: solid 1px #dcdcdc;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        text-indent: 15px
    }

    .select-control:focus,.select-control:active {
        outline: 0
    }

    .subsite-selectbox-arrow {
        position: absolute;
        right: 35px;
        bottom: 28px
    }
}

.sub-management-news-section {
    margin-top: 75px
}

.sub-management-news-section .sub-management-news-main {
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center
}

.sub-management-news-section .sub-management-news-main-title h2,.sub-management-news-section .sub-management-news-main-title .h2 {
    font-family: Gotham-Bold;
    line-height: 1.67;
    color: #063d5d
}

.sub-management-news-section .sub-management-news {
    margin-top: 25px
}

.sub-management-news-section .sub-management-news-card-content {
    text-decoration: none
}

.sub-management-news-section .sub-management-news-card-content img {
    width: 100%
}

.sub-management-news-section .sub-management-news-card-content-date {
    padding: 10px 0 0 20px
}

.sub-management-news-section .sub-management-news-card-content h2,.sub-management-news-section .sub-management-news-card-content .h2 {
    color: #000;
    line-height: 1.33;
    font-family: Gotham-Bold;
    margin-top: 10px;
    font-size: 36px
}

.sub-management-news-section .sub-management-news-card-content h2:hover,.sub-management-news-section .sub-management-news-card-content .h2:hover {
    color: #063d5d
}

.sub-management-news-section .sub-management-news-card-content-show {
    position: relative
}

.sub-management-news-section .sub-management-news-card-content-show h6,.sub-management-news-section .sub-management-news-card-content-show .h6 {
    line-height: 1.33;
    font-family: Gotham-Bold;
    margin-top: 40px;
    color: red;
    font-size: 12px
}

.sub-management-news-section .sub-management-news-card-content-show h6:hover+.subsite-news-arrow,.sub-management-news-section .sub-management-news-card-content-show .h6:hover+.subsite-news-arrow {
    display: block
}

.sub-management-news-section .sub-management-news-card-content-show h6:hover,.sub-management-news-section .sub-management-news-card-content-show .h6:hover {
    cursor: pointer
}

.sub-management-news-section .sub-management-news-card-content-show .subsite-news-arrow {
    background: url(../svg/subsite-news-arrow.svg) top center no-repeat;
    display: none;
    position: absolute;
    top: 0;
    left: 100px;
    width: 16px;
    height: 16px
}

.sub-management-news-card-content:hover .effect {
    color: red
}

.sub-management-news-card-content:hover h2,.sub-management-news-card-content:hover .h2 {
    color: #063d5d
}

@media screen and (max-width: 768px) {
    .sub-management-news-section .sub-management-news-card-content {
        margin-top:20px
    }

    .sub-management-news-section .sub-management-news-card-content-show {
        display: none
    }
}

@media screen and (max-width: 415px) {
    .sub-management-news-section .sub-management-news-card-content h2,.sub-management-news-section .sub-management-news-card-content .h2 {
        font-size:16px
    }
}

.sub-management-main-filter-input {
    position: relative
}

.sub-management-main-filter-input input {
    height: 46px
}

.sub-management-main-filter-input input::-webkit-input-placeholder {
    font-family: Gotham-Bold
}

.sub-management-main-filter-input input::-moz-placeholder {
    font-family: Gotham-Bold
}

.sub-management-main-filter-input input:-ms-input-placeholder {
    font-family: Gotham-Bold
}

.sub-management-main-filter-input input::-ms-input-placeholder {
    font-family: Gotham-Bold
}

.sub-management-main-filter-input input::placeholder {
    font-family: Gotham-Bold
}

.sub-management-main-filter-input .filter-icon {
    position: absolute;
    top: 13px;
    right: 10px;
    width: 28px;
    height: 23px
}

.sub-management-main-filter-input .filter-icon:hover {
    cursor: pointer
}

.sub-management-main-input-province-filter {
    position: relative
}

.sub-management-main-input-province-filter .subsite-selectbox-arrow {
    position: absolute;
    right: 10px;
    bottom: 10px
}

.sub-management-main-input-province-filter .select-control {
    height: 46px;
    border: solid 1px #dcdcdc;
    padding: 0 12px;
    margin-left: 20px;
    width: 150px;
    color: #dcdcdc;
    background-color: #fff;
    line-height: 1.43;
    font-family: Gotham-Bold;
    font-size: 14px
}

.sub-management-main-input-province-filter .select-control:focus,.sub-management-main-input-province-filter .select-control:active {
    outline: 0
}

.sub-management-main-input-province-filter select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

.sub-management-main-input-filter-cage {
    width: 55%
}

.sub-management-main-input-filter {
    -ms-flex-pack: end;
    justify-content: flex-end
}

@media screen and (max-width: 768px) {
    .sub-management-main-filter-input {
        margin-top:0
    }
}

@media screen and (max-width: 768px) {
    .sub-management-main-input-filter-cage {
        width:100%
    }
}

@media screen and (max-width: 415px) {
    .sub-management-main-filter {
        -ms-flex-direction:column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .sub-management-main-filter-input {
        margin-top: 10px;
        position: relative
    }

    .sub-management-main-filter-input input {
        height: 46px
    }

    .sub-management-main-filter-input input::-webkit-input-placeholder {
        padding-left: 0
    }

    .sub-management-main-filter-input input::-moz-placeholder {
        padding-left: 0
    }

    .sub-management-main-filter-input input:-ms-input-placeholder {
        padding-left: 0
    }

    .sub-management-main-filter-input input::-ms-input-placeholder {
        padding-left: 0
    }

    .sub-management-main-filter-input input::placeholder {
        padding-left: 0
    }

    .sub-management-main-filter-input .filter-icon {
        position: absolute;
        top: 13px;
        right: 10px;
        width: 28px;
        height: 23px
    }
}

.management-share {
    margin-left: 32px;
    margin-top: 1px
}

.management-share:hover .management-share-icon-hidden {
    display: block;
    -webkit-animation: slide-in .5s ease;
    animation: slide-in .5s ease
}

.management-share-icon {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff
}

.management-share-icon:hover {
    cursor: pointer
}

.management-share-icon-show img {
    padding: 10px;
    border: 1px solid #dcdcdc
}

.management-share-icon-show:hover+.management-share-icon-show {
    display: none
}

.management-share-icon-hidden {
    display: none;
    position: relative
}

.management-share-icon-hidden-content {
    position: absolute;
    background-color: #fff;
    top: -23px;
    left: -181px
}

.management-share-icon-hidden-content .active-share img {
    background-color: #063d5d;
    border-left: 1px solid #dcdcdc
}

.management-share-icon-hidden-content a img {
    padding: 10px;
    border-left: 0;
    outline: 1px solid transparent;
    border: 1px solid #dcdcdc
}

.management-share-icon-hidden-content a img:hover {
    outline: 0 solid transparent;
    border: 1px solid #9bb1be
}

.management-share-icon-hidden-content a a:last-child img {
    background-color: #fff
}

.management-share-text {
    font-family: Gotham-Bold;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 95px;
    color: #063d5d;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-left: 0
}

.management-share-text a {
    color: #063d5d;
    font-family: Gotham-Bold;
    line-height: 1.86
}

.management-share-text:hover {
    cursor: pointer
}

@keyframes slide-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@media screen and (max-width: 768px) {
    .management-share {
        margin-left:5px
    }

    .management-share-icon {
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media screen and (max-width: 415px) {
    .management-share {
        margin-left:0
    }

    .management-share-icon {
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.journal-page-allnews {
    background-color: #f8f8f8;
    padding: 30px 0;
    margin-top: 30px
}

.journal-page-allnews .journal-allnews-title h5,.journal-page-allnews .journal-allnews-title .h5 {
    line-height: 1.5;
    border-left: 2px solid red;
    color: #000;
    text-transform: none;
    padding-left: 15px
}

.journal-page-allnews .journal-allnews-news-border {
    padding: 15px;
    text-decoration: none;
    border: 1px solid #dcdcdc;
    border-bottom: none
}

.journal-page-allnews .journal-allnews-news-border:last-child {
    border-bottom: 1px solid #dcdcdc
}

.journal-page-allnews .journal-allnews-news-content {
    margin-left: 24px;
    margin-top: 10px
}

.journal-page-allnews .journal-allnews-right-card {
    width: 290px;
    height: 800px;
    background: #063d5d
}

.journal-allnews-news-border:hover .effect {
    color: red
}

.journal-allnews-news-border:hover .subtitle {
    color: #063d5d
}

.journal-allnews-pagination {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 100px
}

.journal-allnews-pagination-prev {
    margin-right: 16px
}

.journal-allnews-pagination-prev .to-prev-icon {
    background: #fff url(../svg/pagination-1.svg) no-repeat center
}

.journal-allnews-pagination-prev .prev-icon {
    background: #fff url(../svg/pagination-2.svg) no-repeat center
}

.journal-allnews-pagination-numbers {
    display: -ms-flexbox;
    display: flex
}

.journal-allnews-pagination-numbers>a {
    width: 46px;
    height: 46px;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #dcdcdc;
    border-left: none;
    background-color: #fff;
    font-family: Gotham-Bold;
    color: #063d5d;
    font-size: 20px;
    line-height: 1.04;
    text-decoration: none
}

.journal-allnews-pagination-numbers>a:hover {
    background-color: #9bb1be
}

.journal-allnews-pagination-numbers>a.active {
    background: #063d5d;
    color: #fff
}

.journal-allnews-pagination-numbers>a:first-child {
    border-left: 1px solid #dcdcdc
}

.journal-allnews-pagination-next {
    margin-left: 16px
}

.journal-allnews-pagination-next .to-next-icon {
    background: #fff url(../svg/pagination-3.svg) no-repeat center
}

.journal-allnews-pagination-next .next-icon {
    background: #fff url(../svg/pagination-4.svg) no-repeat center
}

.pagination-numbers {
    display: -ms-flexbox;
    display: flex
}

.pagination-icon {
    width: 46px;
    height: 46px;
    border: 1px solid #dcdcdc;
    border-left: none;
    background-color: #fff;
    -o-object-fit: contain;
    object-fit: contain
}

.pagination-icon:hover {
    background-color: #9bb1be
}

.pagination-icon:first-child {
    border-left: 1px solid #dcdcdc
}

@media screen and (max-width: 1024px) {
    .journal-page-allnews .journal-allnews-right-card {
        width:235px
    }
}

@media screen and (max-width: 768px) {
    .journal-page-allnews .journal-allnews-right {
        display:none
    }
}

@media screen and (max-width: 415px) {
    .journal-page-allnews .journal-allnews-news-image {
        display:none
    }

    .journal-page-allnews .journal-allnews-right {
        display: none
    }

    .journal-allnews-pagination-numbers {
        display: none
    }
}

.party-management-page-member {
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 40px
}

.party-management-page-member-image {
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 190px;
    height: 190px;
    border: solid 10px rgba(185,185,185,.41)
}

.party-management-page-member .party-management-page-member-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.party-management-page-member-info {
    margin-left: 50px
}

.party-management-page-member-info h3,.party-management-page-member-info .h3 {
    font-size: 24px;
    line-height: 1.5;
    color: #063d5d
}

.party-management-page-member-info h6,.party-management-page-member-info .h6 {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    color: red;
    margin-bottom: 19px
}

.party-management-page-member-info p {
    font-family: Gotham-Medium;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.46;
    letter-spacing: .26px;
    color: #000
}

.party-management-page-member-links {
    margin-top: 20px
}

.party-management-page-member-links-mail {
    background: url(../svg/Mail.svg) no-repeat center;
    border-radius: 50%;
    border: solid 1px #dcdcdc
}

.party-management-page-member-links-mail:active {
    background: url(../svg/mail-white.svg) no-repeat center;
    border: solid 1px #dcdcdc;
    background-color: #063d5d!important
}

.party-management-page-member-links-mail:hover {
    background-color: #f8f8f8;
    border: solid 1px #9bb1be
}

.party-management-page-member-links-twitter {
    background: url(../svg/Twitter.svg) no-repeat center;
    border-radius: 50%;
    border: solid 1px #dcdcdc
}

.party-management-page-member-links-twitter:active {
    background: url(../svg/twitter-white.svg) no-repeat center;
    border: solid 1px #dcdcdc;
    background-color: #063d5d!important
}

.party-management-page-member-links-twitter:hover {
    background-color: #f8f8f8;
    border: solid 1px #9bb1be
}

.party-management-page-member-links-facebook {
    background: url(../svg/Facebook.svg) no-repeat center;
    border-radius: 50%;
    border: solid 1px #dcdcdc
}

.party-management-page-member-links-facebook:active {
    background: url(../svg/facebook-white.svg) no-repeat center;
    border: solid 1px #dcdcdc;
    background-color: #063d5d!important
}

.party-management-page-member-links-facebook:hover {
    background-color: #f8f8f8;
    border: solid 1px #9bb1be
}

.party-management-page-member-links-instagram {
    background: url(../svg/instagram.svg) no-repeat center;
    border-radius: 50%;
    border: solid 1px #dcdcdc
}

.party-management-page-member-links-instagram:active {
    background: url(../svg/instagram-white.svg) no-repeat center;
    border: solid 1px #dcdcdc;
    background-color: #063d5d!important
}

.party-management-page-member-links-instagram:hover {
    background-color: #f8f8f8;
    border: solid 1px #9bb1be
}

.party-management-page-member-links-youtube {
    background: url(../svg/youtube.svg) no-repeat center;
    border-radius: 50%;
    border: solid 1px #dcdcdc
}

.party-management-page-member-links-youtube:active {
    background: url(../svg/youtube-white.svg) no-repeat center;
    border: solid 1px #dcdcdc;
    background-color: #063d5d!important
}

.party-management-page-member-links-youtube:hover {
    background-color: #f8f8f8;
    border: solid 1px #9bb1be
}

.party-management-page-member-links-common {
    width: 48px;
    height: 48px;
    -o-object-fit: contain;
    object-fit: contain;
    margin-inline:6px}

.party-management-page-member-links-common:hover {
    cursor: pointer
}

.party-management-page-member-resume-section {
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 100px;
    background: #f8f8f8;
    overflow-x: hidden
}

.party-management-page-member-resume-section .member-right-section {
    background: #f8f8f8
}

.party-management-page-member-resume-section .party-management-page-member-resume {
    background: #f8f8f8
}

.party-management-page-member-resume-section .party-management-page-member-resume-content h3,.party-management-page-member-resume-section .party-management-page-member-resume-content .h3 {
    color: #000;
    line-height: 1.67;
    padding: 20px;
    font-size: 36px;
    border-left: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc
}

.party-management-page-member-resume-section .party-management-page-member-resume-content p {
    line-height: 1.75;
    color: #4a4a4a;
    padding: 20px;
    font-size: 16px;
    margin-top: -8px;
    border-top: none;
    border-left: 1px solid #dcdcdc;
    width: 60%
}

@media screen and (max-width: 768px) {
    .party-management-page-member .party-management-page-member-group {
        -ms-flex-direction:column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .party-management-page-member-info {
        margin-left: 15px
    }

    .party-management-page-member-resume-section .party-management-page-member-resume {
        width: 100%
    }

    .party-management-page-member-resume-section .party-management-page-member-resume-content {
        margin-left: -160px;
        margin-right: 36px
    }

    .party-management-page-member-resume-section .party-management-page-member-resume-content h3,.party-management-page-member-resume-section .party-management-page-member-resume-content .h3 {
        font-size: 24px;
        border-bottom: 1px solid #dcdcdc;
        border-right: 1px solid #dcdcdc
    }

    .party-management-page-member-resume-section .party-management-page-member-resume-content p {
        border-right: 1px solid #dcdcdc;
        width: 100%
    }
}

@media screen and (max-width: 736px) {
    .party-management-page-member-resume-section .party-management-page-member-resume-content {
        margin-left:76px;
        margin-right: 75px
    }
}

@media screen and (max-width: 415px) {
    .party-management-page-member {
        display:-ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        border: none
    }

    .party-management-page-member .party-management-page-member-group {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 20px
    }

    .party-management-page-member-info {
        text-align: center;
        margin-left: 0
    }

    .party-management-page-member-links {
        margin-top: 40px
    }

    .party-management-page-member-resume-section .party-management-page-member-resume-content {
        margin-left: 0;
        margin-right: 0
    }

    .party-management-page-member-resume-section .party-management-page-member-resume-content h3,.party-management-page-member-resume-section .party-management-page-member-resume-content .h3 {
        border-left: none;
        border-right: none
    }

    .party-management-page-member-resume-section .party-management-page-member-resume-content p {
        border-left: none;
        border-right: none
    }
}

.publications-main-title {
    padding-top: 95px;
    padding-bottom: 40px;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc
}

.publications h1,.publications .h1 {
    border-left: 2px solid red;
    padding-left: 24px
}

.publications-pad-left {
    padding-right: 0
}

.publications-pad-right {
    padding-left: 0
}

.publications-millet-dergi,.publications-edb-dergi {
    width: 192px;
    height: 271px
}

.publications-left {
    background-image: url(../img/yayin.png);
    padding: 30px 30px 54px 20px;
    height: 100%
}

.publications-right {
    background-image: url(../img/kitap.png);
    padding: 30px 30px 54px 20px;
    height: 100%
}

.publications-title {
    font-family: Gotham-Bold;
    font-size: 24px;
    line-height: 1.5;
    color: #000
}

.publications-second-line {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.publications-second-line a {
    text-decoration: none
}

.publications-download {
    font-family: Gotham-Bold;
    font-size: 14px;
    line-height: 1.86;
    color: #063d5d;
    margin-top: 5px
}

.publications-list {
    background-image: linear-gradient(to right,#f8f8f8,#f3f3f3)
}

.publications-list-left {
    padding-right: 0
}

.publications-list-left-content input {
    width: 100%;
    padding: 22px 60px;
    border: 1px solid #dcdcdc;
    border-bottom: none
}

.publications-list-left-content input:focus {
    outline: 0
}

.publications-list-left-content input::-webkit-input-placeholder {
    font-family: Gotham-Bold;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: .32px;
    color: #063d5d
}

.publications-list-left-content input::-moz-placeholder {
    font-family: Gotham-Bold;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: .32px;
    color: #063d5d
}

.publications-list-left-content input:-ms-input-placeholder {
    font-family: Gotham-Bold;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: .32px;
    color: #063d5d
}

.publications-list-left-content input::-ms-input-placeholder {
    font-family: Gotham-Bold;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: .32px;
    color: #063d5d
}

.publications-list-left-content input::placeholder {
    font-family: Gotham-Bold;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: .32px;
    color: #063d5d
}

.publications-list-left-box {
    border: 1px solid #dcdcdc;
    padding: 30px 10px 20px 20px
}

.publications-list-left-box a {
    text-decoration: none
}

.publications-list-left-box a .title-effect {
    font-size: 24px
}

.publications-list-left-box:hover .effect {
    color: red
}

.publications-list-left-box:hover .title-effect {
    color: #063d5d
}

.publications-list-right {
    padding-left: 0;
    background-color: #f3f3f3
}

.publications-list-right-category {
    font-family: Gotham-Bold;
    font-size: 16px;
    line-height: 1.38;
    color: #063d5d;
    border-bottom: 1px solid #dcdcdc;
    padding: 24px
}

.publications-list-right ul {
    list-style: none;
    padding-left: 17px
}

.publications-list-right ul li {
    margin-top: 30px
}

.publications-list-right ul li a {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    color: #000;
    text-decoration: none
}

.publications-list-line {
    width: 85%
}

.publications-input {
    position: relative;
    width: 100%
}

.publications-search {
    position: absolute;
    left: 20px;
    top: 23px
}

.publications-page-num {
    margin-bottom: 100px
}

.publications-dropdown {
    display: none
}

.publications-mobile {
    display: none
}

.publications-page-image {
    width: 73px
}

.publications-left-btn,.publications-right-btn {
    width: 192px;
    height: 46px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.86;
    font-family: Gotham-Bold
}

.publications-mobile {
    display: none
}

.publications .second-box {
    margin-top: 100px
}

.publications-arrow-down {
    display: none
}

.arrow-search {
    width: 24px;
    height: 24px
}

.publications-list-right-content li a:hover,.publications-list-right-content li a.active {
    color: red;
    border-bottom: 2px dashed #063d5d
}

.regulations-title {
    font-family: Gotham-Bold;
    font-size: 48px;
    line-height: 1.67;
    color: #063d5d;
    border-left: 2px solid red;
    padding-left: 20px
}

.regulations-input {
    width: 100%;
    position: relative
}

@media screen and (max-width: 1024px) {
    .publications-second-line {
        -ms-flex-direction:column;
        flex-direction: column
    }

    .publications .second-box {
        margin-top: 0
    }
}

@media screen and (max-width: 768px) {
    .publications-main-title {
        padding-top:60px
    }

    .publications-pad-left,.publications-pad-right {
        padding: 0 8px
    }

    .publications-dropdown {
        width: 50%;
        border-right: 1px solid #dcdcdc;
        border-top: 1px solid #dcdcdc;
        position: relative;
        padding: 0;
        margin: 0;
        list-style: none;
        display: block
    }

    .publications-dropdown-list:hover>ul {
        display: block;
        height: 530px;
        overflow-y: auto
    }

    .publications-dropdown-list-content {
        width: 100%;
        padding: 0;
        list-style: none;
        position: absolute;
        background-color: #fff;
        top: 60px;
        display: none
    }

    .publications-dropdown-list-content li {
        border-bottom: 1px solid #dcdcdc;
        padding: 20px;
        font-family: Gotham-Bold;
        font-size: 13px;
        line-height: 1.46;
        letter-spacing: .26px
    }

    .publications-dropdown-list-content li:hover {
        background-color: #f8f8f8
    }

    .publications-dropdown-list-content li a {
        text-decoration: none;
        color: #000
    }

    .publications-list-left-content {
        padding: 0
    }

    .publications-list-left-content input {
        padding: 22px 30px 22px 60px;
        border-bottom: none
    }

    .publications-list-left-box a .title-effect {
        font-size: 16px
    }

    .publications-list-right {
        display: none
    }

    .publications-list-right-category {
        border: none
    }

    .publications-input {
        width: 50%
    }

    .publications-arrow-down {
        display: block;
        position: absolute;
        right: 10px;
        top: 20px
    }

    .regulations-title {
        font-size: 36px
    }
}

@media screen and (max-width: 720px) {
    .publications-list-left-content input {
        padding-left:50px;
        padding-right: 20px
    }
}

@media screen and (max-width: 415px) {
    .publications {
        display:none
    }

    .publications-main-title h1,.publications-main-title .h1 {
        border-left: 2px solid red;
        padding-left: 24px
    }

    .publications-body {
        padding: 0 23px
    }

    .publications-main {
        display: none
    }

    .publications-mobile {
        display: block
    }

    .publications-millet-dergi,.publications-edb-dergi {
        width: 90px;
        height: 127px
    }

    .publications-title {
        font-size: 16px;
        padding: 16px
    }

    .publications-content-text {
        display: block
    }

    .publications-second-line {
        margin-top: 16px
    }

    .publications-list-boxes {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .publications-input {
        width: 100%
    }

    .publications-dropdown {
        padding: 20px;
        border: 1px solid #dcdcdc;
        border-bottom: none;
        width: 100%
    }

    .publications-dropdown-list-content {
        width: 100%;
        left: 0
    }

    .publications-search {
        left: 24px
    }

    .publications-list-right-category {
        padding: 0
    }

    .publications-page-image {
        display: none
    }

    .publications-second-line {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .publications-right-btn,.publications-left-btn {
        width: 100%
    }

    .publications-right,.publications-left {
        padding: 17px 16px 18px
    }

    .regulations-title {
        font-size: 24px
    }
}

.newsOneLine {
    margin-top: 110px
}

.newsOneLine-title {
    line-height: 1.5;
    text-align: left;
    color: #000;
    border-left: 2px solid red;
    padding: 0 20px;
    margin-bottom: 40px
}

.newsOneLine-box {
    padding-left: 0;
    border-left: 1px solid #dcdcdc
}

@media screen and (max-width: 415px) {
    .newsOneLine {
        padding:0 23px
    }

    .newsOneLine img {
        width: 100px;
        height: 56px
    }
}

.publications-details-border {
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    padding-top: 100px;
    padding-bottom: 40px
}

.publications-details-title {
    border-left: 2px solid red;
    padding-left: 20px
}

/*.publications-details-download {*/
/*    height: 200px;*/
/*    background-image: url(/assets/img/subsitegokyuzu_1.png)*/
/*}*/

.publications-details-download {
    padding: 54px 0
}

.publications-details-download-content {
    background-color: #fff;
    width: 504px
}

.publications-details-download-content-text,.publications-details-download-content-info {
    color: #063d5d;
    font-size: 14px
}

.publications-details-download-content-text {
    font-family: Gotham-Bold;
    line-height: 1.43
}

.publications-details-download-content-info {
    font-family: Gotham-Light;
    margin-top: 13px
}

.publications-details-download-icon {
    background-color: rgba(75,192,255,.33);
    width: 88px;
    height: 100px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center
}

.publications-details-download-pdf {
    padding: 23px
}

.publications-details-most-reading {
    margin-top: 90px
}

.publications-details-most-reading-title {
    border-left: 2px solid red;
    padding-left: 20px;
    margin-bottom: 40px
}

.publications-details-most-reading-box {
    margin-bottom: 16px
}

.publications-details-most-reading a {
    text-decoration: none
}

.publications-details-title:hover .effect {
    color: red
}

.publications-details-title:hover h1,.publications-details-title:hover .h1 {
    color: #063d5d
}

@media screen and (max-width: 768px) {
    .publications-details-title {
        border:none;
        padding-left: 0;
        padding: 0 23px;
        margin-bottom: 20px
    }

    .publications-details-share {
        display: none
    }

    .publications-details-download {
        padding: 40px 0
    }

    .publications-details-download-content {
        width: 300px;
        margin: auto
    }

    .publications-details-border {
        border: none;
        padding-top: 80px
    }

    .publications-details-download-pdf {
        display: none
    }

    .publications-details-download-content-text,.publications-details-download-content-info {
        padding-left: 16px
    }

    .publications-details-download-content-text {
        margin-right: 19px;
        padding-top: 18px;
        width: 200px
    }

    .publications-details-download-content-info {
        padding-bottom: 14px
    }

    .publications-details-download-icon {
        width: 90px;
        height: 127px
    }

    .publications-details-most-reading {
        padding: 0 32px
    }
}

.party-management-page-section-title {
    padding-top: 80px;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc
}

.party-management-page-section-main {
    border-top: 1px solid #dcdcdc;
    margin-bottom: 100px
}

.party-management-page-section-main a {
    text-decoration: none
}

.party-management-page-section-main a:hover {
    color: #063d5d
}

.party-management-page-section-main .party-management-page-section {
    background-color: #f3f3f3;
    border-bottom: 1px solid #dcdcdc
}

.party-management-page-section-main .party-management-page-section .party-management-page-top {
    background-color: #f3f3f3;
    border-right: 1px solid #dcdcdc
}

.party-management-page-section-main .party-management-page-section .party-management-page-top-mobile {
    display: none
}

.party-management-page-section-main .party-management-page-section .party-management-page-bottom {
    background-color: #f8f8f8;
    border-right: 1px solid #dcdcdc;
    padding-left: 8px
}

.party-management-page-section-main .party-management-page-section-aside ul {
    list-style-type: none;
    padding-top: 45px
}

.party-management-page-section-main .party-management-page-section-aside ul li {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    border-bottom: 1px solid #dcdcdc;
    padding: 16px 0;
    color: #000;
    cursor: pointer
}

.party-management-page-section-main .party-management-page-section-aside ul li:first-child {
    border-top: none
}

.party-management-page-section-main .party-management-page-section-aside ul li:last-child {
    border-bottom: 1px solid #dcdcdc
}

.party-management-page-section-main .party-management-page-section-aside ul li:hover {
    color: #063d5d
}

.party-management-page-section-main .party-management-page-section-aside ul li a {
    text-decoration: none;
    color: #000
}

.party-management-page-section-main .party-management-page-section-aside ul li a.active {
    color: #063d5d
}

.party-management-page-section-main .party-management-page-section-aside-select-arrow {
    display: none
}

.party-management-page-section-main .party-management-page-section-aside-select select {
    display: none;
    font-family: Gotham-Bold;
    line-height: 1.46;
    letter-spacing: .26px;
    border: none;
    outline: none;
    width: 100%;
    border: 1px solid #dcdcdc;
    border-top: none;
    padding: 20px;
    background-color: #f3f3f3
}

.party-management-page-section-main .party-management-page-section-members {
    padding-top: 20px;
    padding-right: 8px
}

.party-management-page-section-main .party-management-page-section-members-title h2,.party-management-page-section-main .party-management-page-section-members-title .h2 {
    color: #000
}

.party-management-page-section-main .party-management-page-section-members .party-management-page-section-center {
    -ms-flex-align: start;
    align-items: flex-start
}

.party-management-page-section-main .party-management-page-section-members-input {
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    position: relative
}

.party-management-page-section-main .party-management-page-section-members-input-filter {
    position: relative
}

.party-management-page-section-main .party-management-page-section-members-input-filter input {
    width: 300px;
    height: 46px
}

.party-management-page-section-main .party-management-page-section-members-input-filter input::-webkit-input-placeholder {
    font-family: Gotham-Bold
}

.party-management-page-section-main .party-management-page-section-members-input-filter input::-moz-placeholder {
    font-family: Gotham-Bold
}

.party-management-page-section-main .party-management-page-section-members-input-filter input:-ms-input-placeholder {
    font-family: Gotham-Bold
}

.party-management-page-section-main .party-management-page-section-members-input-filter input::-ms-input-placeholder {
    font-family: Gotham-Bold
}

.party-management-page-section-main .party-management-page-section-members-input-filter input::placeholder {
    font-family: Gotham-Bold
}

.party-management-page-section-main .party-management-page-section-members-input-filter .filter-icon {
    position: absolute;
    top: 13px;
    right: 10px;
    width: 28px;
    height: 23px
}

.party-management-page-section-main .party-management-page-section-members-input-filter .filter-icon:hover {
    cursor: pointer
}

.party-management-page-section-main .party-management-page-section-members-input .subsite-selectbox-arrow {
    position: absolute;
    right: 10px;
    bottom: 10px
}

.party-management-page-section-main .party-management-page-section-members-input .select-control {
    height: 46px;
    border: solid 1px #dcdcdc;
    padding: 0 12px;
    margin-left: 20px;
    width: 150px;
    color: #dcdcdc;
    background-color: #fff;
    line-height: 1.43;
    font-family: Gotham-Bold;
    font-size: 14px
}

.party-management-page-section-main .party-management-page-section-members-input .select-control:focus,.party-management-page-section-main .party-management-page-section-members-input .select-control:active {
    outline: 0
}

.party-management-page-section-main .party-management-page-section-members-input select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

.party-management-page-section-main .party-management-page-section-members-card {
    width: 100%
}

.party-management-page-section-main .party-management-page-section-members-card:hover h6,.party-management-page-section-main .party-management-page-section-members-card:hover .h6,.party-management-page-section-main .party-management-page-section-members-card:hover p {
    color: red
}

.party-management-page-section-main .party-management-page-section-members-card .party-management-pic {
    padding: 10px;
    width: 114px
}

.party-management-page-section-main .party-management-page-section-members-card-content {
    margin-left: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-align: start;
    align-items: flex-start
}

.party-management-page-section-main .party-management-page-section-members-card-content-top h4,.party-management-page-section-main .party-management-page-section-members-card-content-top .h4 {
    color: #063d5d
}

.party-management-page-section-main .party-management-page-section-members-card-content-bottom h6,.party-management-page-section-main .party-management-page-section-members-card-content-bottom .h6 {
    line-height: 1.46;
    letter-spacing: .26px;
    color: #414042;
    font-size: 13px
}

.party-management-page-section-main .party-management-page-section-members-card-content-bottom p {
    line-height: 1.46;
    font-size: 13px;
    color: #414042
}

.myk-members-card {
    margin-top: 29px;
    margin-bottom: 50px
}

.nopadding {
    padding: 0!important;
    border-bottom: 1px solid #dcdcdc;
    text-decoration: none
}

.nopadding:first-child,.nopadding:nth-child(2) {
    border-top: 1px solid #dcdcdc
}

.nopadding:nth-child(odd) {
    border-right: 1px solid #dcdcdc
}

.party-management-page-section-center {
    -ms-flex-pack: justify;
    justify-content: space-between
}

.party-management-district-section-members-title h3,.party-management-district-section-members-title .h3 {
    color: #000
}

@media screen and (max-width: 992px) {
    .party-management-page-section-main .party-management-page-section .party-management-page-top {
        border-right:none
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile {
        display: block;
        position: relative
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile ul {
        margin: 0;
        padding-left: 0;
        list-style: none;
        border: 1px solid #dcdcdc;
        border-top: none
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile ul li p {
        color: #000;
        font-family: Gotham-Bold;
        font-size: 24px;
        padding: 15px 26px
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile .party-management-mobile-dropdown {
        display: none
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile .party-management-mobile-dropdown li {
        padding: 15px 26px;
        border-bottom: 1px solid #dcdcdc
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile .party-management-mobile-dropdown li a {
        text-decoration: none;
        color: #063d5d;
        font-family: Gotham-Light
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile .party-management-mobile-dropdown li:first-child {
        border-top: 1px solid #dcdcdc
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile .party-management-mobile-dropdown li:last-child {
        border-bottom: none
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile .party-management-mobile-dropdown li:hover {
        background-color: #dcdcdc
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile .party-management-mobile-dropdown li:hover a {
        text-decoration: underline
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile .party-management-mobile-arrow-red {
        display: none;
        position: absolute;
        right: 20px;
        top: 20px
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile .party-management-mobile-arrow-down {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile ul li:hover .party-management-mobile-dropdown {
        display: block;
        border: none
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile ul li:hover .party-management-mobile-arrow-red {
        display: block
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile ul li:hover .party-management-mobile-arrow-down {
        display: none
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile ul:hover {
        background: #fff
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-top-mobile ul li:hover p {
        color: red
    }

    .party-management-page-section-main .party-management-page-section .party-management-page-bottom {
        background-color: #f3f3f3;
        border-right: none;
        padding-left: 0
    }

    .party-management-page-section-main .party-management-page-section-aside ul {
        display: none
    }

    .party-management-page-section-main .party-management-page-section-aside-select {
        position: relative;
        border-top: none
    }

    .party-management-page-section-main .party-management-page-section-aside-select-arrow {
        display: block;
        position: absolute;
        right: 10px;
        bottom: 20px
    }

    .party-management-page-section-main .party-management-page-section-aside-select select {
        display: block
    }

    .party-management-page-section-main .party-management-page-section-members {
        padding: 20px
    }

    .party-management-page-section-main .party-management-page-section-members-title {
        display: none
    }

    .party-management-page-section-main .party-management-page-section-members-input {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px
    }

    .party-management-page-section-main .party-management-page-section-members-input-filter input::-webkit-input-placeholder {
        padding-left: 0
    }

    .party-management-page-section-main .party-management-page-section-members-input-filter input::-moz-placeholder {
        padding-left: 0
    }

    .party-management-page-section-main .party-management-page-section-members-input-filter input:-ms-input-placeholder {
        padding-left: 0
    }

    .party-management-page-section-main .party-management-page-section-members-input-filter input::-ms-input-placeholder {
        padding-left: 0
    }

    .party-management-page-section-main .party-management-page-section-members-input-filter input::placeholder {
        padding-left: 0
    }

    .party-management-page-section-main .party-management-page-section-members-input .subsite-selectbox-arrow {
        right: 30px
    }

    .party-management-page-section-main .party-management-page-section-members-input .select-control {
        padding: 0 5px;
        width: 100%
    }

    .party-management-page-section-main .party-management-page-section-members-input select {
        width: 100%
    }

    .party-management-page-section-main .party-management-page-section-members-card {
        margin-top: 0;
        margin-right: 0;
        padding: 20px;
        border-left: 1px solid #dcdcdc
    }

    .party-management-page-section-main .party-management-page-section-members-card-content {
        margin-left: 15px;
        margin-top: 10px;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .party-management-page-section-center {
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 30px
    }

    .nopadding {
        border-bottom: 1px solid #dcdcdc
    }

    .nopadding:nth-child(2) {
        border-top: none
    }

    .nopadding:nth-child(even) {
        border-right: 1px solid #dcdcdc
    }
}

@media screen and (max-width: 415px) {
    .party-management-page-section-main {
        border-bottom:none
    }

    .party-management-page-section-main .party-management-page-section-aside ul {
        display: none
    }

    .party-management-page-section-main .party-management-page-section-aside select {
        display: block
    }

    .party-management-page-section-main .party-management-page-section-members {
        padding: 0
    }

    .party-management-page-section-main .party-management-page-section-members-title {
        display: none
    }

    .party-management-page-section-main .party-management-page-section-members-input {
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        margin-top: 10px
    }

    .party-management-page-section-main .party-management-page-section-members-input-filter input {
        width: 100%
    }

    .party-management-page-section-main .party-management-page-section-members-input .select-control {
        margin-left: 0;
        padding: 0 1px;
        width: 100%
    }

    .party-management-page-section-main .party-management-page-section-members-input .select-control:focus,.party-management-page-section-main .party-management-page-section-members-input .select-control:active {
        outline: 0
    }

    .party-management-page-section-main .party-management-page-section-members-input .subsite-selectbox-arrow {
        right: 30px
    }

    .party-management-page-section-main .party-management-page-section-members-input select {
        margin-top: 10px;
        margin-left: 0
    }

    .party-management-page-section-main .party-management-page-section-members-card {
        margin-top: 0;
        padding: 20px;
        border-left: 1px solid #dcdcdc
    }

    .party-management-page-section-main .party-management-page-section-members-card img {
        width: 100px;
        height: auto
    }

    .party-management-page-section-main .party-management-page-section-members-card-content {
        margin-left: 15px;
        margin-top: 10px;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .party-management-page-section-main .party-management-page-section-members-card-content-top h4,.party-management-page-section-main .party-management-page-section-members-card-content-top .h4,.party-management-page-section-main .party-management-page-section-members-card-content-bottom h4,.party-management-page-section-main .party-management-page-section-members-card-content-bottom .h4 {
        font-size: 17px
    }

    .party-management-page-section-center {
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 30px
    }
}

@media screen and (min-width: 540px) and (max-width:736px) {
    .party-management-page-section-main .party-management-page-section-members-input-filter input {
        width:250px
    }
}

.group-management-page-section-title {
    padding-top: 75px;
    margin-bottom: 39px
}

.sub-group-management-main-title {
    font-size: 36px;
    line-height: 1.67
}

.group-management-page-section-main {
    margin-bottom: 100px
}

.group-management-page-section-main a {
    text-decoration: none
}

.group-management-page-section-main a:hover {
    color: #063d5d
}

.group-management-page-section-main .group-management-page-section .group-management-page-top-mobile {
    display: none
}

.group-management-page-section-main .group-management-page-section .group-management-page-bottom {
    padding-inline:8px}

.group-management-page-section-main .group-management-page-section-aside ul {
    list-style-type: none
}

.group-management-page-section-main .group-management-page-section-aside ul li {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    padding: 16px 0;
    color: #000;
    cursor: pointer;
    border-top: 1px solid #dcdcdc;
    padding-left: 11px
}

.group-management-page-section-main .group-management-page-section-aside ul li:last-child {
    border-bottom: 1px solid #dcdcdc
}

.group-management-page-section-main .group-management-page-section-aside ul li:hover {
    color: #063d5d
}

.group-management-page-section-main .group-management-page-section-aside ul li a {
    text-decoration: none;
    color: #000
}

.group-management-page-section-main .group-management-page-section-aside ul li a.active {
    color: #063d5d
}

.group-management-page-section-main .group-management-page-section-aside-select-arrow {
    display: none
}

.group-management-page-section-main .group-management-page-section-aside-select select {
    display: none;
    font-family: Gotham-Bold;
    line-height: 1.46;
    letter-spacing: .26px;
    border: none;
    outline: none;
    width: 100%;
    border: 1px solid #dcdcdc;
    border-top: none;
    padding: 20px;
    background-color: #f3f3f3
}

.group-management-page-section-main .group-management-page-section-members {
    padding-top: 20px;
    padding-inline:8px}

.group-management-page-section-main .group-management-page-section-members-title h2,.group-management-page-section-main .group-management-page-section-members-title .h2 {
    color: #000
}

.group-management-page-section-main .group-management-page-section-members .group-management-page-section-center {
    -ms-flex-align: start;
    align-items: flex-start
}

.group-management-page-section-main .group-management-page-section-members-input {
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    position: relative
}

.group-management-page-section-main .group-management-page-section-members-input-filter {
    position: relative
}

.group-management-page-section-main .group-management-page-section-members-input-filter input {
    width: 300px;
    height: 46px
}

.group-management-page-section-main .group-management-page-section-members-input-filter input::-webkit-input-placeholder {
    font-family: Gotham-Bold
}

.group-management-page-section-main .group-management-page-section-members-input-filter input::-moz-placeholder {
    font-family: Gotham-Bold
}

.group-management-page-section-main .group-management-page-section-members-input-filter input:-ms-input-placeholder {
    font-family: Gotham-Bold
}

.group-management-page-section-main .group-management-page-section-members-input-filter input::-ms-input-placeholder {
    font-family: Gotham-Bold
}

.group-management-page-section-main .group-management-page-section-members-input-filter input::placeholder {
    font-family: Gotham-Bold
}

.group-management-page-section-main .group-management-page-section-members-input-filter .filter-icon {
    position: absolute;
    top: 13px;
    right: 10px;
    width: 28px;
    height: 23px
}

.group-management-page-section-main .group-management-page-section-members-input-filter .filter-icon:hover {
    cursor: pointer
}

.group-management-page-section-main .group-management-page-section-members-input .subsite-selectbox-arrow {
    position: absolute;
    right: 10px;
    bottom: 10px
}

.group-management-page-section-main .group-management-page-section-members-input .select-control {
    height: 46px;
    border: solid 1px #dcdcdc;
    padding: 0 12px;
    margin-left: 20px;
    width: 150px;
    color: #dcdcdc;
    background-color: #fff;
    line-height: 1.43;
    font-family: Gotham-Bold;
    font-size: 14px
}

.group-management-page-section-main .group-management-page-section-members-input .select-control:focus,.group-management-page-section-main .group-management-page-section-members-input .select-control:active {
    outline: 0
}

.group-management-page-section-main .group-management-page-section-members-input select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

.group-management-page-section-main .group-management-page-section-members-card {
    width: 100%
}

.group-management-page-section-main .group-management-page-section-members-card:hover h6,.group-management-page-section-main .group-management-page-section-members-card:hover .h6,.group-management-page-section-main .group-management-page-section-members-card:hover p {
    color: red
}

.group-management-page-section-main .group-management-page-section-members-card .group-management-pic {
    padding: 10px;
    width: 114px
}

.group-management-page-section-main .group-management-page-section-members-card-content {
    margin-left: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-align: start;
    align-items: flex-start
}

.group-management-page-section-main .group-management-page-section-members-card-content-top h4,.group-management-page-section-main .group-management-page-section-members-card-content-top .h4 {
    color: #063d5d
}

.group-management-page-section-main .group-management-page-section-members-card-content-bottom h6,.group-management-page-section-main .group-management-page-section-members-card-content-bottom .h6 {
    line-height: 1.46;
    letter-spacing: .26px;
    color: #414042;
    font-size: 13px
}

.group-management-page-section-main .group-management-page-section-members-card-content-bottom p {
    line-height: 1.46;
    font-size: 13px;
    color: #414042
}

.group-nopadding {
    padding: 0!important;
    border-bottom: 1px solid #dcdcdc;
    text-decoration: none;
    border-right: 1px solid #dcdcdc
}

.group-nopadding:first-child {
    border-top: 1px solid #dcdcdc
}

.group-nopadding:nth-child(2) {
    border-top: 1px solid #dcdcdc
}

.group-nopadding:nth-child(odd) {
    border-left: 1px solid #dcdcdc
}

.group-management-page-section-center {
    -ms-flex-pack: justify;
    justify-content: space-between
}

.group-management-district-section-members-title h3,.group-management-district-section-members-title .h3 {
    color: #000
}

@media screen and (max-width: 992px) {
    .sub-group-management-main-title {
        display:none!important
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top {
        border-right: none
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile {
        display: block;
        position: relative
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile ul {
        margin: 0;
        padding-left: 0;
        list-style: none;
        border: 1px solid #dcdcdc;
        border-top: none
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile ul li p {
        color: #000;
        font-family: Gotham-Bold;
        font-size: 24px;
        padding: 15px 26px
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile .group-management-mobile-dropdown {
        display: none
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile .group-management-mobile-dropdown li {
        padding: 15px 26px;
        border-top: 1px solid #dcdcdc
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile .group-management-mobile-dropdown li a {
        text-decoration: none;
        color: #063d5d;
        font-family: Gotham-Light
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile .group-management-mobile-dropdown li:last-child {
        border-top: none
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile .group-management-mobile-dropdown li:hover {
        background-color: #dcdcdc
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile .group-management-mobile-dropdown li:hover a {
        text-decoration: underline
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile .group-management-mobile-arrow-red {
        display: none;
        position: absolute;
        right: 20px;
        top: 20px
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile .group-management-mobile-arrow-down {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile ul li:hover .group-management-mobile-dropdown {
        display: block;
        border: none
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile ul li:hover .group-management-mobile-arrow-red {
        display: block
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile ul li:hover .group-management-mobile-arrow-down {
        display: none
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile ul:hover {
        background: #fff
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-top-mobile ul li:hover p {
        color: red
    }

    .group-management-page-section-main .group-management-page-section .group-management-page-bottom {
        background-color: #f3f3f3;
        border-right: none;
        padding-left: 0
    }

    .group-management-page-section-main .group-management-page-section-aside ul {
        display: none
    }

    .group-management-page-section-main .group-management-page-section-aside-select {
        position: relative;
        border-top: none
    }

    .group-management-page-section-main .group-management-page-section-aside-select-arrow {
        display: block;
        position: absolute;
        right: 10px;
        bottom: 20px
    }

    .group-management-page-section-main .group-management-page-section-aside-select select {
        display: block
    }

    .group-management-page-section-main .group-management-page-section-members {
        padding: 20px
    }

    .group-management-page-section-main .group-management-page-section-members-title {
        display: none
    }

    .group-management-page-section-main .group-management-page-section-members-input {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px
    }

    .group-management-page-section-main .group-management-page-section-members-input-filter input::-webkit-input-placeholder {
        padding-left: 0
    }

    .group-management-page-section-main .group-management-page-section-members-input-filter input::-moz-placeholder {
        padding-left: 0
    }

    .group-management-page-section-main .group-management-page-section-members-input-filter input:-ms-input-placeholder {
        padding-left: 0
    }

    .group-management-page-section-main .group-management-page-section-members-input-filter input::-ms-input-placeholder {
        padding-left: 0
    }

    .group-management-page-section-main .group-management-page-section-members-input-filter input::placeholder {
        padding-left: 0
    }

    .group-management-page-section-main .group-management-page-section-members-input .subsite-selectbox-arrow {
        right: 30px
    }

    .group-management-page-section-main .group-management-page-section-members-input .select-control {
        padding: 0 5px;
        width: 100%
    }

    .group-management-page-section-main .group-management-page-section-members-input select {
        width: 100%
    }

    .group-management-page-section-main .group-management-page-section-members-card {
        margin-top: 0;
        margin-right: 0;
        padding: 20px;
        border-left: 1px solid #dcdcdc
    }

    .group-management-page-section-main .group-management-page-section-members-card-content {
        margin-left: 15px;
        margin-top: 10px;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .group-management-page-section-center {
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 30px
    }

    .group-nopadding {
        border-bottom: 1px solid #dcdcdc
    }

    .group-nopadding:nth-child(2) {
        border-top: none
    }

    .group-nopadding:nth-child(even) {
        border-right: 1px solid #dcdcdc
    }
}

@media screen and (max-width: 415px) {
    .group-management-page-section-main {
        border-bottom:none
    }

    .group-management-page-section-main .group-management-page-section-aside ul {
        display: none
    }

    .group-management-page-section-main .group-management-page-section-aside select {
        display: block
    }

    .group-management-page-section-main .group-management-page-section-members {
        padding: 0
    }

    .group-management-page-section-main .group-management-page-section-members-title {
        display: none
    }

    .group-management-page-section-main .group-management-page-section-members-input {
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        margin-top: 10px
    }

    .group-management-page-section-main .group-management-page-section-members-input-filter input {
        width: 100%
    }

    .group-management-page-section-main .group-management-page-section-members-input .select-control {
        margin-left: 0;
        padding: 0 1px;
        width: 100%
    }

    .group-management-page-section-main .group-management-page-section-members-input .select-control:focus,.group-management-page-section-main .group-management-page-section-members-input .select-control:active {
        outline: 0
    }

    .group-management-page-section-main .group-management-page-section-members-input .subsite-selectbox-arrow {
        right: 30px
    }

    .group-management-page-section-main .group-management-page-section-members-input select {
        margin-top: 10px;
        margin-left: 0
    }

    .group-management-page-section-main .group-management-page-section-members-card {
        margin-top: 0;
        padding: 20px;
        border-left: 1px solid #dcdcdc
    }

    .group-management-page-section-main .group-management-page-section-members-card img {
        width: 100px;
        height: auto
    }

    .group-management-page-section-main .group-management-page-section-members-card-content {
        margin-left: 15px;
        margin-top: 10px;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .group-management-page-section-main .group-management-page-section-members-card-content-top h4,.group-management-page-section-main .group-management-page-section-members-card-content-top .h4,.group-management-page-section-main .group-management-page-section-members-card-content-bottom h4,.group-management-page-section-main .group-management-page-section-members-card-content-bottom .h4 {
        font-size: 17px
    }

    .group-management-page-section-center {
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 30px
    }
}

@media screen and (min-width: 540px) and (max-width:736px) {
    .group-management-page-section-main .group-management-page-section-members-input-filter input {
        width:250px
    }
}

.publications-live-read {
    background-image: url(../img/Document_Bg.webp);
    max-height: 900px
}

.publications-live-read-flip {
    margin: auto;
    display: block;
    width: 960px;
    height: 680px;
    padding-top: 60px;
    padding-bottom: 50px
}

.publications-live-read-logo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 48px
}

.publications-live-read-logo img {
    width: 200px
}

.genel-baskan {
    margin-top: 40px;
    margin-bottom: 40px
}

.genel-baskan-general-info {
    margin-left: 67px
}

.genel-baskan-general-info-title {
    font-size: 24px;
    line-height: 1.5;
    color: #063d5d
}

.genel-baskan-general-info-degree {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    color: red;
    margin-bottom: 19px
}

.genel-baskan-general-info-mail,.genel-baskan-general-info-num {
    font-family: Gotham-Medium;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.46;
    letter-spacing: .26px;
    color: #000
}

.genel-baskan-info {
    background-color: #f3f3f3;
    border: 1px solid #dcdcdc;
    overflow: hidden
}

.genel-baskan-info-list {
    padding-right: 0
}

.genel-baskan-info-list-content {
    padding-left: 0;
    list-style: none;
    padding-top: 45px;
    border-right: 1px solid #dcdcdc
}

.genel-baskan-info-list-content li {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    border-bottom: 1px solid #dcdcdc;
    padding: 15px 26px;
    cursor: pointer
}

.genel-baskan-info-list-content li a {
    text-decoration: none;
    color: #000
}

.genel-baskan-info-list-content li a.active {
    color: #063d5d;
    text-decoration: underline
}

.genel-baskan-info-list-content li:last-child {
    border: none
}

.genel-baskan-info-list-mobile {
    display: none
}

.genel-baskan-info-content {
    padding: 0
}

.genel-baskan-info-content-title {
    line-height: 1.67;
    margin: 0;
    color: #000;
    padding: 17px 0 23px 20px;
    border-bottom: 1px solid #dcdcdc
}

.genel-baskan-info-content-text {
    font-family: Gotham-Light;
    font-size: 16px;
    padding: 30px 20px;
    line-height: 1.75;
    color: #4a4a4a
}

.genel-baskan-info-content .scheduler-col {
    position: relative
}

.genel-baskan-info-content .scheduler-col:before {
    position: absolute;
    content: " ";
    top: 0;
    width: 6000px;
    height: 900px
}

.genel-baskan-info-content .scheduler-col.is-right::before {
    left: -15px;
    background-color: #f8f8f8;
    z-index: -99;
    padding: 20px
}

.genel-baskan-info-property .dec-image {
    width: 400px
}

.genel-baskan-info-speech .journal-allnews-title {
    display: none
}

.genel-baskan-info-speech .journal-page-allnews {
    margin-top: 0;
    padding: 0
}

.genel-baskan-info-speech .journal-allnews-right {
    display: none
}

.genel-baskan-info-speech .journal-allnews-news-border {
    border-left: none
}

.genel-baskan-info-speech .journal-allnews-news-border:first-child {
    border-top: none
}

.genel-baskan-card {
    margin: auto;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc
}

.genel-baskan-dropdown {
    list-style: none
}

.genel-baskan-arrow-down {
    display: none
}

.genel-baskan-form-title {
    margin-bottom: 40px;
    margin-top: 100px
}

.genel-baskan-form-title p {
    font-family: Gotham-Light
}

.genel-baskan-title-arrow-down {
    display: none
}

@media screen and (max-width: 768px) {
    .genel-baskan-info {
        padding-bottom:50px
    }

    .genel-baskan-info-content-title {
        display: none
    }

    .genel-baskan-info-content-text {
        padding: 30px 10px
    }

    .genel-baskan-dropdown {
        display: block
    }

    .genel-baskan-info-list-mobile {
        display: block;
        position: relative
    }

    .genel-baskan-info-list-mobile ul {
        margin: 0;
        padding-left: 0;
        list-style: none;
        border: 1px solid #dcdcdc;
        border-top: none
    }

    .genel-baskan-info-list-mobile ul li p {
        color: #000;
        font-family: Gotham-Bold;
        font-size: 24px;
        padding: 15px 26px
    }

    .genel-baskan-info-list ul {
        display: none
    }

    .genel-baskan-info-property .dec-image {
        width: 275px
    }

    .genel-baskan-form-title h1,.genel-baskan-form-title .h1 {
        font-size: 48px
    }

    .genel-baskan-arrow-down {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px
    }

    .genel-baskan-arrow-red {
        display: none
    }

    .baskan-list-dropdown li {
        padding: 15px 26px;
        border-bottom: 1px solid #dcdcdc
    }

    .baskan-list-dropdown li a {
        text-decoration: none;
        color: #063d5d;
        font-family: Gotham-Light
    }

    .baskan-list-dropdown li:first-child {
        border-top: 1px solid #dcdcdc
    }

    .baskan-list-dropdown li:hover {
        background-color: #dcdcdc
    }

    .baskan-list-dropdown li:hover a {
        text-decoration: underline
    }

    .baskan-list-dropdown {
        display: none;
        list-style: none;
        padding-left: 0;
        height: 424px;
        overflow-x: auto
    }

    .genel-baskan-info-list-mobile {
        position: relative
    }

    .genel-baskan-info-list-mobile ul li:hover .baskan-list-dropdown {
        display: block;
        border: none
    }

    .genel-baskan-info-list-mobile ul li:hover .genel-baskan-arrow-red {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px
    }

    .genel-baskan-info-list-mobile ul li:hover .genel-baskan-arrow-down {
        display: none
    }

    .genel-baskan-info-list-mobile ul:hover {
        background-color: #fff
    }

    .genel-baskan-info-list-mobile ul li:hover p {
        color: red
    }

    ::-webkit-scrollbar-track {
        background-color: #9bb1be
    }

    ::-webkit-scrollbar {
        width: 10px
    }

    ::-webkit-scrollbar-thumb {
        background-color: #51778e;
        border-radius: 5px
    }
}

@media screen and (max-width: 415px) {
    .genel-baskan-mobile {
        -ms-flex-direction:column;
        flex-direction: column
    }

    .genel-baskan-general-info {
        text-align: center;
        margin-left: 0;
        margin-top: 20px
    }

    .genel-baskan-card {
        border: none
    }

    .genel-baskan-form-title h1,.genel-baskan-form-title .h1 {
        font-size: 24px
    }

    .genel-baskan-info-list-content:hover {
        width: 100%
    }
}

.SocialMediaIconCircle {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center
}

.SocialMediaIconCircle a {
    width: 46px;
    height: 46px;
    background-color: #fff;
    border: solid 1px #dcdcdc;
    border-radius: 50%;
    padding: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.SocialMediaIconCircle a:not(:last-child) {
    margin-right: 16px
}

.SocialMediaIconCircle a.social-media-twitter {
    background: url(../svg/Twitter.svg) center no-repeat
}

.SocialMediaIconCircle a.social-media-instagram {
    background: url(../svg/instagram.svg) center no-repeat
}

.SocialMediaIconCircle a.social-media-facebook {
    background: url(../svg/Facebook.svg) center no-repeat
}

.SocialMediaIconCircle a.social-media-youtube {
    background: url(../svg/youtube.svg) center no-repeat
}

.SocialMediaIconCircle a.social-media-email {
    background: url(../svg/Mail.svg) center no-repeat
}

.SocialMediaIconCircle a:hover {
    background-color: #f8f8f8;
    border: solid 1px #9bb1be
}

.SocialMediaIconCircle a:active {
    background-color: #063d5d!important;
    border: solid 1px #dcdcdc
}

.SocialMediaIconCircle a:active.social-media-twitter {
    background: url(../svg/twitter-white.svg) center no-repeat
}

.SocialMediaIconCircle a:active.social-media-instagram {
    background: url(../svg/instagram-white.svg) center no-repeat
}

.SocialMediaIconCircle a:active.social-media-facebook {
    background: url(../svg/facebook-white.svg) center no-repeat
}

.SocialMediaIconCircle a:active.social-media-youtube {
    background: url(../svg/youtube-white.svg) center no-repeat
}

.SocialMediaIconCircle a:active.social-media-email {
    background: url(../svg/mail-white.svg) center no-repeat
}

@media screen and (min-width: 768px) {
    .SocialMediaIconCircle a:last-child {
        margin-right:30px
    }
}

@media screen and (max-width: 768px) {
    .SocialMediaIconCircle {
        margin-top:-25px;
        margin-left: 272px;
        margin-bottom: 42px;
        -ms-flex-pack: start;
        justify-content: start
    }
}

@media screen and (max-width: 415px) {
    .SocialMediaIconCircle {
        margin-left:0;
        margin-top: 0;
        -ms-flex-pack: center;
        justify-content: center
    }

    .SocialMediaIconCircle a:last-child {
        margin-right: 0
    }
}

.journal-detail-section-main-content {
    padding-top: 100px;
    padding-bottom: 120px;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc
}

.journal-detail-section-main-content-title {
    border-left: 2px solid red;
    padding-left: 10px;
    width: 100%
}

.journal-detail-section-main-content-title h1,.journal-detail-section-main-content-title .h1 {
    font-family: Gotham-Bold;
    line-height: 1.25;
    color: #000;
    font-size: 48px
}

.journal-detail-section-main-content-title p {
    font-family: Gotham-Bold;
    line-height: 1.38;
    color: #b7b7b7
}

.journal-detail-section-main-content-title p:hover {
    color: red
}

.journal-detail-section-main-content-title:hover .effect {
    color: red
}

.journal-detail-section {
    background-color: #f8f8f8;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc
}

.journal-detail-section .sub-news {
    border-right: 1px solid #dcdcdc;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.journal-detail-section .sub-news-detail {
    border-left: 1px solid #dcdcdc;
    margin-top: -60px
}

.journal-detail-section .sub-news-detail img {
    width: 100%;
    height: auto
}

.journal-detail-section .sub-news-detail-mobile {
    display: none
}

.journal-detail-section .sub-news-detail-tablet {
    display: none
}

.journal-detail-section .sub-news-detail-content {
    padding-top: 35px
}

.journal-detail-section .sub-news-detail-content p {
    font-family: Gotham-Light;
    font-weight: 500;
    line-height: 1.75;
    color: #4a4a4a;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 5px
}

.journal-detail-section .sub-news-detail-content img {
    margin-top: 70px
}

.journal-detail-section .sub-news-detail-content .newsdetail-video {
    position: relative
}

.journal-detail-section .sub-news-detail-content .newsdetail-video-red {
    background-color: red;
    width: 80px;
    height: 10px
}

.journal-detail-section .sub-news-detail-content .newsdetail-video-blue {
    background-color: #063d5d;
    width: 725px;
    height: 10px
}

.journal-detail-section .sub-news-detail-content .newsdetail-video-play {
    background: url(../svg/subsite-newsdetail-play.svg) no-repeat center;
    position: absolute;
    width: 59px;
    height: 59px;
    top: 250px;
    left: 380px
}

.journal-detail-section .sub-news-detail-content .newsdetail-video-play:hover {
    cursor: pointer
}

.journal-detail-section .sub-news-detail-content .newsdetail-video h5,.journal-detail-section .sub-news-detail-content .newsdetail-video .h5 {
    font-family: Gotham-Bold;
    border: 1px solid #dcdcdc;
    border-left: none;
    border-top: none;
    color: #063d5d;
    font-weight: 700;
    line-height: 1.75;
    padding: 25px;
    background-color: #fff
}

.journal-detail-section .sub-news-detail-content .newsdetail-quote h6,.journal-detail-section .sub-news-detail-content .newsdetail-quote .h6 {
    font-family: Gotham-Bold;
    color: #063d5d;
    font-weight: 500;
    font-style: italic;
    line-height: 1.6;
    margin: 50px 0 200px 70px;
    padding-left: 15px;
    border-left: 5px solid #063d5d
}

.journal-detail-section .sub-news .journal-detail-section-icons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: end;
    margin-right: 2%;
    margin-top: -60px
}

.journal-detail-section .sub-news .journal-detail-section-icons>div {
    margin-left: -20px
}

.video-detail {
    width: 744px;
    height: 418px
}

@media screen and (max-width: 1024px) {
    .journal-detail-section-main {
        margin-bottom:100px
    }

    .journal-detail-section-main-content {
        padding-top: 50px;
        padding-bottom: 10px;
        border: 0
    }

    .journal-detail-section-main-content-title {
        width: 100%
    }

    .journal-detail-section-main-content-title h1,.journal-detail-section-main-content-title .h1 {
        font-size: 36px
    }

    .journal-detail-section .sub-news {
        margin-top: 0;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .journal-detail-section .sub-news-detail {
        margin-top: -60px
    }

    .journal-detail-section .sub-news-detail-mobile {
        display: none
    }

    .journal-detail-section .sub-news-detail-tablet {
        display: block
    }

    .journal-detail-section .sub-news-detail img {
        width: 100%
    }

    .journal-detail-section .sub-news-detail-content .newsdetail-video-play {
        top: 200px;
        left: 250px
    }

    .journal-detail-section .sub-news-detail-content .newsdetail-video h5,.journal-detail-section .sub-news-detail-content .newsdetail-video .h5 {
        padding: 10px;
        font-size: 15px;
        background-color: #fff
    }

    .journal-detail-section .sub-news-detail-content .newsdetail-quote h6,.journal-detail-section .sub-news-detail-content .newsdetail-quote .h6 {
        margin-left: 0
    }

    .journal-detail-section .sub-news .journal-detail-section-icons {
        display: none
    }

    .video-detail {
        width: 520px;
        height: 300px
    }
}

@media screen and (max-width: 540px) {
    .video-detail {
        width:500px
    }
}

@media screen and (max-width: 415px) {
    .journal-detail-section-main {
        margin-bottom:100px
    }

    .journal-detail-section-main-content {
        padding-top: 50px;
        padding-bottom: 10px;
        border: 0
    }

    .journal-detail-section-main-content-title {
        width: 100%;
        border-left: none
    }

    .journal-detail-section-main-content-title h1,.journal-detail-section-main-content-title .h1 {
        font-size: 24px
    }

    .journal-detail-section .sub-news {
        margin-top: 0;
        -ms-flex-direction: column;
        flex-direction: column;
        border-right: none
    }

    .journal-detail-section .sub-news-detail {
        border-left: none;
        margin-top: -60px
    }

    .journal-detail-section .sub-news-detail-mobile {
        display: block
    }

    .journal-detail-section .sub-news-detail-tablet {
        display: none
    }

    .journal-detail-section .sub-news-detail img {
        width: 100%;
        height: auto
    }

    .journal-detail-section .sub-news-detail-content .newsdetail-video-play {
        top: 150px;
        left: 160px
    }

    .journal-detail-section .sub-news-detail-content .newsdetail-video h5,.journal-detail-section .sub-news-detail-content .newsdetail-video .h5 {
        padding: 10px;
        font-size: 15px;
        background-color: #fff
    }

    .journal-detail-section .sub-news-detail-content .newsdetail-quote h6,.journal-detail-section .sub-news-detail-content .newsdetail-quote .h6 {
        margin-left: 0
    }

    .journal-detail-section .sub-news .journal-detail-section-icons {
        display: none
    }

    .video-detail {
        width: 330px;
        height: auto
    }
}

@media screen and (max-width: 320px) {
    .video-detail {
        width:275px
    }
}

.journal-page-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 30px
}

.journal-page-content-title {
    border-left: 2px solid red;
    padding-left: 10px;
    color: #063d5d
}

.journal-page-content-option {
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 0
}

.journal-page-content-option-category {
    color: #658699;
    font-family: Gotham-Medium;
    font-size: 24px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 310px;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative
}

.journal-page-content-option-category p {
    text-align: end
}

.journal-page-content-option-category:hover {
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.16)
}

.journal-page-content-option-category:hover p {
    color: red
}

.journal-page-content-option-category:hover>ul {
    display: block;
    background-color: #fff
}

.journal-page-content-option-list {
    display: none;
    background-color: #fff;
    padding-left: 0;
    list-style: none;
    display: none;
    position: absolute;
    width: 310px;
    height: 400px;
    overflow-y: scroll;
    top: 100%;
    right: 0;
    z-index: 2
}

.journal-page-content-option-list li {
    font-family: Gotham-Light;
    font-size: 24px;
    line-height: 3.33;
    color: #063d5d;
    padding-left: 30px;
    border-top: 1px solid #dcdcdc
}

.journal-page-content-option-list li:hover {
    background-color: #dcdcdc
}

.journal-page-content-option-list li:hover a {
    text-decoration: underline
}

.journal-page-content-option-list li a {
    color: #063d5d;
    text-decoration: none
}

.journal-page-content-option ::-webkit-scrollbar-track {
    background-color: #9bb1be
}

.journal-page-content-option ::-webkit-scrollbar {
    width: 10px
}

.journal-page-content-option ::-webkit-scrollbar-thumb {
    background-color: #51778e;
    border-radius: 5px
}

@media screen and (max-width: 415px) {
    .journal-page-content {
        display:-ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0
    }

    .journal-page-content-option-category {
        width: 100%
    }

    .journal-page-content-option-category:hover {
        box-shadow: none
    }

    .journal-page-content-option-category p {
        display: none
    }

    .journal-page-content-option-list {
        box-shadow: 0 3px 6px 0 rgba(0,0,0,.16)
    }
}

.option-icon-dark {
    display: none
}

.journal-page-content-option-category:hover .option-icon-light {
    display: none
}

.journal-page-content-option-category:hover .option-icon-dark {
    display: block
}

.journal-detail-page-right-icon {
    margin-left: 10px
}

.journal-detail-page-right-icon-button {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    outline: 1px solid transparent;
    border: 1px solid #dcdcdc
}

.journal-detail-page-right-icon-button:hover {
    cursor: pointer;
    outline: 0 solid transparent;
    border: 1px solid #9bb1be
}

.journal-detail-page-right-icon-button img {
    padding: 10px
}

.journal-detail-page-right-icon-scroll {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    margin-left: 10px
}

.journal-detail-page-right-icon-scroll:hover {
    cursor: pointer
}

.journal-detail-page-right-icon-scroll img {
    padding: 10px;
    border: 1px solid #dcdcdc
}

.journal-detail-page-right-icon-scroll-button:hover .journal-detail-page-right-icon-scroll-button-hidden {
    display: block;
    -webkit-animation: slide-in .5s ease;
    animation: slide-in .5s ease
}

.journal-detail-page-right-icon-scroll-button:hover {
    cursor: pointer;
    background-color: #063d5d
}

.journal-detail-page-right-icon-scroll-button:hover .journal-detail-page-right-icon-scroll-button-show {
    display: none
}

.journal-detail-page-right-icon-scroll-button:hover .journal-detail-page-right-icon-scroll-button-hiddenicon {
    display: block
}

.journal-detail-page-right-icon-scroll-button-hiddenicon {
    display: none
}

.journal-detail-page-right-icon-scroll-button-hidden {
    display: none;
    position: relative;
    z-index: 5
}

.journal-detail-page-right-icon-scroll-button-hidden-content {
    position: absolute;
    background-color: #fff;
    top: -46px;
    left: -91px
}

.journal-detail-page-right-icon-scroll-button-hidden-content img {
    padding: 10px;
    border: 1px solid #dcdcdc
}

.journal-detail-page-right-icon-scroll-button-hidden-content img:hover {
    border: solid 1px #9bb1be
}

.journal-detail-page-right-icon-scroll-button-hidden-content img:first-child:hover {
    border-right: 1px solid #9bb1be
}

.journal-detail-page-right-icon-scroll-button-hidden-content img:nth-child(2) {
    margin-left: -4px
}

@keyframes slide-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@media screen and (max-width: 1024px) {
    .journal-detail-page-right-icon {
        margin-left:10px
    }

    .journal-detail-page-right-icon-scroll-button-hidden-content {
        display: -ms-flexbox;
        display: flex;
        left: -89px
    }

    .journal-detail-page-right-icon-scroll-button-hidden-content img:first-child {
        border-right: none
    }

    .journal-detail-page-right-icon-scroll-button-hidden-content img:nth-child(2) {
        margin-left: 0
    }
}

@media screen and (max-width: 768px) {
    .journal-detail-page-right-icon {
        margin-left:10px
    }

    .journal-detail-page-right-icon-scroll-button-hidden-content {
        display: -ms-flexbox;
        display: flex;
        left: -89px
    }

    .journal-detail-page-right-icon-scroll-button-hidden-content img:first-child {
        border-right: none
    }

    .journal-detail-page-right-icon-scroll-button-hidden-content img:nth-child(2) {
        margin-left: 0
    }
}

@media screen and (max-width: 415px) {
    .journal-detail-page-right-icon {
        margin-left:24px
    }
}

.form-right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: stretch;
    justify-content: stretch
}

.form-right div {
    width: 100%
}

.form-right textarea.form-control {
    height: 135px
}

.form-left {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.form .form-control {
    padding: 12px 16px;
    border-radius: 0
}

.form .form-label,.form .text-label {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    color: #000
}

@media screen and (max-width: 768px) {
    .form .btn {
        width:100%!important;
        margin-top: 10px;
        margin-right: 16px
    }
}

@media(min-width: 992px) {
    .form-right .flex-column-stretch {
        display:-ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: stretch;
        justify-content: stretch
    }

    .form-right textarea.form-control {
        height: 100%
    }
}

.party-management-cityhead {
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 100px
}

.party-management-cityhead-member {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #dcdcdc;
    border-top: none;
    border-bottom: none;
    padding: 40px
}

.party-management-cityhead-member .party-management-cityhead-member-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.party-management-cityhead-member-info {
    margin-left: 50px
}

.party-management-cityhead-member-info h3,.party-management-cityhead-member-info .h3 {
    font-size: 24px;
    line-height: 1.5;
    color: #063d5d;
    text-transform: uppercase
}

.party-management-cityhead-member-info h6,.party-management-cityhead-member-info .h6 {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    color: red;
    margin-bottom: 19px;
    text-transform: uppercase
}

@media screen and (max-width: 768px) {
    .party-management-cityhead-member .party-management-cityhead-member-group {
        -ms-flex-direction:column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .party-management-cityhead-member-info {
        margin-left: 15px
    }
}

@media screen and (max-width: 415px) {
    .party-management-cityhead {
        margin-top:70px
    }

    .party-management-cityhead-member {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        border: none
    }

    .party-management-cityhead-member .party-management-cityhead-member-group {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center
    }

    .party-management-cityhead-member-info {
        text-align: center;
        margin-left: 0
    }
}

.party-management-district-section-title {
    padding-top: 80px;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc
}

.party-management-district-section-main {
    border-top: 1px solid #dcdcdc;
    margin-bottom: 100px
}

.party-management-district-section-main a {
    text-decoration: none
}

.party-management-district-section-main .party-management-district-section {
    background-color: #f3f3f3;
    border-bottom: 1px solid #dcdcdc
}

.party-management-district-section-main .party-management-district-section .party-management-district-top {
    background-color: #f3f3f3;
    border-right: 1px solid #dcdcdc
}

.party-management-district-section-main .party-management-district-section .party-management-district-top-mobile {
    display: none
}

.party-management-district-section-main .party-management-district-section .party-management-district-bottom {
    background-color: #f8f8f8;
    border-right: 1px solid #dcdcdc;
    padding-left: 8px
}

.party-management-district-section-main .party-management-district-section-aside ul {
    list-style-type: none;
    padding-top: 45px
}

.party-management-district-section-main .party-management-district-section-aside ul li {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    border-bottom: 1px solid #dcdcdc;
    padding: 16px 0;
    color: #000;
    cursor: pointer
}

.party-management-district-section-main .party-management-district-section-aside ul li:first-child {
    border-top: none
}

.party-management-district-section-main .party-management-district-section-aside ul li:last-child {
    border-bottom: 1px solid #dcdcdc
}

.party-management-district-section-main .party-management-district-section-aside ul li:hover {
    color: #063d5d
}

.party-management-district-section-main .party-management-district-section-aside ul li a {
    text-decoration: none;
    color: #000
}

.party-management-district-section-main .party-management-district-section-aside ul li a.active {
    color: #063d5d
}

.party-management-district-section-main .party-management-district-section-aside-select-arrow {
    display: none
}

.party-management-district-section-main .party-management-district-section-aside-select select {
    display: none;
    font-family: Gotham-Bold;
    line-height: 1.46;
    letter-spacing: .26px;
    border: none;
    outline: none;
    width: 100%;
    border: 1px solid #dcdcdc;
    border-top: none;
    padding: 20px;
    background-color: #f3f3f3
}

.party-management-district-section-main .party-management-district-section-members {
    padding-top: 20px;
    padding-right: 8px
}

.party-management-district-section-main .party-management-district-section-members-title h2,.party-management-district-section-main .party-management-district-section-members-title .h2 {
    color: #000
}

.party-management-district-section-main .party-management-district-section-members .party-management-district-section-center {
    -ms-flex-align: start;
    align-items: flex-start
}

.party-management-district-section-main .party-management-district-section-members-input {
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    position: relative
}

.party-management-district-section-main .party-management-district-section-members-input-filter {
    position: relative
}

.party-management-district-section-main .party-management-district-section-members-input-filter input {
    width: 300px;
    height: 46px
}

.party-management-district-section-main .party-management-district-section-members-input-filter input::-webkit-input-placeholder {
    font-family: Gotham-Bold
}

.party-management-district-section-main .party-management-district-section-members-input-filter input::-moz-placeholder {
    font-family: Gotham-Bold
}

.party-management-district-section-main .party-management-district-section-members-input-filter input:-ms-input-placeholder {
    font-family: Gotham-Bold
}

.party-management-district-section-main .party-management-district-section-members-input-filter input::-ms-input-placeholder {
    font-family: Gotham-Bold
}

.party-management-district-section-main .party-management-district-section-members-input-filter input::placeholder {
    font-family: Gotham-Bold
}

.party-management-district-section-main .party-management-district-section-members-input-filter .filter-icon {
    position: absolute;
    top: 13px;
    right: 10px;
    width: 28px;
    height: 23px
}

.party-management-district-section-main .party-management-district-section-members-input-filter .filter-icon:hover {
    cursor: pointer
}

.party-management-district-section-main .party-management-district-section-members-input .subsite-selectbox-arrow {
    position: absolute;
    right: 10px;
    bottom: 10px
}

.party-management-district-section-main .party-management-district-section-members-input .select-control {
    height: 46px;
    border: solid 1px #dcdcdc;
    padding: 0 12px;
    margin-left: 20px;
    width: 150px;
    color: #dcdcdc;
    background-color: #fff;
    line-height: 1.43;
    font-family: Gotham-Bold;
    font-size: 14px
}

.party-management-district-section-main .party-management-district-section-members-input .select-control:focus,.party-management-district-section-main .party-management-district-section-members-input .select-control:active {
    outline: 0
}

.party-management-district-section-main .party-management-district-section-members-input select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

.party-management-district-section-main .party-management-district-section-members-card {
    width: 100%
}

.party-management-district-section-main .party-management-district-section-members-card:hover h6,.party-management-district-section-main .party-management-district-section-members-card:hover .h6,.party-management-district-section-main .party-management-district-section-members-card:hover p {
    color: red
}

.party-management-district-section-main .party-management-district-section-members-card img {
    padding: 10px
}

.party-management-district-section-main .party-management-district-section-members-card-content {
    margin-left: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-align: start;
    align-items: flex-start
}

.party-management-district-section-main .party-management-district-section-members-card-content-top h4,.party-management-district-section-main .party-management-district-section-members-card-content-top .h4 {
    color: #063d5d
}

.party-management-district-section-main .party-management-district-section-members-card-content-bottom h6,.party-management-district-section-main .party-management-district-section-members-card-content-bottom .h6 {
    line-height: 1.46;
    letter-spacing: .26px;
    color: #414042;
    font-size: 13px
}

.party-management-district-section-main .party-management-district-section-members-card-content-bottom p {
    line-height: 1.46;
    font-size: 13px;
    color: #414042
}

.district-heads-card {
    margin-top: 29px;
    margin-bottom: 50px
}

.nopadding {
    padding: 0!important;
    border-bottom: 1px solid #dcdcdc
}

.nopadding:first-child,.nopadding:nth-child(2) {
    border-top: 1px solid #dcdcdc
}

.nopadding:nth-child(odd) {
    border-right: 1px solid #dcdcdc
}

.party-management-district-section-center {
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media screen and (max-width: 768px) {
    .party-management-district-section-main .party-management-district-section .party-management-district-bottom {
        background-color:#f3f3f3;
        border-right: none;
        padding-left: 0
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top {
        border-right: none
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile {
        display: block;
        position: relative
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile ul {
        margin: 0;
        padding-left: 0;
        list-style: none;
        border: 1px solid #dcdcdc;
        border-top: none
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile ul li p {
        color: #000;
        font-family: Gotham-Bold;
        font-size: 24px;
        padding: 15px 26px
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile .party-management-mobile-dropdown {
        display: none
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile .party-management-mobile-dropdown li {
        padding: 15px 26px;
        border-bottom: 1px solid #dcdcdc
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile .party-management-mobile-dropdown li a {
        text-decoration: none;
        color: #063d5d;
        font-family: Gotham-Light
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile .party-management-mobile-dropdown li:first-child {
        border-top: 1px solid #dcdcdc
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile .party-management-mobile-dropdown li:last-child {
        border-bottom: none
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile .party-management-mobile-dropdown li:hover {
        background-color: #dcdcdc
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile .party-management-mobile-dropdown li:hover a {
        text-decoration: underline
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile .party-management-mobile-arrow-red {
        display: none;
        position: absolute;
        right: 20px;
        top: 20px
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile .party-management-mobile-arrow-down {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile ul li:hover .party-management-mobile-dropdown {
        display: block;
        border: none
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile ul li:hover .party-management-mobile-arrow-red {
        display: block
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile ul li:hover .party-management-mobile-arrow-down {
        display: none
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile ul:hover {
        background: #fff
    }

    .party-management-district-section-main .party-management-district-section .party-management-district-top-mobile ul li:hover p {
        color: red
    }

    .party-management-district-section-main .party-management-district-section-aside ul {
        display: none
    }

    .party-management-district-section-main .party-management-district-section-aside-select {
        position: relative;
        border-top: none
    }

    .party-management-district-section-main .party-management-district-section-aside-select-arrow {
        display: block;
        position: absolute;
        right: 10px;
        bottom: 20px
    }

    .party-management-district-section-main .party-management-district-section-aside-select select {
        display: block
    }

    .party-management-district-section-main .party-management-district-section-members {
        padding: 20px
    }

    .party-management-district-section-main .party-management-district-section-members-title {
        display: none
    }

    .party-management-district-section-main .party-management-district-section-members-input {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px
    }

    .party-management-district-section-main .party-management-district-section-members-input-filter input::-webkit-input-placeholder {
        padding-left: 0
    }

    .party-management-district-section-main .party-management-district-section-members-input-filter input::-moz-placeholder {
        padding-left: 0
    }

    .party-management-district-section-main .party-management-district-section-members-input-filter input:-ms-input-placeholder {
        padding-left: 0
    }

    .party-management-district-section-main .party-management-district-section-members-input-filter input::-ms-input-placeholder {
        padding-left: 0
    }

    .party-management-district-section-main .party-management-district-section-members-input-filter input::placeholder {
        padding-left: 0
    }

    .party-management-district-section-main .party-management-district-section-members-input .subsite-selectbox-arrow {
        right: 30px
    }

    .party-management-district-section-main .party-management-district-section-members-input .select-control {
        padding: 0 5px;
        width: 100%
    }

    .party-management-district-section-main .party-management-district-section-members-input select {
        width: 100%
    }

    .party-management-district-section-main .party-management-district-section-members-card {
        margin-top: 0;
        border-top: none;
        padding: 20px;
        border-left: 1px solid #dcdcdc
    }

    .party-management-district-section-main .party-management-district-section-members-card-content {
        margin-left: 15px;
        margin-top: 10px;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .party-management-district-section-center {
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 30px
    }

    .nopadding {
        border-bottom: 1px solid #dcdcdc
    }

    .nopadding:nth-child(2) {
        border-top: none
    }

    .nopadding:nth-child(even) {
        border-right: 1px solid #dcdcdc
    }
}

@media screen and (max-width: 768px) and (max-width:415px) {
    .party-management-district-section-main .party-management-district-section-aside ul {
        display:none
    }

    .party-management-district-section-main .party-management-district-section-aside select {
        display: block
    }

    .party-management-district-section-main .party-management-district-section-members {
        padding: 0
    }

    .party-management-district-section-main .party-management-district-section-members-title {
        display: none
    }

    .party-management-district-section-main .party-management-district-section-members-input {
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        margin-top: 10px
    }

    .party-management-district-section-main .party-management-district-section-members-input-filter input {
        width: 100%
    }

    .party-management-district-section-main .party-management-district-section-members-input .select-control {
        margin-left: 0;
        padding: 0 1px;
        width: 100%
    }

    .party-management-district-section-main .party-management-district-section-members-input .select-control:focus,.party-management-district-section-main .party-management-district-section-members-input .select-control:active {
        outline: 0
    }

    .party-management-district-section-main .party-management-district-section-members-input .subsite-selectbox-arrow {
        right: 30px
    }

    .party-management-district-section-main .party-management-district-section-members-input select {
        margin-top: 10px;
        margin-left: 0
    }

    .party-management-district-section-main .party-management-district-section-members-card {
        margin-top: 0;
        padding: 20px;
        border-left: 1px solid #dcdcdc
    }

    .party-management-district-section-main .party-management-district-section-members-card img {
        width: 100px;
        height: auto
    }

    .party-management-district-section-main .party-management-district-section-members-card-content {
        margin-left: 15px;
        margin-top: 10px;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .party-management-district-section-center {
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 30px
    }
}

@media screen and (min-width: 540px) and (max-width:736px) {
    .party-management-district-section-main .party-management-district-section-members-input-filter input {
        width:250px
    }
}

.chp-tv-page {
    overflow: hidden;
    background-color: #063d5d;
    border-top: 1px solid #2a667f
}

.chp-tv-page-title {
    background-color: #063d5d
}

.chp-tv-page-title-border {
    border-left: 1px solid #2a667f;
    border-right: 1px solid #2a667f;
    padding-top: 120px
}

.chp-tv-page-title-option {
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 0
}

.chp-tv-page-title-option-category {
    color: #fff;
    font-family: Gotham-Medium;
    font-size: 24px;
    line-height: 3.33;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 280px;
    position: relative
}

.chp-tv-page-title-option-category:hover {
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.16)
}

.chp-tv-page-title-option-category:hover p {
    color: red;
    text-align: center
}

.chp-tv-page-title-option-category:hover>ul {
    display: block;
    background-color: #fff
}

.chp-tv-page-title-option-list {
    display: none;
    background-color: #fff;
    padding-left: 0;
    list-style: none;
    display: none;
    position: absolute;
    width: 280px;
    height: 400px;
    overflow-y: scroll;
    top: 70px;
    right: 0;
    z-index: 2
}

.chp-tv-page-title-option-list li {
    font-family: Gotham-Light;
    font-size: 24px;
    line-height: 3.33;
    color: #063d5d;
    padding-left: 30px;
    border-top: 1px solid #dcdcdc
}

.chp-tv-page-title-option-list li:hover {
    background-color: #dcdcdc
}

.chp-tv-page-title-option-list li:hover a {
    text-decoration: underline
}

.chp-tv-page-title-option-list li a {
    color: #063d5d;
    text-decoration: none
}

.chp-tv-page-title-option ::-webkit-scrollbar-track {
    background-color: #9bb1be
}

.chp-tv-page-title-option ::-webkit-scrollbar {
    width: 10px
}

.chp-tv-page-title-option ::-webkit-scrollbar-thumb {
    background-color: #51778e;
    border-radius: 5px
}

.chp-tv-page-title-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 30px;
    margin-right: 30px
}

.chp-tv-page-content {
    background-color: #063d5d
}

.chp-tv-page-border {
    border-bottom: none
}

.chp-tv-page-left-box {
    background-image: linear-gradient(146deg,#003e5f,#00324b);
    border-left: 1px solid #2a667f;
    border-bottom: none;
    padding: 80px 50px 30px 29px;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.chp-tv-page-left-box-title {
    font-style: italic;
    color: #fff
}

.chp-tv-page-left-box a {
    color: #fff;
    text-decoration: none
}

.chp-tv-page-left-box-mobile .video-count {
    display: none
}

.chp-tv-page-right-box.soft {
    background-color: #063d5d
}

.chp-tv-page-slider-icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #fff;
    width: 46px;
    height: 46px;
    z-index: 3;
    background-color: transparent
}

.chp-tv-page-image {
    width: 296px
}

.chp-tv-page-slider-icons-box {
    width: 95%;
    margin-top: 50px;
    padding-bottom: 30px
}

.chp-tv-page-live {
    background-color: #05314a;
    padding: 62px 0 80px
}

.chp-tv-page-live-content {
    display: -ms-flexbox;
    display: flex
}

.chp-tv-page-live-content-live {
    width: 100%
}

.chp-tv-page-live-content-live .container {
    aspect-ratio: 16/9
}

.chp-tv-page-live-content-live iframe {
    height: 100%;
    width: 100%
}

.chp-tv-page-live-content-text {
    width: 300px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative
}

.chp-tv-page-live-content-text-live {
    font-size: 24px;
    font-style: italic;
    background-color: red;
    color: #fff;
    font-family: Gotham-Bold;
    padding: 5px 70px;
    margin-bottom: 24px
}

.chp-tv-page-live-content-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 24px;
    top: 10px
}

.chp-tv-page-sub {
    background-color: #05314a;
    padding-top: 20px
}

.chp-tv-page-sub a {
    text-decoration: none
}

.chp-tv-page-num {
    background-color: #05314a;
    padding-bottom: 100px
}

.chp-tv-page-sorting {
    background-color: #05314a;
    padding-top: 19px
}

.chp-tv-page-sorting-unorder {
    padding-left: 0;
    margin: 0;
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.chp-tv-page-sorting-menu {
    font-family: Gotham-Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #fff;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 16px;
    border: 1px solid #dcdcdc;
    width: 296px;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.chp-tv-page-sorting-menu:hover .chp-tv-page-sorting-menu-list {
    display: block
}

.chp-tv-page-sorting-menu:hover .sort-icon-dark {
    display: block
}

.chp-tv-page-sorting-menu:hover .sort-icon-light {
    display: none
}

.chp-tv-page-sorting-menu:hover {
    background-color: #fff;
    color: #000
}

.chp-tv-page-sorting-menu-list {
    background-color: #fff;
    padding-left: 0;
    list-style: none;
    display: none;
    position: absolute;
    width: 296px;
    top: 40px;
    left: -1px;
    z-index: 2
}

.chp-tv-page-sorting-menu-list li {
    padding: 10px;
    border-top: 1px solid #dcdcdc
}

.chp-tv-page-sorting-menu-list li a {
    text-decoration: none;
    color: #000
}

.chp-tv-page-video {
    background-color: #063d5d;
    -webkit-backdrop-filter: blur(9.6px);
    backdrop-filter: blur(9.6px);
    padding: 120px 0 100px
}

.chp-tv-page-video-detail {
    width: 800px;
    height: 449px
}

.chp-tv-page-video-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 16px;
    max-width: 400px
}

.chp-tv-page-video-content h2,.chp-tv-page-video-content .h2 {
    font-family: Gotham-Bold;
    line-height: 1.33;
    color: #fff
}

.chp-tv-page-video-content p {
    line-height: 1.75;
    color: rgba(255,255,255,.8)
}

.chp-tv-page-video-share p {
    font-family: Gotham-Medium;
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: .26px;
    color: #dcdcdc;
    margin-bottom: 20px
}

.chp-tv-page-video-share-icon {
    display: -ms-flexbox;
    display: flex
}

.chp-tv-page-video-share-icon span {
    padding: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #dcdcdc;
    width: 46px;
    height: 46px;
    cursor: pointer
}

.chp-tv-page-video-wrapper {
    display: -ms-flexbox;
    display: flex
}

.chp-tv-page .scheduler-col {
    position: relative
}

.chp-tv-page .scheduler-col:before {
    position: absolute;
    content: " ";
    top: 0;
    width: 6000px;
    height: 100%;
    border-left: 1px solid #2a667f;
    border-right: 1px solid #2a667f
}

.chp-tv-page .scheduler-col.is-left::before {
    right: -15px
}

.chp-tv-page .scheduler-col.is-right::before {
    left: -15px;
    background-color: #05314a
}

.chp-tv-page .scheduler-col.is-right-soft::before {
    left: -15px;
    background-color: #063d5d
}

.chp-tv-page-area {
    padding: 80px 0 30px
}

.chp-tv-page-area a {
    text-decoration: none
}

.chp-tv-page-show-videos {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.chp-tv-page .owl-nav {
    display: none
}

.chp-tv-page .chp-tv-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.chp-tv-page .owl-stage {
    display: -ms-flexbox;
    display: flex
}

.chp-tv-page-boxes {
    margin-bottom: 40px;
    position: relative
}

.chp-tv-page-boxes:hover .effect {
    color: red
}

.chp-tv-page-boxes:hover .chp-tv-right-side-time h4,.chp-tv-page-boxes:hover .chp-tv-right-side-time .h4 {
    text-decoration: underline
}

.item-play-icon {
    position: absolute;
    top: 80px;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.item-play-icon img {
    width: 48px
}

.sort-icon-dark {
    display: none
}

.option-icon-dark {
    display: none
}

.chp-tv-page-title-option-category:hover .option-icon-light {
    display: none
}

.chp-tv-page-title-option-category:hover .option-icon-dark {
    display: block
}

.owl-dots {
    display: none
}

@media screen and (max-width: 1024px) {
    .chp-tv-page-video {
        padding-left:30px;
        padding-right: 30px
    }

    .chp-tv-page-image {
        width: 100%
    }
}

@media screen and (max-width: 768px) {
    .chp-tv-page-mobile {
        -ms-flex-direction:column;
        flex-direction: column
    }

    .chp-tv-page-arrow .video-count-lg {
        display: none
    }

    .chp-tv-page-left-box {
        width: 100%;
        padding: 20px 0 35px;
        margin-left: 0;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: center;
        align-items: center;
        border-bottom: 1px solid #2a667f;
        border-left: none
    }

    .chp-tv-page-left-box-mobile .video-count {
        display: block
    }

    .chp-tv-page-title-content {
        margin-right: 20px
    }

    .chp-tv-page-title-border {
        padding-top: 90px
    }

    .chp-tv-page-slider-icons-box {
        width: 100%
    }

    .chp-tv-page-live {
        padding-bottom: 25px
    }

    .chp-tv-page-live-content {
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        padding-bottom: 59px;
        margin-left: 0
    }

    .chp-tv-page-live-content-live img {
        width: 100%
    }

    .chp-tv-page-live-content-text {
        width: 100%;
        padding: 0 15px
    }

    .chp-tv-page-live-content-info {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: baseline;
        align-items: baseline
    }

    .chp-tv-page-video {
        padding-left: 0;
        padding-right: 0
    }

    .chp-tv-page-video-content {
        padding-top: 30px;
        padding-left: 0
    }

    .chp-tv-page-video-detail {
        width: 600px;
        height: 300px
    }

    .chp-tv-page-video-wrapper {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }

    .chp-tv-page-video-content {
        max-width: 100%
    }

    .chp-tv-page .scheduler-col.is-right::before,.chp-tv-page .scheduler-col.is-right-soft::before {
        left: -100px
    }

    .chp-tv-page-image {
        width: 100%
    }
}

@media screen and (max-width: 720px) {
    .chp-tv-page-title-content h1,.chp-tv-page-title-content .h1 {
        font-size:30px
    }
}

@media screen and (max-width: 540px) {
    .chp-tv-page-video-detail {
        width:500px
    }

    .chp-tv-page-title-content h1,.chp-tv-page-title-content .h1 {
        font-size: 24px
    }

    .item-play-icon {
        top: 140px
    }
}

@media screen and (max-width: 415px) {
    .chp-tv-page-title-option-category {
        width:100%
    }

    .chp-tv-page-title-option-category p {
        display: none
    }

    .chp-tv-page-title-option-category:hover {
        background-color: transparent;
        box-shadow: none
    }

    .chp-tv-page-title-option-list {
        width: 250px
    }

    .chp-tv-page-left-box {
        padding-right: 50px
    }

    .chp-tv-page-slider-icons-box {
        width: 85%
    }

    .chp-tv-page-title-content {
        margin-right: 11px
    }

    .chp-tv-page-area {
        -ms-flex-pack: center;
        justify-content: center
    }

    .chp-tv-page-sub {
        padding-bottom: 0
    }

    .chp-tv-page-sorting-unorder {
        -ms-flex-pack: center;
        justify-content: center
    }

    .chp-tv-page-mobile .video-count {
        text-align: start
    }

    .chp-tv-page-show-videos p {
        display: none
    }

    .chp-tv-page-video-detail {
        width: 330px;
        height: 200px
    }

    .chp-tv-page-video-content {
        padding-left: 16px
    }

    .item-play-icon {
        top: 90px
    }
}

@media screen and (max-width: 320px) {
    .chp-tv-page-video-detail {
        width:280px;
        height: 175px
    }

    .item-play-icon {
        top: 75px
    }
}

.contact-presidencies-page-section iframe {
    width: 100%;
    height: 727px
}

.contact-presidencies-page-card {
    position: absolute;
    top: 150px;
    right: 200px;
    background-color: #fff;
    width: 400px
}

.contact-presidencies-page-card-content .contact-presidencies-card-select {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f8f8f8;
    height: 80px;
    position: relative
}

.contact-presidencies-page-card-content .contact-presidencies-card-select .subsite-selectbox-arrow {
    position: absolute;
    right: 35px;
    bottom: 28px
}

.contact-presidencies-page-card-content .contact-presidencies-card-sub {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f8f8f8;
    height: 80px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative
}

.contact-presidencies-page-card-content .contact-presidencies-card-sub select {
    width: 170px
}

.contact-presidencies-page-card-content .contact-presidencies-card-sub .subsite-selectbox-arrow {
    position: absolute;
    right: 35px;
    bottom: 28px
}

.contact-presidencies-page-card-content .contact-presidencies-card-sub .subsite-selectbox-arrow:first-child {
    right: 225px
}

.contact-presidencies-page-card-content .contact-presidencies-card-info {
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 40px
}

.contact-presidencies-page-card-content .contact-presidencies-card-info>div {
    margin-top: 10px
}

.contact-presidencies-page-card-content .contact-presidencies-card-info>div img {
    margin-right: 10px;
    -o-object-fit: contain;
    object-fit: contain
}

.contact-presidencies-page-card-content .contact-presidencies-card-info>div h6,.contact-presidencies-page-card-content .contact-presidencies-card-info>div .h6 {
    color: #000;
    font-family: Gotham-Bold;
    line-height: 1.46;
    letter-spacing: .26px;
    margin-top: 5px;
    font-size: 13px
}

.contact-presidencies-page-card-content .contact-presidencies-card-info>div p {
    line-height: 1.64;
    margin-left: 40px;
    margin-top: -5px;
    font-size: 14px
}

.contact-presidencies-page-card-content .contact-presidencies-card-info>div a {
    margin-left: 40px;
    line-height: 1.64;
    color: #e03997;
    font-size: 14px
}

.contact-presidencies-page-card-content .contact-presidencies-card-info>div:first-child {
    margin-top: 10px
}

.contact-presidencies-page-card select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

.contact-presidencies-page-card .select-control {
    width: 360px;
    height: 46px;
    border: solid 1px #dcdcdc;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    text-indent: 15px
}

.contact-presidencies-page-card .select-control:focus,.contact-presidencies-page-card .select-control:active {
    outline: 0
}

.contact-presidencies-page-card-mobile {
    display: none
}

@media screen and (max-width: 768px) {
    .contact-presidencies-page-section {
        display:none
    }

    .contact-presidencies-page-card {
        display: none
    }

    .contact-presidencies-page-card-mobile {
        display: block;
        margin-top: 50px;
        width: 90%
    }

    .contact-presidencies-page-card-mobile-title {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

    .contact-presidencies-page-card-mobile-title h2,.contact-presidencies-page-card-mobile-title .h2 {
        line-height: 1.33;
        font-size: 36px;
        border-left: 2px solid red;
        padding-left: 10px;
        margin-bottom: 30px
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content {
        border: 1px solid #dcdcdc;
        padding-bottom: 40px
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-select {
        background: 0 0;
        background-color: #f8f8f8;
        padding-left: 20px;
        padding-right: 20px
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-select select {
        width: 100%
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub {
        background-color: #fff;
        padding-left: 20px;
        padding-right: 20px
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub-top {
        position: relative
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub-top .subsite-selectbox-arrow {
        position: absolute;
        right: 25px;
        bottom: 10px
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub-bottom {
        position: relative
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub-bottom .subsite-selectbox-arrow {
        position: absolute;
        right: 14px;
        bottom: 10px
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub select {
        width: 100%
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub-top {
        width: 100%;
        padding-right: 10px
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub-bottom {
        width: 100%;
        padding-left: 10px
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-info:last-child {
        margin-bottom: 10px
    }

    .contact-presidencies-page-card-mobile h6,.contact-presidencies-page-card-mobile .h6 {
        color: #000;
        line-height: 1.46;
        letter-spacing: .26px;
        font-size: 13px;
        text-align: center;
        margin-top: 12px
    }

    .contact-presidencies-page-card-mobile h6:hover,.contact-presidencies-page-card-mobile .h6:hover {
        cursor: pointer
    }
}

@media screen and (max-width: 415px) {
    .contact-presidencies-page-section {
        display:none
    }

    .contact-presidencies-page-card {
        display: none
    }

    .contact-presidencies-page-card-mobile {
        display: block;
        margin-top: 50px;
        width: 90%
    }

    .contact-presidencies-page-card-mobile-title {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

    .contact-presidencies-page-card-mobile-title h2,.contact-presidencies-page-card-mobile-title .h2 {
        line-height: 1.33;
        font-size: 36px;
        border-left: 2px solid red;
        padding-left: 10px;
        margin-bottom: 30px
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content {
        border: 1px solid #dcdcdc
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-select {
        background-color: #f8f8f8;
        padding-left: 20px;
        padding-right: 20px
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-select select {
        width: 100%
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub {
        -ms-flex-flow: column;
        flex-flow: column;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
        background-color: #fff;
        width: 100%;
        height: 120px;
        padding: 0;
        margin: 0
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub select {
        width: 100%
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub select:last-child {
        margin-top: 0
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub-top,.contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub-bottom {
        padding-left: 20px;
        padding-right: 20px;
        margin: 0;
        position: relative
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub-top .subsite-selectbox-arrow,.contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-sub-bottom .subsite-selectbox-arrow {
        position: absolute;
        right: 35px;
        bottom: 10px
    }

    .contact-presidencies-page-card-mobile .contact-presidencies-page-card-content .contact-presidencies-card-info:last-child {
        margin-bottom: 10px
    }
}

.contact-abroad-page-section iframe {
    width: 100%;
    height: 727px
}

.contact-abroad-page-card {
    position: absolute;
    top: 150px;
    right: 200px;
    background-color: #fff;
    width: 400px
}

.contact-abroad-page-card-content .contact-abroad-card-select {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f8f8f8;
    height: 80px;
    position: relative
}

.contact-abroad-page-card-content .contact-abroad-card-sub {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    height: 80px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    padding-top: 10px;
    padding-bottom: 10px
}

.contact-abroad-page-card-content .contact-abroad-card-info {
    margin-left: 20px;
    padding-bottom: 40px
}

.contact-abroad-page-card-content .contact-abroad-card-info>div {
    margin-top: 10px
}

.contact-abroad-page-card-content .contact-abroad-card-info>div img {
    margin-right: 10px;
    -o-object-fit: contain;
    object-fit: contain
}

.contact-abroad-page-card-content .contact-abroad-card-info>div h6,.contact-abroad-page-card-content .contact-abroad-card-info>div .h6 {
    color: #000;
    font-family: Gotham-Bold;
    line-height: 1.46;
    letter-spacing: .26px;
    margin-top: 5px;
    font-size: 13px
}

.contact-abroad-page-card-content .contact-abroad-card-info>div p {
    line-height: 1.64;
    margin-left: 40px;
    margin-top: -5px;
    font-size: 14px
}

.contact-abroad-page-card-content .contact-abroad-card-info>div a {
    margin-left: 40px;
    line-height: 1.64;
    color: #e03997;
    font-size: 14px
}

.contact-abroad-page-card-content .contact-abroad-card-info>div:first-child {
    margin-top: 10px
}

.contact-abroad-page-card select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

.contact-abroad-page-card .select-control {
    width: 360px;
    height: 46px;
    border: solid 1px #dcdcdc;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    text-indent: 15px
}

.contact-abroad-page-card .select-control:focus,.contact-abroad-page-card .select-control:active {
    outline: 0
}

.contact-abroad-page-card .subsite-selectbox-arrow {
    position: absolute;
    right: 35px;
    bottom: 28px
}

.contact-abroad-page-card-mobile {
    display: none
}

@media screen and (max-width: 768px) {
    .contact-abroad-page-section {
        display:none
    }

    .contact-abroad-page-card {
        display: none
    }

    .contact-abroad-page-card-mobile {
        display: block;
        margin-top: 50px;
        width: 90%
    }

    .contact-abroad-page-card-mobile-title {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

    .contact-abroad-page-card-mobile-title h2,.contact-abroad-page-card-mobile-title .h2 {
        line-height: 1.33;
        font-size: 36px;
        border-left: 2px solid red;
        padding-left: 10px;
        margin-bottom: 30px
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content {
        border: 1px solid #dcdcdc
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-select {
        background: 0 0;
        background-color: #f8f8f8;
        padding-left: 20px;
        padding-right: 20px
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-select select {
        width: 100%
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-sub {
        background-color: #fff;
        padding-left: 20px;
        padding-right: 20px
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-sub select {
        width: 100%
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-sub-top {
        width: 100%;
        padding-right: 10px
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-sub-bottom {
        width: 100%;
        padding-left: 10px
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-info:last-child {
        margin-bottom: 10px
    }

    .contact-abroad-page-card-mobile h6,.contact-abroad-page-card-mobile .h6 {
        color: #000;
        line-height: 1.46;
        letter-spacing: .26px;
        font-size: 13px;
        text-align: center;
        margin-top: 12px
    }

    .contact-abroad-page-card-mobile h6:hover,.contact-abroad-page-card-mobile .h6:hover {
        cursor: pointer
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none
    }

    .select-control {
        width: 360px;
        height: 46px;
        border: solid 1px #dcdcdc;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        text-indent: 15px
    }

    .select-control:focus,.select-control:active {
        outline: 0
    }

    .subsite-selectbox-arrow {
        position: absolute;
        right: 35px;
        bottom: 28px
    }
}

@media screen and (max-width: 415px) {
    .contact-abroad-page-section {
        display:none
    }

    .contact-abroad-page-card {
        display: none
    }

    .contact-abroad-page-card-mobile {
        display: block;
        margin-top: 50px;
        width: 90%
    }

    .contact-abroad-page-card-mobile-title {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

    .contact-abroad-page-card-mobile-title h2,.contact-abroad-page-card-mobile-title .h2 {
        line-height: 1.33;
        font-size: 36px;
        border-left: 2px solid red;
        padding-left: 10px;
        margin-bottom: 30px
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content {
        border: 1px solid #dcdcdc
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-select {
        background: 0 0;
        background-color: #f8f8f8;
        padding-left: 20px;
        padding-right: 20px
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-select select {
        width: 100%
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-sub {
        background-color: #fff;
        padding-left: 20px;
        padding-right: 20px
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-sub select {
        width: 100%
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-sub-top {
        width: 100%;
        padding-right: 10px
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-sub-bottom {
        width: 100%;
        padding-left: 10px
    }

    .contact-abroad-page-card-mobile .contact-abroad-page-card-content .contact-abroad-card-info:last-child {
        margin-bottom: 10px
    }

    .contact-abroad-page-card-mobile h6,.contact-abroad-page-card-mobile .h6 {
        color: #000;
        line-height: 1.46;
        letter-spacing: .26px;
        font-size: 13px;
        text-align: center;
        margin-top: 12px
    }

    .contact-abroad-page-card-mobile h6:hover,.contact-abroad-page-card-mobile .h6:hover {
        cursor: pointer
    }
}

@media screen and (max-width: 415px) {
    .directory-button-dropbtn-text {
        display:none
    }
}

@media screen and (max-width: 415px) {
    .directory-button-dropdown-content {
        min-width:300px
    }
}

@media screen and (max-width: 768px) {
    .directory-button-dropdown-content {
        min-width:300px
    }
}

.directory-button-divider {
    height: 1px;
    margin: 25px 0 16px;
    background-color: #dcdcdc
}

.directory-button-dropbtn {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 50px;
    color: #063d5d;
    background-color: #fff;
    border-left: 0;
    height: 46px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #dcdcdc
}

.directory-button-dropbtn-text {
    font-family: Gotham-Bold;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 95px;
    color: #063d5d;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-left: 0;
    padding: 10px
}

.directory-button-dropbtn-text p {
    color: #063d5d;
    font-family: Gotham-Bold
}

.directory-button-dropbtn-text p:hover {
    cursor: pointer
}

.directory-button-dropdown {
    position: relative;
    display: -ms-flexbox;
    display: flex
}

.directory-button ::-webkit-scrollbar-track {
    background-color: #fff
}

.directory-button ::-webkit-scrollbar {
    width: 10px
}

.directory-button ::-webkit-scrollbar-thumb {
    background-color: #dcdcdc
}

.directory-button-dropdown-content {
    display: none;
    position: absolute;
    top: 45px;
    left: 95px;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
    z-index: 1;
    min-width: 500px;
    height: 470px;
    overflow-y: scroll;
    text-align: center!important;
    -webkit-transform: translate(-90%,0)!important;
    transform: translate(-90%,0)!important
}

.directory-button-dropdown-content ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.directory-button-dropdown-content ul li {
    padding: 20px;
    border-bottom: 1px solid #dcdcdc
}

.directory-button-dropdown-content ul li:hover {
    background-color: #dcdcdc
}

.directory-button-dropdown-content ul li:last-child {
    border-bottom: none
}

.directory-button-dropdown-content a {
    font-family: Gotham-Bold;
    font-size: 13px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    display: block;
    text-decoration: none
}

.directory-button-dropdown:hover .directory-button-dropdown-content {
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.16);
    background-color: #fff;
    display: block
}

.directory-button-dropdown:hover .directory-button-dropbtn-text {
    background-color: #063d5d
}

.directory-button-dropdown:hover .directory-button-dropbtn-text p {
    color: #fff
}

.directory-button-dropdown:hover .directory-button-dropbtn img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1)
}

.directory-button-dropdown:hover .directory-button-dropbtn {
    background-color: #063d5d
}

@media screen and (max-width: 415px) {
    .directory-button-dropdown-content {
        top:50px;
        left: 40px;
        min-width: 300px;
        height: 470px
    }
}

.historyContainer .row {
    margin-right: 0
}

@media screen and (max-width: 415px) {
    .historyContainer .row {
        margin-right:0
    }
}

@media screen and (max-width: 768px) {
    .historyContainer .row {
        margin-right:0
    }
}

.history-titles h2,.history-titles .h2 {
    border-left: 2px solid red;
    padding: 10px
}

.share-button-mobile {
    display: none
}

@media screen and (max-width: 415px) {
    .share-button-mobile {
        display:block;
        margin-right: 10px;
        margin-bottom: 10px
    }

    .share-button-desktop {
        display: none
    }
}

@media screen and (max-width: 415px) {
    .history-titles h2,.history-titles .h2 {
        border-left:2px solid red;
        padding: 10px;
        margin-left: 0
    }
}

.history-titles {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 20px;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    padding-top: 100px
}

@media screen and (max-width: 415px) {
    .history-titles {
        display:-ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-transform: uppercase;
        padding-bottom: 20px;
        margin-left: 0
    }
}

.history-titles-show {
    margin-right: 20px
}

.border {
    height: 1px;
    background-color: #dcdcdc
}

.historyContainer {
    background-color: #f8f8f8
}

@media screen and (max-width: 415px) {
    .historyContainer {
        padding:0 0 121px;
        background-color: #f8f8f8
    }
}

@media screen and (max-width: 768px) {
    .historyContainer {
        padding:0 0 44px;
        background-color: #f8f8f8
    }
}

.history-content {
    border-right: 1px solid #dcdcdc
}

@media screen and (max-width: 415px) {
    .history-content {
        margin:10px 0;
        border-right: 0
    }
}

@media screen and (max-width: 768px) {
    .history-content {
        margin:10px;
        border-right: 0
    }
}

.history-content-article {
    margin-left: 15px;
    margin-bottom: 60px;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0,0,0,.8);
    margin-top: 30px;
    margin-right: 50px
}

@media screen and (max-width: 415px) {
    .history-content-article {
        font-size:16px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
        color: rgba(0,0,0,.8);
        margin-right: 0
    }
}

@media screen and (max-width: 768px) {
    .history-content-article {
        font-size:16px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
        color: rgba(0,0,0,.8);
        margin-right: 0
    }
}

.history-content-head {
    font-family: Gotham-Bold;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-top: 30px
}

@media screen and (max-width: 415px) {
    .history-content-head {
        font-size:24px;
        font-weight: 900;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000
    }
}

.history-content-image {
    -o-object-fit: contain;
    object-fit: contain;
    margin-top: 50px;
    width: 100%
}

.history-content-imagetext {
    font-size: 13px;
    font-family: Gotham-Bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    color: #000
}

.corporateIdentity {
    overflow: hidden
}

.corporateIdentity-Page {
    background-color: #f8f8f8;
    border-top: 1px solid #dcdcdc
}

@media screen and (max-width: 415px) {
    .corporateIdentity-Page {
        padding:20px
    }
}

.corporateIdentity-titles {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-transform: uppercase;
    padding: 100px 30px 40px 0;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc
}

.corporateIdentity-titles h2,.corporateIdentity-titles .h2 {
    border-left: 2px solid red;
    padding: 10px 16px 0 24px
}

@media screen and (max-width: 415px) {
    .corporateIdentity-titles {
        display:-ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-transform: uppercase;
        padding-top: 66px;
        padding-right: 0
    }

    .corporateIdentity-titles-show {
        display: none!important
    }
}

.corporateIdentity {
    border-right: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc
}

.corporateIdentity .corp-image {
    width: 100%
}

.corporateIdentity .newsOneLine-box {
    padding-bottom: 100px
}

.corporateIdentity-card-content img {
    float: right
}

.corporateIdentity-card-content h2,.corporateIdentity-card-content .h2 {
    font-size: 36px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #063d5d;
    padding: 20px;
    padding-bottom: 80px
}

.corporateIdentity-card-content h2 img,.corporateIdentity-card-content .h2 img {
    padding-top: 12px
}

.corporateIdentity-card-content h4,.corporateIdentity-card-content .h4 {
    padding: 16px 0 40px 20px;
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #063d5d
}

@media screen and (max-width: 415px) {
    .corporateIdentity .journal-blocks {
        -ms-flex-align:center;
        align-items: center;
        padding-right: 0;
        display: inline;
        width: 100%;
        border-right: 1px solid #dcdcdc
    }

    .corporateIdentity .sub-management-news-card #corporateIdentity-image {
        width: 100%
    }
}

@media screen and (max-width: 415px) {
    .corporateIdentity #corporateIdentity-subimage {
        width:100%
    }

    .corporateIdentity .corporateIdentity-card-content img {
        margin-right: 20px
    }
}

@media screen and (max-width: 768px) {
    .sub-management-news-card #corporateIdentity-image {
        width:100%
    }
}

@media screen and (max-width: 768px) {
    .corporateIdentity-card-content h2,.corporateIdentity-card-content .h2 {
        font-size:16px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: #063d5d
    }

    .corporateIdentity-card-content h2 img,.corporateIdentity-card-content .h2 img {
        padding-top: 0
    }
}

@media screen and (max-width: 415px) {
    .corporateIdentity-card-content h2,.corporateIdentity-card-content .h2 {
        font-size:16px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: #063d5d
    }

    .corporateIdentity-card-content h2 img,.corporateIdentity-card-content .h2 img {
        padding-top: 0
    }

    .corporateIdentity-card-content h4,.corporateIdentity-card-content .h4 {
        font-size: 16px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        color: #063d5d
    }
}

@media screen and (max-width: 768px) {
    .corporateIdentity .sub-management-news-card {
        width:50%
    }
}

@media screen and (max-width: 415px) {
    .corporateIdentity .sub-management-news-card {
        width:100%
    }
}

.header-banner {
    background-image: url(../img/gokyuzu.webp)
}

.header-banner-content {
    border: 4px solid #fff;
    border-bottom: none
}

.header-banner-content-bigTitle {
    font-family: Gotham-Bold;
    font-size: 96px;
    line-height: 1.37;
    color: #fff;
    text-align: center
}

.header-banner-content-title {
    font-family: Gotham-Bold;
    font-size: 36px;
    line-height: 1.33;
    color: #063d5d;
    text-align: center
}

.header-banner-content-text {
    font-family: Gotham-Bold;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    width: 50%;
    margin: auto
}

.header-banner-content-btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 19px;
    margin-top: 47px
}

.header-banner-content-btn button {
    font-family: Gotham-Bold;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 2.4px
}

.header-banner-cancel {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.header-banner-cancel button {
    width: 145px;
    font-family: Gotham-Bold;
    font-size: 14px;
    letter-spacing: 2.8px
}

@media screen and (max-width: 768px) {
    .header-banner-content h1,.header-banner-content .h1 {
        font-size:50px
    }

    .header-banner-content p {
        width: 75%
    }
}

.header-video-banner {
    height: 450px
}

.header-video-banner-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 34px 0 68px
}

.header-video-banner-content img {
    height: 300px
}

.header-video-banner-content-bigTitle {
    font-family: Gotham-Bold;
    font-size: 36px;
    color: #fff
}

.header-video-banner-content-title {
    font-family: Gotham-Bold;
    font-size: 24px;
    line-height: 2;
    color: #063d5d
}

.header-video-banner-content-text {
    font-family: Gotham-Light;
    font-size: 16px;
    line-height: 1.75;
    color: #fff
}

.header-video-banner-text {
    padding: 0 50px 0 16px
}

.header-live-banner {
    background-image: url(../img/gokyuzu2.webp);
    background-size: cover
}

.header-live-banner-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center
}

.header-live-banner-content h2,.header-live-banner-content .h2 {
    color: #fff
}

.header-live-banner-content p {
    font-family: Gotham-Bold;
    font-size: 24px;
    line-height: 2;
    color: #063d5d
}

.header-live-banner-content .video {
    width: 100%;
    aspect-ratio: 16/9
}

.header-live-banner-info {
    width: 816px;
    padding-left: 30px;
    padding-right: 30px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    -ms-flex-align: center;
    align-items: center
}

.header-live-banner-info .live-description {
    font-size: 18px;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.header-live-banner-info a {
    color: #fff;
    text-decoration: none;
    padding-right: 30px
}

@media screen and (max-width: 1024px) {
    .header-live-banner-content {
        -ms-flex-pack:center;
        justify-content: center
    }

    .header-live-banner-info {
        width: 700px
    }

    .header-live-banner img {
        display: none
    }
}

@media screen and (max-width: 768px) {
    .header-live-banner-info {
        width:100%
    }
}

.chp-volunteer {
    background-color: #dcdcdc50
}

.chp-volunteer-title {
    padding-top: 90px;
    padding-bottom: 41px;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc
}

.chp-volunteer-title p {
    padding-left: 24px
}

.chp-volunteer-border {
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc
}

.chp-volunteer-select {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.chp-volunteer-select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

.chp-volunteer-select label {
    position: relative
}

.chp-volunteer-right {
    border-left: 1px solid #dcdcdc;
    height: 100%;
    padding-bottom: 50px;
    padding-top: 6px
}

.chp-volunteer-right img {
    width: 100%
}

.chp-volunteer-right-content {
    padding: 27px 31px 0 23px
}

.chp-volunteer-right-content p {
    font-family: Gotham-Light;
    font-size: 16px;
    line-height: 1.75;
    color: rgba(0,0,0,.8)
}

.chp-volunteer-form {
    padding: 51px 24px
}

.chp-volunteer-form button {
    width: 100%;
    margin-bottom: 67px;
    margin-top: 58px
}

.chp-volunteer-form .form-check-label {
    font-family: Gotham-Light;
    font-size: 14px;
    line-height: 1.86;
    color: #000
}

.chp-volunteer .form-control {
    padding: 12px;
    border-radius: 0
}

.chp-volunteer .form-label {
    font-family: Gotham-Bold;
    font-size: 13px;
    line-height: 1.46;
    color: #000
}

.chp-volunteer #exampleFormControlTextarea12 {
    height: 204px
}

.chp-volunteer .select-control {
    height: 46px;
    border: solid 1px #dcdcdc;
    padding: 0 12px;
    color: #dcdcdc
}

.chp-volunteer .select-control:focus,.chp-volunteer .select-control:active {
    outline: 0
}

.chp-volunteer .right-check {
    margin-left: 43px
}

.chp-volunteer input::-webkit-outer-spin-button,.chp-volunteer input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.chp-volunteer input[type=number] {
    -moz-appearance: textfield
}

.chp-volunteer .selectbox-arrow {
    position: absolute;
    right: 20px;
    bottom: 10px
}

.breadcrumb-part {
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc
}

.breadcrumb-item {
    font-family: Gotham-Light;
    font-size: 12px;
    line-height: 1.83
}

.breadcrumb-item a {
    color: #000;
    text-decoration: none
}

.breadcrumb {
    background-color: transparent;
    margin-top: 14px;
    margin-left: 15px;
    margin-bottom: 0
}

.search-title {
    padding: 94px 0 17px;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc
}

.search-title p {
    padding-left: 20px;
    font-size: 13px
}

.search-dropdown {
    display: none
}

.search-dropdown-list:hover .search-dropdown-content-list {
    display: block
}

@media screen and (max-width: 768px) {
    .search-dropdown {
        display:block;
        width: 100%;
        border-right: 1px solid #dcdcdc;
        border-left: 1px solid #dcdcdc;
        border-top: 1px solid #dcdcdc;
        position: relative;
        margin: 0;
        list-style: none;
        padding: 0
    }

    .search-dropdown-content-list {
        width: 100%;
        padding: 0;
        list-style: none;
        position: absolute;
        background-color: #fff;
        top: 60px;
        left: 0;
        height: auto;
        display: none;
        list-style: none
    }

    .search-dropdown-content-list li {
        border-bottom: 1px solid #dcdcdc;
        padding: 20px;
        font-family: Gotham-Bold;
        font-size: 13px;
        line-height: 1.46;
        letter-spacing: .26px
    }

    .search-dropdown-content-list li a {
        text-decoration: none;
        color: #000
    }

    .search-dropdown-content-list li:hover {
        background-color: #f8f8f8
    }
}

@media screen and (max-width: 415px) {
    .search-title {
        font-size:24px
    }

    .search-dropdown {
        padding: 20px
    }
}

.grecaptcha-badge {
    visibility: hidden
}

.border-bottom-divider {
    border-bottom: 1px solid #dcdcdc
}

.border-left-divider {
    border-left: 1px solid #dcdcdc
}

.shadow-bottom-header {
    box-shadow: 0 6px 6px 0 rgba(0,0,0,.2)
}

.chp-calendar-box {
    cursor: pointer
}
.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}

.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.login-with-google-btn:active {
  background-color: #eeeeee;
}

.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25), 0 0 0 3px #c8dafc;
}

.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  cursor: not-allowed;
}
